<ng-container *ngIf="OnlineState$ | async as state">
    <p-table
            #dt
            [columns]="cols"
            [value]="tableEntries"
            [(selection)]="selectedEntries"
            dataKey="id"
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)"
            (onHeaderCheckboxToggle)="onRowSelect($event)"
            styleClass="table"
            [paginator]="true"
            [rows]="20"
            [resizableColumns]="true">
        <ng-template pTemplate="caption">
            <div class="clearfix display-flex align-items-baseline flex-column lg:flex-row">
                <div class="mr-2 mb-2 md:hidden" style="text-align: left; float: left">
                    <p-toggleButton
                            matPrefix
                            [(ngModel)]="withArchiveState"
                            [onLabel]="'txTeamLeaderProtocol.archivedEntries' | translate"
                            [offLabel]="'txTeamLeaderProtocol.archivedEntries' | translate"
                            [onIcon]="'fa fa-check-square'"
                            [offIcon]="'fa fa-square'"
                            [style]="{ width: '250px' }"
                            (onChange)="handleArchiveState($event)">
                    </p-toggleButton>
                </div>

                <mat-form-field appearance="outline" class="full-width search-input">
                    <!--<i class="fa fa-search" style="margin:4px 4px 0 0"></i>-->
                    <p-toggleButton
                            matPrefix
                            class="mr-2 hidden md:block"
                            [(ngModel)]="withArchiveState"
                            [onLabel]="'txTeamLeaderProtocol.archivedEntries' | translate"
                            [offLabel]="'txTeamLeaderProtocol.archivedEntries' | translate"
                            [onIcon]="'fa fa-check-square'"
                            [offIcon]="'fa fa-square'"
                            [style]="{ width: '250px' }"
                            (onChange)="handleArchiveState($event)">
                    </p-toggleButton>
                    <mat-label>{{ 'txTeamLeaderProtocol.search' | translate }}</mat-label>
                    <input type="text" matInput (input)="dt.filterGlobal(getInputValue($event.target), 'contains')"/>
                    <div matSuffix>
                        <mat-icon>search</mat-icon>
                    </div>
                </mat-form-field>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 5%"></th>
                <th style="width: 15%">{{ 'txTeamLeaderProtocol.name' | translate }}</th>
                <th style="width: 15%">{{ 'txTeamLeaderProtocol.date' | translate }}</th>
                <th>{{ 'txTeamLeaderProtocol.info' | translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
                    [pSelectableRow]="rowData"
                    [@rowVisibleState]="rowData.deleteState"
                    [attr.data-art]="rowData.art"
                    [style.background-color]="rowData.art == 'FSCHECK' ? 'rgba(255,0,0,0.1)' : ''">
                <td class="ui-resizable-column" style="width: 10%">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td class="ui-resizable-column">
                    <ng-template [ngIf]="rowData.art == 'FSCHECK'">
                        <a
                                *ngIf="state.isOnline && !isBusy[rowData.data_id]"
                                (click)="showDriverLicenseCheckDialog(rowData.data_id, rowData.personalnummer)">
                            <span [innerHTML]="rowData.name" style="text-decoration: underline"></span>
                        </a>
                        <span
                                *ngIf="!state.isOnline || !!isBusy[rowData.data_id]"
                                [innerHTML]="rowData.name"
                                style="text-decoration: underline"></span>
                    </ng-template>
                    <ng-template [ngIf]="rowData.art != 'FSCHECK'">
                        <span [innerHTML]="rowData.name"></span>
                    </ng-template>
                </td>
                <td class="ui-resizable-column">
                    {{ rowData.datum }}
                </td>
                <td
                        class="ui-resizable-column"
                        class="disabled-exact disabled-only-blur disabled-loading disabled-loading-small"
                        [ngClass]="{ 'disabled-overlay': !!isBusy[rowData.data_id] }">
                    <ng-template [ngIf]="rowData.art == 'FSCHECK'">
                        <a *ngIf="state.isOnline"
                           (click)="showDriverLicenseCheckDialog(rowData.data_id, rowData.personalnummer)">
							<span [innerHTML]="rowData.protocoltext"></span
                            ><i class="fa fa-external-link" aria-hidden="true" style="margin-left: 8px"></i>
                        </a>
                        <span *ngIf="!state.isOnline" [innerHTML]="rowData.protocoltext"></span>
                    </ng-template>
                    <ng-template [ngIf]="rowData.art != 'FSCHECK'">
                        <span [innerHTML]="rowData.protocoltext"></span>
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-dialog
            header="Führerscheinkontrolle"
            [(visible)]="driverLicenseCheck"
            [modal]="true"
            [responsive]="true"
            [style]="{ 'min-width': '320px' }"
            appendTo="body"
            [positionTop]="70"
            styleClass="driver-license-check"
            [maximizable]="true">
        <div class="grid p-5 xl:vw90" style="height: 100%">
            <div class="col-12 md:col-6 flex align-items-center flex-direction-column">
                <h4 class="font-weight-bold">Referenz</h4>
                <pdf-viewer
                        [src]="refBase64"
                        [original-size]="false"
                        [fit-to-page]="true"
                        [autoresize]="true"
                        [show-borders]="false"
                        [zoom-scale]="'page-fit'"
                        style="width: 100%; padding-bottom: 75%;"
                ></pdf-viewer>
            </div>
            <div class="col-12 md:col-6 flex align-items-center flex-direction-column">
                <h4 class="font-weight-bold">Mitarbeiterupload</h4>
                <pdf-viewer
                        [src]="userImage"
                        [original-size]="false"
                        [fit-to-page]="true"
                        [autoresize]="true"
                        [show-borders]="false"
                        [zoom-scale]="'page-fit'"
                        style="width: 100%; padding-bottom: 75%;"
                ></pdf-viewer>
            </div>
        </div>
        <p-footer>
            <div class="flex justify-content-space-between align-items-center">
                <div class="flex-grow-1 mr-2">
                    <mat-form-field
                            appearance="outline"
                            class="w-full"
                            style="margin-bottom: -22px"
                    >
                        <mat-label>
                            Notiz
                        </mat-label>
                        <input matInput [(ngModel)]="driverLicenseNotice" autocomplete="new-password"/>
                    </mat-form-field>
                </div>
                <div class="">
                    <div class="display-flex">
                        <div class="">
                            <button
                                    type="button"
                                    mat-raised-button
                                    color="primary"
                                    (click)="confirmDriverLicenseCheck(1)"
                                    class="ui-button-success"
                            >
                                <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                <span class="hidden md:inline">Bestätigen</span>
                            </button>
                        </div>
                        <div>
                            <button
                                    type="button"
                                    mat-raised-button
                                    color="warn"
                                    (click)="confirmDriverLicenseCheck(0)"
                                    class="ui-button-danger"
                            >
                                <i class="fa fa-times mr-2" aria-hidden="true"></i>
                                <span class="hidden md:inline">Ablehnen</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </p-footer>
    </p-dialog>
</ng-container>
