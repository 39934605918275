<div class="grid">
	<div class="col-12 xl:col-8">
		<mat-card class="widget flex-grow-1 mat-elevation-z0" *ngIf="Project !== null; else loading">
			<mat-card-header>
				<mat-card-title class="display-flex flex-align-items-center justify-content-between">
					<div>
						<button mat-icon-button routerLink="/projectoffice/assingments">
							<fa-icon icon="chevron-left"></fa-icon>
						</button>
						<span>{{ 'txProjectOffice.assignment' | translate }}</span>
					</div>
					<div *ngIf="reports$ | async as reports">
						<button
							mat-button
							mat-mini-fab
							[mat-menu-trigger-for]="reportMenu"
							[disabled]="loadingReport"
							color="primary"
							class="text-white"
							[ngClass]="{ 'bg-success': !!Filter.to && !!Filter.from }">
							<fa-icon *ngIf="!loadingReport" icon="file-pdf"></fa-icon>
							<fa-icon *ngIf="loadingReport" icon="spinner" [spin]="true"></fa-icon>
						</button>
						<mat-menu #reportMenu="matMenu">
							<button *ngFor="let report of reports" (click)="downloadReport(report)" mat-menu-item>
								{{ report.report_name }}
							</button>
						</mat-menu>
					</div>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<ng-container *ngIf="ProjectForm">
					<form [formGroup]="ProjectForm">
						<div class="display-flex">
							<div class="flex-ml-1 flex-grow-1 display-flex mr-1">
								<mat-form-field appearance="outline" class="">
									<mat-label>{{ 'txProjectOffice.number' | translate }}</mat-label>
									<input matInput formControlName="project_number" required />
								</mat-form-field>
								<mat-form-field appearance="outline" class="flex-grow-1">
									<mat-label>{{ 'txProjectOffice.description' | translate }}</mat-label>
									<input matInput formControlName="project_name" required />
								</mat-form-field>
							</div>
							<div class="flex-ml-1">
								<mat-form-field appearance="outline">
									<mat-label>{{ 'txProjectOffice.status' | translate }}</mat-label>
									<mat-select formControlName="project_state">
										<mat-option *ngFor="let state of ProjectStates" [value]="state.value">
											{{ state.description }}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>
										<span [class.text-danger]="ProjectForm.value.unproductive === 1">{{ 'txProjectOffice.type' | translate }}</span>
									</mat-label>
									<mat-select formControlName="unproductive" [class.test-danger]="ProjectForm.value.unproductive === 1">
										<mat-option [value]="0">Produktiv</mat-option>
										<mat-option [value]="1" class="text-danger">Unproduktiv</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						<div class="display-flex">
							<div class="flex-grow-1 display-flex mr-1">
								<mat-form-field appearance="outline" class="flex-grow-1">
									<mat-label>{{ 'txProjectOffice.description' | translate }}</mat-label>
									<textarea matInput rows="18" [matTextareaAutosize]="true" formControlName="description"></textarea>
								</mat-form-field>
							</div>
							<div class="display-flex flex-direction-column">
								<mat-form-field appearance="fill">
									<mat-label>{{ 'txProjectOffice.create_date' | translate }}</mat-label>
									<input matInput formControlName="creation_date" [matDatepicker]="pickerCreation" />
									<mat-datepicker-toggle matSuffix [for]="pickerCreation"></mat-datepicker-toggle>
									<mat-datepicker #pickerCreation></mat-datepicker>
								</mat-form-field>
								<mat-form-field appearance="fill">
									<mat-label>{{ 'txProjectOffice.is_time' | translate }}</mat-label>
									<div class="display-flex flex-justify-content-space-between">
										<input matInput type="text" formControlName="calculated_time" />
										<span *ngIf="!!Filter.to && !!Filter.from && !!FilterProject" class="filter-active-input">{{
											FilterProject.calculated_time
										}}</span>
									</div>
								</mat-form-field>
								<mat-form-field appearance="fill">
									<mat-label>{{ 'txProjectOffice.end_planed' | translate }}</mat-label>
									<input matInput formControlName="target_date" [matDatepicker]="pickerEnd" />
									<mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
									<mat-datepicker #pickerEnd></mat-datepicker>
								</mat-form-field>
								<mat-form-field appearance="fill">
									<mat-label>{{ 'txProjectOffice.should_time' | translate }}</mat-label>
									<input
										matInput
										mask="000:00"
										[showMaskTyped]="true"
										[dropSpecialCharacters]="false"
										type="text"
										formControlName="target_time" />
								</mat-form-field>
								<mat-form-field appearance="fill">
									<mat-label>{{ 'txProjectOffice.should_amount' | translate }}</mat-label>
									<input matInput type="number" step="0.1" formControlName="target_quantity" />
								</mat-form-field>
							</div>
						</div>
					</form>
				</ng-container>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="col-12 xl:col-4">
		<mat-card class="widget mat-elevation-z0" [ngClass]="{ 'filter-active': !!Filter.to && !!Filter.from }">
			<mat-card-header>
				<mat-card-title class="full-width display-flex flex-align-items-center">
					<div class="flex-grow-1 display-flex flex-justify-content-space-between">
						<h5>{{ 'txProjectOffice.task' | translate }}</h5>
						<div class="worksteps-buttons">
							<button mat-raised-button class="mr-1" type="button" (click)="Refresh(Project)">
								<fa-icon icon="sync"></fa-icon>
                                {{ 'txProjectOffice.refresh' | translate }}
							</button>
							<button mat-raised-button type="button" (click)="openDialog(Project)">
								<fa-icon icon="plus-circle"></fa-icon>
                                {{ 'txProjectOffice.add' | translate }}
							</button>
						</div>
					</div>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div>
					<mat-table class="workstep-table" [dataSource]="WorkstepDatasource">
						<ng-container matColumnDef="workstep_id">
							<mat-header-cell *matHeaderCellDef class="font-weight-bold text-dark">{{ 'txProjectOffice.id' | translate }}</mat-header-cell>
							<mat-cell *matCellDef="let row">{{ row.workstep_project_id }}</mat-cell>
						</ng-container>
						<ng-container matColumnDef="workstep_name">
							<mat-header-cell *matHeaderCellDef class="font-weight-bold">{{ 'txProjectOffice.description_alt' | translate }}</mat-header-cell>
							<mat-cell *matCellDef="let row">{{ row.workstep_name }}</mat-cell>
						</ng-container>
						<ng-container matColumnDef="sum_quantity">
							<mat-header-cell *matHeaderCellDef class="font-weight-bold">{{ 'txProjectOffice.is_amount' | translate }}</mat-header-cell>
							<mat-cell *matCellDef="let row">{{ row.sum_quantity }}</mat-cell>
						</ng-container>
						<ng-container matColumnDef="calculated_time">
							<mat-header-cell *matHeaderCellDef class="font-weight-bold flex-justify-content-flex-end"
								>Ist Zeit{{ 'txProjectOffice.is_time' | translate }}</mat-header-cell
							>
							<mat-cell class="flex-justify-content-flex-end" *matCellDef="let row">{{ row.calculated_time }}</mat-cell>
						</ng-container>
						<ng-container matColumnDef="actions">
							<mat-header-cell *matHeaderCellDef></mat-header-cell>
							<mat-cell *matCellDef="let row">
								<div>
									<button
										mat-icon-button
										type="button"
										[matMenuTriggerFor]="menu"
										aria-label="Example icon-button with a menu">
										<fa-icon icon="ellipsis-v"></fa-icon>
									</button>
									<mat-menu #menu="matMenu">
										<div
											mat-menu-item
											class="no-margin"
											[matTooltipDisabled]="row.calculated_time === '00:00'"
											matTooltip="{{ 'txProjectOffice.has_booked_time' | translate }}">
											<button
												mat-icon-button
												type="button"
												[disabled]="row.calculated_time !== '00:00'"
												(click)="deleteWorkstep(row)">
												<fa-icon icon="minus-circle"></fa-icon>
												<span class="text-danger">&nbsp;{{ 'txProjectOffice.delete' | translate }}</span>
											</button>
										</div>
									</mat-menu>
								</div>
							</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
					</mat-table>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="col-12">
		<mat-card class="widget mat-elevation-z0">
			<mat-card-content>
				<div class="display-flex flex-ml-1">
					<div class="display-flex flex-align-items-center">
						<button mat-raised-button color="basic" [disabled]="!ProjectForm.dirty" (click)="resetForm(Project)">
                            {{ 'general.abort' | translate }}
						</button>
					</div>
					<div class="flex-grow-1 display-flex flex-justify-content-center flex-align-items-center">
						<div *ngIf="isFiltering()" class="display-flex">
							<!--<div>
                                <div class="display-flex p-1" [ngClass]="{ 'filter-active': !!Filter.from }">
                                    <h5 class="no-margin">Von: {{ Filter.from | date:'dd.MM.yyyy' }}</h5>
                                </div>
                                <mat-calendar (selectedChange)="Filter.from = $event">
                                </mat-calendar>
                            </div>
                            <div class="ml-1">
                                <div class="display-flex p-1" [ngClass]="{ 'filter-active': !!Filter.to }">
                                    <h5 class="no-margin">Bis: {{ Filter.to | date:'dd.MM.yyyy' }}</h5>
                                </div>
                                <mat-calendar [minDate]="Filter.from" (selectedChange)="Filter.to = $event; startFilter()">
                                </mat-calendar>
                            </div>-->
						</div>
					</div>
					<div class="display-flex flex-align-items-center">
						<mat-form-field *ngIf="Project.project_id" appearance="standard" class="mr-4 filter-select-field">
							<mat-label>{{ 'txProjectOffice.daterange_filter' | translate }}</mat-label>
							<mat-date-range-input [rangePicker]="filterPicker" (click)="filterPicker.open()">
								<input matStartDate [(ngModel)]="Filter.from" placeholder="Startdatum" disabled />
								<input
									matEndDate
									[(ngModel)]="Filter.to"
									placeholder="Enddatum"
									(dateChange)="startFilter()"
									disabled />
							</mat-date-range-input>
							<mat-datepicker-toggle *ngIf="!isFiltering()" matSuffix [for]="filterPicker" [disabled]="false">
							</mat-datepicker-toggle>
							<button
								mat-icon-button
								color="warn"
								*ngIf="isFiltering()"
								matSuffix
								(click)="Filter.from = null; Filter.to = null; FilterProject = null; FilterBookings = null">
								<fa-icon icon="times"></fa-icon>
							</button>
							<mat-date-range-picker #filterPicker [disabled]="false"></mat-date-range-picker>
						</mat-form-field>
						<!--<mat-slide-toggle class="mr-1" color="primary" [(ngModel)]="isFiltering">
                            Zeitraum Filtern
                        </mat-slide-toggle>-->
						<button
							(click)="saveOrCreate($event, Project, Project.project_id === 0)"
							mat-raised-button
							color="primary"
							[disabled]="ProjectForm.invalid || ProjectForm.untouched">
                            {{ 'general.save' | translate }}
						</button>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="col-12">
		<mat-card class="widget mat-elevation-z0" [ngClass]="{ 'filter-active': !!Filter.from && !!Filter.to }">
			<mat-card-header>
				<mat-card-title class="full-width display-flex flex-align-items-center">
					<div class="flex-grow-1 display-flex flex-justify-content-space-between mt-1">
						<h5>{{ 'txProjectOffice.bookings' | translate }}</h5>
						<button mat-raised-button type="button" (click)="loadAllBookings(Project.project_id)">
							<fa-icon icon="cloud-download-alt"></fa-icon>
							<span> {{ 'txProjectOffice.load_all' | translate }}</span>
						</button>
					</div>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<ng-container *ngIf="Project.project_id > 0">
					<div *ngIf="Bookings !== null; else loading">
						<mat-table class="bookings-table" [dataSource]="FilterBookings || Bookings | sort: 'datum':'desc':true">
							<ng-container matColumnDef="id">
								<mat-header-cell *matHeaderCellDef class="font-weight-bold">{{ 'txProjectOffice.id' | translate }}</mat-header-cell>
								<mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="employee_name">
								<mat-header-cell *matHeaderCellDef class="font-weight-bold">{{ 'txProjectOffice.employees' | translate }}</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<a [routerLink]="['/kalender', { id: row.employee_id, date: (row.datum | date: 'yyyy-MM-dd') }]">{{
										row.employee_name
									}}</a>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="workstep_name">
								<mat-header-cell *matHeaderCellDef class="font-weight-bold">{{ 'txProjectOffice.task' | translate }}</mat-header-cell>
								<mat-cell *matCellDef="let row">{{ row.workstep_name }}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="datum">
								<mat-header-cell *matHeaderCellDef class="font-weight-bold">{{ 'txProjectOffice.day' | translate }}</mat-header-cell>
								<mat-cell *matCellDef="let row">{{ row.datum | date: 'dd.MM.yyyy' }}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="begin_end_time">
								<mat-header-cell *matHeaderCellDef class="font-weight-bold">{{ 'txProjectOffice.timerange' | translate }}</mat-header-cell>
								<mat-cell *matCellDef="let row"
									>{{ row.begin_time | date: 'HH:mm' }} - {{ row.end_time | date: 'HH:mm' }}</mat-cell
								>
							</ng-container>
							<ng-container matColumnDef="calculated_time">
								<mat-header-cell *matHeaderCellDef class="font-weight-bold flex-justify-content-flex-end"
									>{{ 'txProjectOffice.time' | translate }}</mat-header-cell
								>
								<mat-cell class="flex-justify-content-flex-end" *matCellDef="let row">{{
									row.calculated_time
								}}</mat-cell>
							</ng-container>
							<ng-container matColumnDef="quantity">
								<mat-header-cell *matHeaderCellDef class="font-weight-bold flex-justify-content-flex-end"
									>{{ 'txProjectOffice.amount' | translate }}</mat-header-cell
								>
								<mat-cell class="flex-justify-content-flex-end" *matCellDef="let row">{{ row.quantity }}</mat-cell>
							</ng-container>

							<mat-header-row *matHeaderRowDef="displayedColumnsBookings"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumnsBookings"></mat-row>
						</mat-table>
					</div>
				</ng-container>
			</mat-card-content>
		</mat-card>
	</div>
</div>

<ng-template #loading>
	<section class="widget display-flex flex-justify-content-center">
        <ng-container *ngIf="!initDone">
		    <mat-spinner></mat-spinner>
        </ng-container>
        <ng-container *ngIf="initDone">
            <h5 class="text-center">{{ 'general.no_data' | translate }}</h5>
        </ng-container>
	</section>
</ng-template>
