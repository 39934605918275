import { Routes } from '@angular/router';
import { TxLoginComponent } from './components/tx-login/tx-login.component';
import { TxErrorComponent } from './components/tx-error/tx-error.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { TxBuchungenComponent } from './components/tx-buchungen/txbuchungen.component';
import { TxMyAccountComponent } from './components/tx-my-account/tx-my-account.component';
import { TxWorkflowComponent } from './components/tx-workflow/txworkflow.component';
import { TxZeitkontoComponent } from './components/tx-zeitkonto/tx-zeitkonto.component';
import { TxTeamleiterComponent } from './components/tx-teamleiter/txteamleiter.component';
import { TxKalenderComponent } from './components/tx-kalender/txkalender.component';
import { TxHelpComponent } from './components/tx-help/tx-help.component';
import { TxPersonalFilesComponent } from './components/personaloffice/tx-personal-files/tx-personal-files.component';
import { PoUsersResolver } from './shared/resolver/po-users.resolver';
import { PoUserDetailResolver } from './shared/resolver/po-user-detail.resolver';
import { PoTimemodelsResolver } from './shared/resolver/po-timemodels.resolver';
import { PoTypeListsResolver } from './shared/resolver/po-typelists.resolver';
import { TxTimemodelsComponent } from './components/tx-timemodels/tx-timemodels.component';
import { PoTimemodeldetailResolver } from './shared/resolver/po-timemodeldetail.resolver';
import TxPoTimeAccountComponent from './components/personaloffice/tx-po-time-account/tx-po-time-account.component';
import { TeamleaderCalendarResolver } from './shared/resolver/teamleader-calendar.resolver';
import { LeaveGuardService } from './shared/services/leave-guard.service';
import { ProjectOfficeListResolver } from './shared/resolver/project-office-list.resolver';
import { ProjectOfficeWorkstepsResolver } from './shared/resolver/project-office-worksteps.resolver';
import { ProjectOfficeWorkstepTypesResolver } from './shared/resolver/project-office-workstepTypes.resolver';
import { ProjectOfficeProjectStatesResolver } from './shared/resolver/project-office-projectStates.resolver';
import { TxProjectOfficeComponent } from './components/projectoffice/tx-project-office/tx-project-office.component';
import { TxProjectsOfficeComponent } from './components/projectoffice/tx-projects-office/tx-projects-office.component';
import { ProjectOfficeDetailResolver } from './shared/resolver/project-office-detail.resolver';
import { ProjectOfficeDetailBookingsResolver } from './shared/resolver/project-office-detail-bookings.resolver';
import { ReportsResolver } from './shared/resolver/reports.resolver';
import { TxPlaningComponent } from './components/tx-planing/tx-planing.component';
import { PlansResolver } from './shared/resolver/plans.resolver';
import { PlanCalendarResolver } from './shared/resolver/plan-calendar.resolver';
import { TxPoAccountsComponent } from './components/personaloffice/tx-po-accounts/tx-po-accounts.component';
import { PoTeamleaderResolver } from './shared/resolver/po-teamleader.resolver';
import { KstResolver } from './shared/resolver/kst.resolver';
import { DepartmentResolver } from './shared/resolver/department.resolver';
import { EmployeeGroupsResolver } from './shared/resolver/employeeGroups.resolver';
import { TxMessageCenterComponent } from './components/tx-messagecenter/tx-messagecenter.component';
import { MessagesClientResolver } from './shared/resolver/messages-client.resolve';
import { ProjectOfficeCalendarGroupResolver } from './shared/resolver/project-office-calendar-group.resolver';
import { PoEmployeeRelationsResolver } from './shared/resolver/po-employee-relations.resolver';
import { PoTeamsResolver } from './shared/resolver/po-teams.resolver';
import TxTeamconfigurationComponent from './components/personaloffice/tx-teamconfiguration/tx-teamconfiguration.component';

export const baseRoutes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard, LeaveGuardService],
		resolve: {
			reports: ReportsResolver,
		},
		children: [
			{ path: '', redirectTo: 'buchungen', pathMatch: 'full' },
			{ path: 'buchungen', component: TxBuchungenComponent },
			{ path: 'account', component: TxMyAccountComponent },
			{ path: 'workflow', component: TxWorkflowComponent },
			{
				path: 'zeitkonto',
				resolve: {
					messages: MessagesClientResolver,
				},
				component: TxZeitkontoComponent,
			},
			{
				path: 'teamleiter',
				resolve: {
					calendar: TeamleaderCalendarResolver,
				},
				component: TxTeamleiterComponent,
			},
			{
				path: 'planung',
				resolve: {
					calendar: PlanCalendarResolver,
					plans: PlansResolver,
				},
				component: TxPlaningComponent,
			},
			{ path: 'kalender', component: TxKalenderComponent },
			{ path: 'kalender/:date/:id', component: TxKalenderComponent },
			{
				path: 'projectoffice/assingments',
				resolve: {
					worksteps: ProjectOfficeWorkstepsResolver,
					workstepTypes: ProjectOfficeWorkstepTypesResolver,
					projectStates: ProjectOfficeProjectStatesResolver,
					//list: ProjectOfficeListResolver,
				},
				component: TxProjectsOfficeComponent,
			},
			{
				path: 'projectoffice/assingments/:id',
				resolve: {
					worksteps: ProjectOfficeWorkstepsResolver,
					workstepTypes: ProjectOfficeWorkstepTypesResolver,
					projectStates: ProjectOfficeProjectStatesResolver,
					list: ProjectOfficeListResolver,
					project: ProjectOfficeDetailResolver,
					bookings: ProjectOfficeDetailBookingsResolver,
				},
				component: TxProjectOfficeComponent,
			},
			{
				path: 'personaloffice',
				children: [
					{
						path: 'personalakten',
						resolve: {
							kst: KstResolver,
							departments: DepartmentResolver,
							employeeGroups: EmployeeGroupsResolver,
							users: PoUsersResolver,
							timemodels: PoTimemodelsResolver,
							typeLists: PoTypeListsResolver,
							teamleader: PoTeamleaderResolver,
							employeeRelations: PoEmployeeRelationsResolver,
							teams: PoTeamsResolver,
						},
						children: [
							{
								path: '',
								pathMatch: 'prefix',
								redirectTo: 'all',
							},
							{
								path: ':id',
								resolve: {
									user: PoUserDetailResolver,
								},
								component: TxPersonalFilesComponent,
							},
						],
					},
					{
						path: 'zeitkonto',
						component: TxPoTimeAccountComponent,
					},
					{
						path: 'personalkonten',
						component: TxPoAccountsComponent,
						resolve: {
							calendar: PlanCalendarResolver,
							plans: PlansResolver,
						},
						children: [
							{
								path: ':date',
								pathMatch: 'full',
								component: TxPoAccountsComponent,
							},
						],
					},
					{
						path: 'messages',
						resolve: {
							users: PoUsersResolver,
						},
						component: TxMessageCenterComponent,
						children: [
							{
								path: ':id',
								component: TxMessageCenterComponent,
							},
						],
					},
					{
						path: 'teamkonfiguration',
						resolve: {
							teams: PoTeamsResolver,
							teamleader: PoTeamleaderResolver,
							users: PoUsersResolver,
						},
						children: [
							{ path: '', component: TxTeamconfigurationComponent },
							{ path: ':id', component: TxTeamconfigurationComponent },
						],
					},
					{
						path: 'zeitmodelle',
						resolve: {
							timemodels: PoTimemodelsResolver,
							calendarGroups: ProjectOfficeCalendarGroupResolver,
						},
						children: [
							{ path: '', redirectTo: '/personaloffice/zeitmodelle/all', pathMatch: 'full' },
							{
								path: ':id',
								resolve: {
									timemodels: PoTimemodelsResolver,
									timemodelDetail: PoTimemodeldetailResolver,
								},
								component: TxTimemodelsComponent,
							},
						],
					},
				],
			},

			{ path: 'help', component: TxHelpComponent },
		],
	},
	{ path: 'login', component: TxLoginComponent },
	{ path: '**', component: TxErrorComponent },
];
