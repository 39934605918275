import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { VibrationService } from '../../shared/services/vibration.service';
import { lastValueFrom, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { BarcodeFormat, Result } from '@zxing/library';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'tx-code-scanner',
	templateUrl: './tx-code-scanner.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class TxCodeScannerComponent implements OnInit, OnDestroy {
	@Input() scannerEnabled = true;
	@Input() playSound = true;
	@Input() vibrate = true;
	@Input() vibrationDuration = 1000;
	@Input() qrScan = false;
	@Input() formats: BarcodeFormat[] = [
		BarcodeFormat.EAN_13,
		BarcodeFormat.CODE_128,
		BarcodeFormat.QR_CODE,
		BarcodeFormat.CODE_39,
	];
	@Input() selectedDevice: string | null = null;
	@Input() enableDeviceSelector = false;
	@Input() configMode = false;

	@Output() select = new EventEmitter<any>();
	@Output() scanResult = new EventEmitter<any>();

	// @ts-ignore
	@ViewChild('scanner', { static: true })
	scanner: ZXingScannerComponent;

	hasDevices: boolean;
	hasPermission: boolean;
	qrResult: Result;
	availablesDevices = [{ label: 'Keine Kamera gewählt', value: '-1' }];
	selectedDropDownDevice: { label: string; value: string } | null = null;

	currentDevice: MediaDeviceInfo;
	deviceSelected = false;

	private deviceCheckInterval: NodeJS.Timeout;
	private readonly destroy$ = new Subject();

	constructor(private myVibrationService: VibrationService, private readonly appConfig: AppConfigService) {}

	handleQrCodeResult(resultString: string) {
		if (this.vibrate) {
			this.myVibrationService.vibrate(this.vibrationDuration);
		}
		this.playSuccessSound();
		this.scanResult.emit(resultString);
		// this.scanner.resetCodeReader();
	}

	playSuccessSound() {
		if (this.playSound) {
			const successSound = new Audio();
			successSound.src = '../../../assets/snd/scansuccess.wav';
			successSound.load();
			successSound.play();
		}
	}

	async onDeviceSelectChange(event: MatSelectChange) {
		const selectedValue = event.value;
		this.currentDevice = await this.scanner
			.updateVideoInputDevices()
			.then((res) => res.find((m) => m.deviceId === selectedValue));

		this.select.next(this.currentDevice);

		this.scannerEnabled = true;
		this.appConfig.cameraConfig$.pipe(take(1)).subscribe((config) => {
			config.SelectedDevice = selectedValue;
			console.log('setting camera config', config);
			this.appConfig.setCameraConfig(config);
		});
	}

	async ngOnInit() {
		// TODO the preselection of the camera does not work, the library does seem to have a bug or just plain ignore it
		this.appConfig.cameraConfig$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
			this.selectedDevice = config.SelectedDevice;

			// // set selected device in dropdown
			/*this.scanner
				.updateVideoInputDevices()
				.then((res) => res.find((m) => m.deviceId === this.selectedDevice))
				.then((device) => {
					console.log(device);
					this.deviceSelected = this.selectedDevice !== '-1';
					this.enableDeviceSelector = this.configMode;
					this.scannerEnabled = true;
					if (!device) {
						return;
					}

					this.currentDevice = device;
					this.scanner.device = device;
					this.scanner.deviceChange.next(device);
					this.selectedDropDownDevice = { label: device.label, value: device.deviceId };
				});*/
		});
		// if(this.selectedDevice === null) {
		this.scanner.camerasFound
			.pipe(
				map((devices: MediaDeviceInfo[]) => {
					this.hasDevices = true;

					for (const device of devices) {
						if (!this.availablesDevices.find((c) => c.value === device.deviceId)) {
							this.availablesDevices.push({ label: device.label, value: device.deviceId });
						}
					}
					return devices;
				}),
				take(1)
			)
			.subscribe(async (devices: MediaDeviceInfo[]) => {
				if (this.selectedDevice) {
					const device = devices.find((d) => d.deviceId === this.selectedDevice);

					this.currentDevice = await this.scanner
						.updateVideoInputDevices()
						.then((res) => res.find((m) => m.deviceId === device.deviceId));

					this.scanner.device = this.currentDevice;
					this.deviceSelected = false;

					this.deviceCheckInterval = setInterval(() => {
						if (this.currentDevice && !this.scanner.isCurrentDevice(this.currentDevice)) {
							this.scanner.device = this.currentDevice;
						}
						this.deviceSelected = this.scanner.isCurrentDevice(this.currentDevice);
					}, 1000);

					if (!this.configMode && !this.currentDevice) {
						this.enableDeviceSelector = false;
					}
				}
				/*if (this.selectedDevice) {
				const device = devices.find((d) => d.deviceId === this.selectedDevice);
				if (device) {
					this.deviceSelected = this.selectedDevice !== '-1';
					this.currentDevice = device;
					this.scanner.device = device;
					this.scanner.deviceChange.next(device);
					this.enableDeviceSelector = this.configMode;
					this.selectedDropDownDevice = { label: device.label, value: device.deviceId };
					this.scannerEnabled = true;
				}
			}*/
				// selects the devices's back camera by default
			});
		// }

		this.scanner.camerasNotFound.pipe(takeUntil(this.destroy$)).subscribe(() => (this.hasDevices = false));

		this.scanner.scanComplete.pipe(takeUntil(this.destroy$)).subscribe((result: Result) => (this.qrResult = result));

		this.scanner.permissionResponse
			.pipe(takeUntil(this.destroy$))
			.subscribe((perm: boolean) => (this.hasPermission = perm));
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
		if (this.deviceCheckInterval) {
			clearInterval(this.deviceCheckInterval);
		}
	}
}
