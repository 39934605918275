import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectDetailEntity, ProjectWorkstep } from '../../../store/entities/ProjectDetailEntity';
import { delay, filter, map, switchMap, take, tap, timeout, withLatestFrom } from 'rxjs/operators';
import { ProjectBookingEntity } from '../../../store/entities/ProjectBookingEntity';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { ProjectStateEntity } from '../../../store/entities/ProjectStateEntity';
import { ProjectOfficeService } from '../../../shared/services/project-office.service';
import { MatDialog } from '@angular/material/dialog';
import { TxProjectOfficeWorkstepDialogComponent } from '../tx-project-office-workstep-dialog/tx-project-office-workstep-dialog.component';
import { WorkstepEntity } from '../../../store/entities/WorkstepEntity';
import { firstValueFrom, from, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { getProjectList } from '../../../store/selectors/project-office.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../store/reducers';
import { ProjectEntity } from '../../../store/entities/ProjectEntity';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectFilter } from '../../../shared/interfaces/ProjectFilter';
import { ReportEntity } from '../../../store/entities/ReportEntity';
import { getReportList } from '../../../store/selectors/reports.selectors';
import { TxreportapiService } from '../../../shared/services/txreportapi.service';
import { TxModalPdfComponent } from '../../tx-modal-pdf/tx-modal-pdf.component';

@Component({
	selector: 'tx-project-office',
	templateUrl: './tx-project-office.component.html',
	styleUrls: ['./tx-project-office.component.scss'],
})
export class TxProjectOfficeComponent implements OnInit, AfterViewInit, OnDestroy {
	public Project: ProjectDetailEntity;
	public FilterProject: ProjectDetailEntity | null = null;

	public Projects$: Observable<ProjectEntity[]>;
	public Bookings: ProjectBookingEntity[];
	public FilterBookings: ProjectBookingEntity[] | null = null;
	public Worksteps: WorkstepEntity[];
	public ProjectStates: ProjectStateEntity[];

	public WorkstepDatasource: MatTableDataSource<ProjectWorkstep> | null = null;

	public displayedColumns = ['workstep_name', 'sum_quantity', 'calculated_time', 'actions'];
	public displayedColumnsBookings = [
		'employee_name',
		'workstep_name',
		'datum',
		'begin_end_time',
		'calculated_time',
		'quantity',
	];

	public initDone = false;

	private projectNumberControl = this.fb.control(
		{
			value: null,
			disabled: true,
		},
		[Validators.required]
	);

	public Filter: ProjectFilter = new ProjectFilter(null, null);

	public ProjectForm = this.fb.group({
		project_id: this.fb.control({
			value: 0,
			disabled: true,
		}),
		project_number: this.projectNumberControl,
		project_name: [null, [Validators.required]],
		project_state: [null, [Validators.required]],
		unproductive: [null, [Validators.required]],
		description: [null],
		creation_date: this.fb.control({
			value: null,
			disabled: true,
		}),
		target_date: [null],
		target_time: [null],
		calculated_time: this.fb.control({
			value: null,
			disabled: true,
		}),
		target_quantity: [null],
	});

	reports$: Observable<ReportEntity[]>;
	loadingReport = false;
	reportUrl = '';

	private subs: Subscription[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private service: ProjectOfficeService,
		private dialog: MatDialog,
		private store: Store<State>,
		private readonly reportService: TxreportapiService
	) {
		this.reports$ = this.store.select(getReportList).pipe(
			map((reports) => reports.filter((report) => report.report_section === 'project')),
			filter((reports) => reports.length > 0)
		);

		this.Projects$ = this.store.select(getProjectList);
		this.subs.push(
			route.data.subscribe((data) => {
				const { project, projectStates, bookings, worksteps } = data;
				this.Project = project;
				this.ProjectStates = projectStates;
				this.Bookings = bookings;
				this.Worksteps = worksteps;
				this.initDone = true;

				this.initForm(this.Project);
			})
		);
		/*this.Project$ = route.data.pipe(
            map(resolved => {
                this.initForm(resolved.project);
                return resolved.project;
            })
        );

        this.Bookings$ = route.data.pipe(
            map(resolved => {
                return resolved.bookings;
            })
        );

        this.ProjectStates$ = route.data.pipe(
            map(resolved => {
                return resolved.projectStates;
            })
        );

        this.Worksteps$ = route.data.pipe(
            map(resolved => {
                return resolved.worksteps;
            })
        );*/
	}

	ngOnInit(): void {}

	ngAfterViewInit() {}

	isFiltering() {
		return !!this.Filter.from && !!this.Filter.to;
	}

	initForm(project: ProjectDetailEntity) {
		this.ProjectForm.setValue({
			...project.toFormValue(),
		});
		if (project.project_id === 0) {
			this.projectNumberControl.enable();
		}
		if (!this.WorkstepDatasource) {
			this.WorkstepDatasource = new MatTableDataSource<ProjectWorkstep>(
				this.getWorkstepsFiltered(project.ListOfProjectWorkstep)
			);
		} else {
			this.assignWorkstepDataSource(project.ListOfProjectWorkstep);
		}
		/*this.ProjectForm = this.fb.group({
            project_id: this.fb.control({
                value: item.project_id ?? 0,
                disabled: true
            }),
            project_number: [item.project_number, [Validators.required]],
            project_name: [item.project_name, [Validators.required]],
            project_state: [item.project_state, [Validators.required]],
            unproductive: [item.unproductive, [Validators.required]],
            description: [item.description],
            creation_date: this.fb.control({
                value: item.creation_date ?? moment().toDate(),
                disabled: true
            }),
            target_date: [item.target_date, [Validators.required]],
            target_time: [item.target_time],
            calculated_time: this.fb.control({
                value: item.calculated_time,
                disabled: true
            }),
            target_quantity: [item.target_quantity]
        });*/
	}

	getWorkstepsFiltered(worksteps: ProjectWorkstep[]): ProjectWorkstep[] {
		return worksteps.filter((w) => !w.deleted);
	}

	resetForm(item: ProjectDetailEntity) {
		this.ProjectForm.reset({
			...item.toFormValue(),
		});
		this.resetWorksteps();

		if (item.project_id === 0) {
			this.router.navigate(['/projectoffice/assingments']);
		}
	}

	async loadAllBookings(id: number) {
		this.Bookings = await this.service.getProjectBookings(id, true).pipe(delay(200)).toPromise();
	}

	saveOrCreate(event: Event, project: ProjectDetailEntity, redirect = false) {
		if (event) {
			event.preventDefault();
			event.stopImmediatePropagation();
		}
		let target_date: moment.Moment | string = moment(this.ProjectForm.getRawValue().target_date);
		if (!target_date.isValid()) {
			target_date = '';
		} else {
			target_date = target_date.format('YYYY-MM-DDTHH:mm:ss');
		}
		const newProject = Object.assign(new ProjectDetailEntity(), this.ProjectForm.getRawValue(), {
			ListOfProjectWorkstep: project.ListOfProjectWorkstep,
			target_date,
		});
		this.service
			.saveOrCreate(newProject)
			.pipe(take(1))
			.subscribe((data) => {
				if ('payload' in data) {
					this.WorkstepDatasource.data = null;
					of(null)
						.pipe(
							switchMap(() => from(this.Refresh(this.Project))),
							take(1)
						)
						.subscribe(() => {
							this.ProjectForm.markAsUntouched();
							this.ProjectForm.markAsPristine();
						});
					if (redirect) {
						this.router.navigate(['/projectoffice/assingments']);
					}
				}
			});
	}

	async openDialog(item: ProjectDetailEntity) {
		const dialogRef = this.dialog.open(TxProjectOfficeWorkstepDialogComponent, {
			data: {
				project: item,
				available: this.Worksteps,
			},
		});

		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe((result: ProjectWorkstep[]) => {
				if (result) {
					item.ListOfProjectWorkstep = [...item.ListOfProjectWorkstep, ...result];
					this.WorkstepDatasource.data = item.ListOfProjectWorkstep;
					this.ProjectForm.markAsDirty();
					this.ProjectForm.markAsTouched();
				}
			});
	}

	async Refresh(project: ProjectDetailEntity) {
		this.Project = await lastValueFrom(this.service.getProject(project.project_id));
		this.assignWorkstepDataSource(this.Project.ListOfProjectWorkstep);
		return this.Project;
	}

	deleteWorkstep(row: ProjectWorkstep) {
		row.deleted = true;
		this.assignWorkstepDataSource(this.Project.ListOfProjectWorkstep);
		this.ProjectForm.markAsTouched();
		this.ProjectForm.markAsDirty();
	}

	assignWorkstepDataSource(data: ProjectWorkstep[]) {
		if (this.WorkstepDatasource) {
			this.WorkstepDatasource.data = this.getWorkstepsFiltered(data);
		}
	}

	resetWorksteps() {
		this.Project.ListOfProjectWorkstep = this.Project.ListOfProjectWorkstep.filter(
			(p) => p.workstep_project_id > 0
		).map((p) => {
			p.deleted = false;
			return p;
		});
		this.WorkstepDatasource.data = this.Project.ListOfProjectWorkstep;
	}

	async startFilter() {
		this.FilterProject = await this.service.getProject(this.Project.project_id, this.Filter).pipe(take(1)).toPromise();
		this.WorkstepDatasource.data = this.FilterProject.ListOfProjectWorkstep;

		this.FilterBookings = await this.service
			.getProjectBookings(this.Project.project_id, false, this.Filter)
			.pipe(take(1))
			.toPromise();
	}

	async downloadReport(report: ReportEntity) {
		this.loadingReport = true;
		const data = await firstValueFrom(
			this.reportService.getReport(report, {
				idFilter: [this.Project.project_id],
				dateBeginFilter: this.Filter?.from,
				dateEndFilter: this.Filter?.to,
			})
		);
		this.dialog.open(TxModalPdfComponent, {
			minHeight: 'calc(100vh - 90px)',
			height: 'auto',
			width: '1000px',
			data: {
				blob: data,
				title: report.report_name,
			},
		});
		//this.reportUrl = URL.createObjectURL(data);
		//Download(data, `${this.Project.project_number}_${report.report_name}.pdf`);
		this.loadingReport = false;
	}

	ngOnDestroy() {
		this.subs.map((s) => s.unsubscribe());
	}
}
