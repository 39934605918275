import 'jquery-slimscroll';
// NG Base
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, NgZone, OnInit } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import localeDe from '@angular/common/locales/de';
// Routes and Configs
import { baseRoutes } from './app.route';
// Base Component
import { AppComponent } from './app.component';
// Modules
import { DeviceDetectorService } from 'ngx-device-detector';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Services
import { TeamMemberService } from './shared/services/team-member.service';
import { VibrationService } from './shared/services/vibration.service';
import { MessengerService } from './shared/services/messenger.service';
import { BookingService } from './shared/services/booking.service';
import { FormatDate } from './shared/services/formatdate.service';
import { InfocenterService } from './shared/services/infocenter.service';
import { ActiveBDEService } from './shared/services/activebde.service';
import { UserInfoService } from './shared/services/user-info.service';
import { TlProtocolService } from './shared/services/tl-protocol.service';
import { ImageUploadService } from './shared/services/image-upload.service';
import { GeoDataService } from './shared/services/geodata.service';
import { ConnectionStateService } from './shared/services/connection-state.service';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/auth-guard.service';
import { TxApiService } from './shared/services/txapi.service';
import { AppConfigService } from './shared/services/appconfig.service';
import { HeartbeatService } from './shared/services/heartbeat.service';
import { TerminalService } from './shared/services/terminal.service';

import { RequestQueueInterceptor } from './shared/interceptors/request-queue.interceptor';

import { LayoutComponent } from './layout/layout.component';
import { TxSidebarComponent } from './layout/sidebar/sidebar.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { InfoCenterComponent } from './layout/infocenter/infocenter.component';
import { GroupByPipe, OrderByPipe } from './layout/infocenter/infocenter.pipe';
import { TxHelpComponent } from './components/tx-help/tx-help.component';

import { ComponentsModule } from './components/components.module';
import { TxSharedModule } from './shared/shared.module';
import { WorkflowService } from './shared/services/workflow.service';
import { ErrorloggerService } from './shared/services/errorlogger.service';
import { StorageModule } from '@ngx-pwa/local-storage';
import { TxStoreModule } from './store/store.module';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { WidgetsModule } from './widgets/widgets.module';
import { MatListModule } from '@angular/material/list';
import { ValidSessionInterceptor } from './shared/interceptors/valid-session.interceptor';
import { StalledRequestsService } from './shared/services/stalled-requests.service';
import { filter, take } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMaskModule } from 'ngx-mask';
import { UserStatusService } from './shared/services/user-status.service';
import { DateAdapter } from '@angular/material/core';
import { TxDateAdapter } from './shared/adapter/tx-date-adapter';
import { TxreportapiService } from './shared/services/txreportapi.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { getNotifications } from './store/selectors/user.selectors';
import { removeNotification } from './store/actions/user.actions';
import { NavigationService } from './shared/services/navigation.service';
import { MatIconModule } from '@angular/material/icon';
import { MessageCenterService } from './shared/services/message-center.service';
import { DndModule } from 'ngx-drag-drop';

registerLocaleData(localeDe);

@NgModule({
	declarations: [
		AppComponent,
		LayoutComponent,
		TxSidebarComponent,
		NavbarComponent,
		InfoCenterComponent,
		GroupByPipe,
		OrderByPipe,
		TxHelpComponent,
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		HttpClientModule,
		BrowserAnimationsModule,
		TxSharedModule,
		TxStoreModule,
		ComponentsModule,
		WidgetsModule,
		RouterModule.forRoot(baseRoutes, {
			useHash: true,
			enableTracing: false,
			relativeLinkResolution: 'legacy',
		}),
		StorageModule.forRoot({ IDBNoWrap: false }),
		MenubarModule,
		PanelMenuModule,
		MatListModule,
		// ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
		MatButtonModule,
		MatMenuModule,
		NgxMaskModule.forRoot(),
		FontAwesomeModule,
		MatIconModule,
	],
	providers: [
		/*{
			provide: RouteReuseStrategy,
			useClass: CacheRouteReuseStrategy,
		},*/
		{
			provide: LOCALE_ID,
			useValue: 'de-DE',
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ValidSessionInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestQueueInterceptor,
			multi: true,
		},
		{ provide: ErrorHandler, useClass: ErrorloggerService },
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => null,
			deps: [NavigationService],
			multi: true,
		},
		AppConfigService,
		AuthService,
		AuthGuard,
		TxApiService,
		TxreportapiService,
		InfocenterService,
		ActiveBDEService,
		GeoDataService,
		UserInfoService,
		UserStatusService,
		TlProtocolService,
		ConnectionStateService,
		ImageUploadService,
		TeamMemberService,
		VibrationService,
		BookingService,
		MessengerService,
		HeartbeatService,
		TerminalService,
		WorkflowService,
		FormatDate,
		DeviceDetectorService,
		{ provide: DateAdapter, useClass: TxDateAdapter },
	],
	exports: [],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(
		private stalled: StalledRequestsService,
		private connection: ConnectionStateService,
		private authService: AuthService,
		private ngZone: NgZone,
		private store: Store,
		private messageCenterService: MessageCenterService
	) {
		// ngZone.runOutsideAngular(() => dom.watch());
		const s = this.authService.session$
			.pipe(
				filter((s) => !!s),
				//auto unsubscribe after one emit
				take(1)
			)
			.subscribe((session) => {
				if (session.always_online === 1) {
					this.connection.isOnlineSubject.next(true);
				} else {
					this.connection.forceOfflineSubject.subscribe((state) => {
						if (state === true) {
							navigator.serviceWorker.removeEventListener('message', this.swEventListener.bind(this));
							// offline is forced, dont care further
							this.connection.isOnlineSubject.next(false);
						} else {
							navigator.serviceWorker.addEventListener('message', this.swEventListener.bind(this));
						}
					});
				}
			});

		if (Notification.permission !== 'granted') {
			Notification.requestPermission();
		}

		this.store.select(getNotifications).subscribe((notifications) => {
			for (const notification of notifications) {
				var n = new Notification('TX portal', {
					body: notification.message,
					icon: 'assets/img/tx_logo.svg',
					dir: 'auto',
					requireInteraction: true,
					renotify: true,
					silent: false,
					tag: notification.id,
				});

				this.store.dispatch(removeNotification(notification));
			}
		});
		/* var notification = new Notification('TX portal', {
            body: 'Test notification',
            icon: 'assets/img/favicon-96x96.png',
            dir: 'auto',
            requireInteraction: true,
            renotify: true,
            silent: false,
            tag: 'test'
        }); */
	}

	private swEventListener(ev: MessageEvent) {
		switch (ev.data.type) {
			case 'online':
				this.connection.isOnlineSubject.next(true);
				this.connection.isIntermediateSubject.next(false);
				break;
			case 'intermediate':
				this.connection.isIntermediateSubject.next(true);
				break;
			case 'offline':
			default:
				this.connection.isOnlineSubject.next(false);
				this.connection.isIntermediateSubject.next(false);
				break;
		}
	}
}
