<div>
	<mat-card class="mat-elevation-z0">
		<mat-card-title class="flex justify-content-between">
			<div>{{ 'txPlaning.dutyRoster' | translate }}</div>
			<div>
				<button *ngIf="DeleteMode" class="mr-2" mat-mini-fab (click)="abortDeleteMode()">
					<fa-icon icon="times"></fa-icon>
				</button>
				<button
					class="mr-2"
					[ngClass]="{ 'bg-green-600': DeleteMode }"
					color="warn"
					mat-mini-fab
					(click)="toggleDeleteMode()">
					<fa-icon [icon]="DeleteMode ? 'check' : 'trash'"></fa-icon>
				</button>
				<button color="primary" mat-mini-fab (click)="openPlansDialog()">
					<fa-icon icon="cog"></fa-icon>
				</button>
			</div>
		</mat-card-title>
		<mat-card-subtitle>
			<div class="mt-4" *ngIf="workflowGroups$ | async as workflowGroups">
				<mat-button-toggle-group
					(change)="toggleGroup($event)"
					name="workflowGroups"
					aria-label="Workflow Gruppen"
					multiple>
					<mat-button-toggle
						*ngFor="let group of workflowGroups"
						class="workflow-toggle-button"
						[value]="group.id"
						[checked]="SelectedGroups.includes(group.id)"
						>{{ group.name }}</mat-button-toggle
					>
				</mat-button-toggle-group>
			</div>
		</mat-card-subtitle>
		<mat-card-content *ngIf="workflows$ | async as workflows">
			<full-calendar #calendar [deepChangeDetection]="true" [options]="CalendarOptions"> </full-calendar>
		</mat-card-content>
	</mat-card>
</div>
