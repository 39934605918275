<div *ngIf="Messages" class="message-center py-2 bg-gray-100 border-round">
    <div class="px-2 flex justify-content-between">
        <div class="text-xl font-bold">
            {{ Title }}
        </div>
        <mat-checkbox class="mx-2" [(ngModel)]="WithArchived" (change)="WithArchivedChange.emit($event.checked); refresh.emit();">{{ 'txMessageCenter.show_archived' | translate }}</mat-checkbox>
    </div>
    <div #scrollBot
         class="bg-white flex flex-column p-2 overflow-y-auto"
         style="height: {{height}}px; scroll-behavior: smooth; background-color: hsl(0, 0%, 99%)!important;"
    >
        <div *ngIf="Messages.length === 0" class="flex align-items-center justify-content-center text-xl font-bold">
            Keine Nachrichten vorhanden
        </div>
        <div *ngFor="let message of Messages"
             class="flex message"
             [ngClass]="{ 'justify-content-end': isZero(message.target_persnr)}"
        >
            <div class="flex flex-column" style="max-width: 600px;">
                <div class="relative" [ngClass]="{
                        'opacity-50': message.archived,
                        'p-2 rounded border-1 border-gray-100': message.children && message.children.length > 0
                    }">
                    <div class="flex text-gray-400 text-xs" [ngClass]="{
                            'justify-content-end': isZero(message.target_persnr)
                        }">
                        <div *ngIf="!isZero(message.target_persnr)">
                            <fa-icon *ngIf="message.archived" class="mr-1" icon="archive"></fa-icon>
                        </div>
                        {{ message.creation_date | formatTime:'DD.MM.YYYY, HH:mm' }}
                        <div *ngIf="isZero(message.target_persnr)">
                            <fa-icon *ngIf="message.archived" class="ml-1" icon="archive"></fa-icon>
                        </div>
                    </div>
                    <div class="flex" [ngClass]="{
                            'justify-content-end': isZero(message.target_persnr),
                            'justify-content-start': !isZero(message.target_persnr)
                        }">
                        <div class="relative inline-block font-bold text-white-alpha-90 rounded px-2 py-2 shadow-2" [ngClass]="{
                                'bg-gray-400 mr-14': !isZero(message.target_persnr),
                                'bg-blue-400 ml-14': isZero(message.target_persnr)
                            }">
                            {{ message.message }}
                            <ng-container *ngIf="!message.children || message.children.length === 0" >
                                <div *ngTemplateOutlet="icons"></div>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="message.children && message.children.length > 0" class="pt-2" [ngClass]="{
                            'pl-4': !isZero(message.target_persnr),
                            'pr-4': isZero(message.target_persnr)
                        }">
                        <div *ngFor="let child of message.children" class="message-small">
                            <div class="text-xs text-gray-400">{{ child.creation_date | formatTime:'DD.MM.YYYY, HH:mm' }}</div>
                            <div class="flex" [ngClass]="{
                                    'justify-content-end': !isZero(message.target_persnr),
                                    'justify-content-start': isZero(message.target_persnr)
                                }">
                                <div class="p-2 font-bold text-white-alpha-90 rounded shadow-2" [ngClass]="{
                                        'bg-gray-400': !isZero(child.target_persnr),
                                        'bg-blue-400': isZero(child.target_persnr)
                                    }">
                                    {{ child.message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="message.children && message.children.length > 0">
                        <div *ngTemplateOutlet="icons"></div>
                    </ng-container>
                </div>
            </div>
            <ng-template #icons>
                <div *ngIf="!message.archived"
                     class="absolute top-0 icons flex"
                     [ngClass]="{
                                    'left-0 -translate-x-100 pr-2': isZero(message.target_persnr),
                                    'right-0 translate-x-100 pl-2': !isZero(message.target_persnr)
                                }"
                >
                    <ng-container *ngIf="!isZero(message.target_persnr)">
                        <!--<button mat-icon-button class="m-0 p-0 w-min h-1rem line-height-1 cursor-pointer" [disabled]="message.source_persnr != currentUser.personalnr" (click)="archiveMessage(message, true)">
                            <fa-icon class="text-danger" icon="trash"></fa-icon>
                        </button>-->
                        <button mat-mini-fab class="text-black-alpha-90 mr-2" (click)="archive.emit({ message, remove: false })">
                            <fa-icon icon="archive"></fa-icon>
                        </button>
                        <button mat-mini-fab class="text-orange-500" (click)="this.AnswerMessageChange.emit(message); messageInput.focus()">
                            <fa-icon icon="paper-plane"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="isZero(message.target_persnr)">
                        <button *ngIf="message.children && message.children.length > 0"
                                mat-mini-fab
                                class="text-orange-500 mr-2" (click)="this.AnswerMessageChange.emit(message); messageInput.focus()">
                            <fa-icon icon="paper-plane"></fa-icon>
                        </button>
                        <button *ngIf="!message.children || message.children.length === 0"
                                txConfirmClick
                                confirmText="Wollen Sie diese Nachricht wirklich löschen?"
                                title="Löschen?"
                                [buttonTexts]="{ approve: 'Löschen', cancel: 'Abbrechen'}"
                                mat-mini-fab class="text-red-500 mr-2"
                                (confirmClick)="archive.emit({ message, remove: true })">
                            <fa-icon class="text-danger" icon="trash"></fa-icon>
                        </button>
                        <!--<button mat-icon-button class="text-xs w-min h-1rem line-height-1 cursor-pointer text-black-alpha-90" (click)="archiveMessage(message)">
                            <fa-icon icon="archive"></fa-icon>
                        </button>-->
                    </ng-container>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="flex flex-column">
        <div *ngIf="AnswerMessage" class="px-2 pt-2 mr-1 flex align-items-center">
            <div class="flex-grow-1 rounded border-orange-400 p-2 text-white bg-gray-600 shadow-2" style="border-left: solid 5px;">
                <div class="text-xs text-gray-100">{{ AnswerMessage.creation_date | formatTime:'DD.MM.YYYY, HH:mm' }}</div>
                <div>{{ AnswerMessage.message }}</div>
            </div>
            <div>
                <button mat-icon-button class="text-gray-400 ml-3" (click)="this.AnswerMessageChange.emit(null)">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </div>
        </div>
        <div class="flex border-round-bottom pt-2 px-2">
            <mat-form-field class="full-width mr-3"
                            appearance="outline"
                            style="background-color: hsl(0, 0%, 98%); border-radius: 5px;">
                <mat-label>
                    {{ (is_office ? 'txMessageCenter.new_message' : 'txMessageCenter.new_message_to_hq') | translate }}
                </mat-label>
                <input #messageInput matInput [(ngModel)]="Message" (keyup.enter)="sendMessage()">
                <button matSuffix mat-icon-button class="text-xl" color="primary" (click)="sendMessage()">
                    <mat-icon>send</mat-icon>
                </button>
            </mat-form-field>
            <div class="flex align-items-center mr-1">
                <button mat-mini-fab (click)="refresh.emit()">
                    <fa-icon icon="sync"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
