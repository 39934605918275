<full-calendar #fullCalendar [options]="calendarOptions"></full-calendar>
<!--<full-calendar
    [options]="{
        firstDay: 1,
        businessHours: [{
            daysOfWeek: [1,2],
            startTime: '06:00',
            endTime: '16:00'
        }],
        locales: [{ code: 'de' }],
        allDaySlot: true,
		allDayText: '',
        selectable: true,
        initialView: 'timeGridWeek'
    }"
></full-calendar>-->
<div class="dropdown-menu fc-settings" [style.display]="showFcSettingsMenu ? 'block' : 'none'">
	<li>
		<h6 class="dropdown-header">{{ 'txScheduler.calendarView' | translate }}</h6>
	</li>
	<li>
		<a class="dropdown-item" [routerLink]="" (click)="changeView('timeGridDay')">{{ 'txScheduler.day' | translate }}</a>
	</li>
	<li>
		<a class="dropdown-item" [routerLink]="" (click)="changeView('timeGridThreeDays')">{{
			'txScheduler.3days' | translate
		}}</a>
	</li>
	<li>
		<a class="dropdown-item" [routerLink]="" (click)="changeView('timeGridWorkday')">{{
			'txScheduler.workday' | translate
		}}</a>
	</li>
	<li>
		<a class="dropdown-item" [routerLink]="" (click)="changeView('timeGridWeek')">{{
			'txScheduler.week' | translate
		}}</a>
	</li>
	<li>
		<a class="dropdown-item" [routerLink]="" (click)="changeView('dayGridMonth')">{{
			'txScheduler.month' | translate
		}}</a>
	</li>
	<li class="dropdown-divider"></li>
	<li>
		<h6 class="dropdown-header">{{ 'txScheduler.intervalView' | translate }}</h6>
	</li>
	<li>
		<a class="dropdown-item" [routerLink]="" (click)="toggleHourMode()">{{ 'txScheduler.hourMode' | translate }}</a>
	</li>
	<li>
		<a class="dropdown-item" [routerLink]="" (click)="toggleSlotMode()">{{ 'txScheduler.intervalMode' | translate }}</a>
	</li>
</div>
<p-dialog
	[(visible)]="newEventDialog"
	[breakpoint]="640"
	[closable]="false"
	[closeOnEscape]="false"
	[contentStyle]="{ 'overflow-y': 'auto' }"
	[contentStyle]="{ 'min-height': '200px' }"
	[draggable]="false"
	[modal]="true"
	[resizable]="false"
	[responsive]="true"
	[style]="{ overflow: 'visible', width: '640px', 'min-width': '320px' }"
	appendTo="body"
	[header]="'txBuchungen.timeRegistration' | translate"
	showEffect="fade">
	<div [formGroup]="myCreateForm">
		<div class="p-fluid">
			<div *ngIf="(myBuchungsTyp.length >= 2 || teamLeaderFor) && dialogType !== 'FZ'">
				<div class="grid">
					<div class="col-4">
						<label for="typ">{{ 'txScheduler.bookingType' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[options]="myBuchungsTyp"
							formControlName="type"
							id="typ"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"
                            (ngModelChange)="saveCalendarEntityValues('bookingType', $event)"
                        ></p-dropdown>
					</div>
				</div>
			</div>
			<div *ngIf="dialogType === 'PZE'">
				<div *ngIf="myAuthService?.getSession()?.KST == 1 || teamLeaderFor" class="grid">
					<div class="col-4">
						<label for="Kostenstelle">{{ 'txScheduler.costCentre' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="true"
							[options]="myCalendarKSTArray"
							formControlName="kst_nummer"
							id="Kostenstelle"
							name="Kostenstelle"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"
                            (ngModelChange)="saveCalendarEntityValues('costCentre', $event)"
                        ></p-dropdown>
					</div>
				</div>
			</div>

			<div *ngIf="dialogType == 'BDE'">
				<div class="grid">
					<div class="col-4">
						<label for="myGuiAuftragDropdown">{{ 'tx-bde.assignment' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="true"
							[options]="myAuftragsArray"
                            (keyup)="onSearchAuftrag($event)"
							formControlName="auftrag_nummer"
							id="myGuiAuftragDropdown"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"
                            (ngModelChange)="saveCalendarEntityValues('assignment', $event)"
                        ></p-dropdown>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label for="myGuiAuftragsschrittDropdown">{{ 'tx-bde.task' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="true"
							[options]="myAuftragsschrittArray"
                            [disabled]="!myCreateForm.get('auftrag_nummer').value"
							formControlName="schritt_nummer"
							id="myGuiAuftragsschrittDropdown"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"></p-dropdown>
					</div>
				</div>
			</div>

			<div *ngIf="dialogType == 'FZ'">
				<div class="grid">
					<div class="col-4">
						<label for="Fehlzeit">{{ 'txApplyBooking.missingTime' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="false"
							[options]="myListOfWorkflowTyp"
							formControlName="fehlzeit_id"
							id="Fehlzeit"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"></p-dropdown>
					</div>
				</div>
			</div>
			<div *ngIf="dialogType == 'PZE' || dialogType == 'BDE'">
				<div class="grid">
					<div class="col-4">
						<label for="Datum">{{ 'general.date' | translate }}</label>
					</div>
					<div class="col-8">
						<p-calendar
                            [readonlyInput]="true"
							readOnly="true"
							appendTo="body"
							dateFormat="dd.mm.yy"
							formControlName="datum"
                            [firstDayOfWeek]="1"
							id="Datum"
                        ></p-calendar>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label for="ZeiterfassungStart2">{{ 'general.start' | translate }}</label>
					</div>
					<div class="col-8">
						<wl-clockpicker
							[autoclose]="true"
							donetext="Fertig"
							formControlName="book_beginn"
							id="ZeiterfassungStart2"
							name="ZeiterfassungStart"
							[placeholder]="'general.time' | translate"></wl-clockpicker>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label for="ZeiterfassungEnde">{{ 'general.end' | translate }}</label>
					</div>
					<div class="col-8">
						<wl-clockpicker
							[autoclose]="true"
							donetext="Fertig"
							formControlName="book_end"
							id="ZeiterfassungEnde"
							[placeholder]="'general.time' | translate"></wl-clockpicker>
					</div>
				</div>
			</div>

			<div *ngIf="dialogType == 'FZ'">
				<div class="grid">
					<div class="col-4">
						<label for="Startdatum">{{ 'general.startDate' | translate }}</label>
					</div>
					<div class="col-8">
						<p-calendar [firstDayOfWeek]="1" dateFormat="dd.mm.yy" appendTo="body" formControlName="fzstart" id="Startdatum"></p-calendar>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label for="Enddatum">{{ 'general.endDate' | translate }}</label>
					</div>
					<div class="col-8">
						<p-calendar [firstDayOfWeek]="1" dateFormat="dd.mm.yy" appendTo="body" formControlName="fzende" id="Enddatum"></p-calendar>
					</div>
				</div>
			</div>
			<div *ngIf="dialogType === 'BDE'">
				<div class="grid">
					<div class="col-4">
						<label>{{ 'general.quantity' | translate }}</label>
					</div>
					<div class="col-8">
						<input pInputText formControlName="quantity" />
					</div>
				</div>
			</div>
			<div class="grid">
				<div class="col-4">
					<label>{{ 'general.note' | translate }}</label>
				</div>
				<div class="col-8">
					<textarea [autoResize]="false" [rows]="5" formControlName="info" pInputTextarea></textarea>
				</div>
			</div>
		</div>
		<div>
			<div class="grid">
				<div class="col-6">
					<button
						(click)="resetForm()"
						class="p-button-danger"
						icon="fa fa-close"
						[label]="'general.abort' | translate"
						pButton
						type="button"></button>
				</div>
				<div class="col-6">
					<ng-container *ngIf="dialogType === 'PZE' || dialogType === 'FZ'">
						<button
							*ngIf="session.calendar_pze === 1 || teamLeaderFor"
							(click)="createEvent(dialogType)"
							[disabled]="myCreateForm.invalid"
							class="p-button-success alignright"
							icon="fa fa-check"
							[label]="'general.enter' | translate"
							pButton
							type="button"></button>
						<button
							*ngIf="session.calendar_pze === 2 && !teamLeaderFor"
							(click)="createEvent(dialogType)"
							[disabled]="myCreateForm.invalid"
							class="p-button-success alignright"
							icon="fa fa-check"
							[label]="'general.apply' | translate"
							pButton
							type="button"></button>
					</ng-container>
					<ng-container *ngIf="dialogType === 'BDE'">
						<button
							(click)="createEvent(dialogType)"
							[disabled]="myCreateForm.invalid"
							class="p-button-success alignright"
							icon="fa fa-check"
							[label]="'general.enter' | translate"
							pButton
							type="button"></button>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog
	[(visible)]="editEventDialog"
	[breakpoint]="640"
	[closable]="false"
	[closeOnEscape]="false"
	[contentStyle]="{ 'overflow-y': 'auto' }"
	[contentStyle]="{ 'min-height': '200px' }"
	[draggable]="true"
	[header]="editEventDialogTitle"
	[modal]="true"
	[resizable]="false"
	[responsive]="true"
	[style]="{ overflow: 'visible', width: '640px', 'min-width': '320px' }"
	appendTo="body"
	showEffect="fade">
	<div [formGroup]="myEditForm">
		<div class="p-fluid">
			<div *ngIf="editEventType == 'PZE'">
				<div *ngIf="myAuthService?.getSession()?.KST == 1 || teamLeaderFor" class="grid">
					<div class="col-4">
						<label for="Kostenstelle">{{ 'txScheduler.costCentre' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="true"
							[options]="myCalendarKSTArray"
							formControlName="kst"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"></p-dropdown>
					</div>
				</div>
			</div>

			<div *ngIf="editEventType == 'BDE'">
				<div class="grid">
					<div class="col-4">
						<label for="myGuiAuftragDropdown">{{ 'tx-bde.assignment' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="true"
							[options]="myAuftragsArray"
                            (keyup)="onSearchAuftrag($event)"
							formControlName="auftrag"
							id="BdeMyGuiAuftragDropdown"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"></p-dropdown>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label for="myGuiAuftragsschrittDropdown">{{ 'tx-bde.task' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="true"
							[options]="myAuftragsschrittArray"
							formControlName="schritt"
							id="BdeMyGuiAuftragsschrittDropdown"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"></p-dropdown>
					</div>
				</div>
			</div>

			<div *ngIf="editEventType == 'FZ'">
				<div class="grid">
					<div class="col-4">
						<label>{{ 'txApplyBooking.missingTime' | translate }}</label>
					</div>
					<div class="col-8">
						<p-dropdown
							[filter]="true"
							[options]="myListOfWorkflowTyp"
							formControlName="fehlzeit"
							[placeholder]="'general.pleaseChoose' | translate"
							styleClass="fullwidthDiv"></p-dropdown>
					</div>
				</div>
				<div *ngIf="myCurrentPzeEvent.fehlzeit_dauer != null" class="grid">
					<div class="col-4"><label>Dauer</label></div>
					<div class="col-8">{{ myCurrentPzeEvent.fehlzeit_dauer }}</div>
				</div>
			</div>
			<div *ngIf="editEventType == 'BDE' || editEventType == 'PZE'">
				<div class="grid">
					<div class="col-4">
						<label>{{ 'general.date' | translate }}</label>
					</div>
					<div class="col-8">
						<p-calendar [firstDayOfWeek]="1" appendTo="body" dateFormat="dd.mm.yy" formControlName="datum" [readonlyInput]="true"></p-calendar>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label>{{ 'general.start' | translate }}</label>
					</div>
					<div class="col-8">
						<wl-clockpicker
							[autoclose]="true"
							donetext="Fertig"
							formControlName="start"
							id="start"
							[placeholder]="'general.time' | translate"></wl-clockpicker>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label>{{ 'general.end' | translate }}</label>
					</div>
					<div class="col-8">
						<wl-clockpicker
							[autoclose]="true"
							donetext="Fertig"
							formControlName="ende"
							id="ende"
							[placeholder]="'general.time' | translate"></wl-clockpicker>
					</div>
				</div>
			</div>
			<div *ngIf="editEventType === 'BDE'">
				<div class="grid">
					<div class="col-4">
						<label>{{ 'general.quantity' | translate }}</label>
					</div>
					<div class="col-8">
						<input pInputText formControlName="quantity" />
					</div>
				</div>
			</div>
			<div *ngIf="editEventType == 'FZ'">
				<div class="grid">
					<div class="col-4">
						<label for="StartdatumEdit">{{ 'general.startDate' | translate }}</label>
					</div>
					<div class="col-8">
						<p-calendar
                            [firstDayOfWeek]="1"
                            [readonlyInput]="true"
							readOnly="true"
							appendTo="body"
							dateFormat="dd.mm.yy"
							formControlName="fzstart"
							id="StartdatumEdit"></p-calendar>
					</div>
				</div>
				<div class="grid">
					<div class="col-4">
						<label for="EnddatumEdit">{{ 'general.endDate' | translate }}</label>
					</div>
					<div class="col-8">
						<p-calendar
                            [firstDayOfWeek]="1"
                            [readonlyInput]="true"
							readOnly="true"
							appendTo="body"
							dateFormat="dd.mm.yy"
							formControlName="fzende"
							id="EnddatumEdit"></p-calendar>
					</div>
				</div>
			</div>
			<div class="grid">
				<div class="col-4">
					<label>{{ 'general.note' | translate }}</label>
				</div>
				<div class="col-8">
					<textarea [autoResize]="false" [rows]="5" formControlName="info" pInputTextarea></textarea>
				</div>
			</div>
			<div *ngIf="editEventType != 'FZ' && bookProtocol.length >= 1" class="grid">
				<div class="col-12">
					<p-accordion [styleClass]="'txBookProtocol'">
						<p-accordionTab>
							<ng-template pTemplate="header">
								<div class="p-2">
									<span class="text-sm">{{ 'general.protocol' | translate }}</span>
								</div>
							</ng-template>
							<p-table [value]="bookProtocol" [scrollable]="true" scrollHeight="150px">
								<ng-template pTemplate="header">
									<tr>
										<th>{{ 'general.date' | translate }}</th>
										<th>{{ 'general.type' | translate }}</th>
										<th>{{ 'general.info' | translate }}</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-bookProtocol>
									<tr>
										<td>
											{{ bookProtocol.BookDate }} {{ 'general.at' | translate }} {{ bookProtocol.BookTime }}
											{{ 'general.clock' | translate }}
										</td>
										<td>{{ 'general.' + bookProtocol.BookType | translate }}</td>
										<td>{{ bookProtocol.BookError }}</td>
									</tr>
								</ng-template>
							</p-table>
						</p-accordionTab>
					</p-accordion>
				</div>
			</div>
		</div>

		<div>
			<div class="grid">
				<div class="col-6">
					<button
						(click)="editEventDialog = false"
						class="p-button-danger"
						icon="fa fa-close"
						[label]="'general.abort' | translate"
						pButton
						type="button"></button>
				</div>
				<div class="col-6">
					<ng-container *ngIf="editEventType === 'PZE' || editEventType === 'FZ'">
						<ng-container *ngIf="!!teamLeaderFor || session.calendar_pze === 1">
							<button
								(click)="updateEvent(editEventType)"
								[disabled]="myEditForm.disabled || myEditForm.invalid"
								class="p-button-success alignright"
								icon="fa fa-pencil"
								[label]="'general.change' | translate"
								pButton
								type="button"></button>
							<button
								(click)="deleteEvent()"
								[disabled]="myEditForm.disabled || myEditForm.invalid"
								class="p-button-danger alignright b-m-r--10"
								icon="fa fa-trash"
								pButton
								type="button"></button>
						</ng-container>
						<ng-container *ngIf="!teamLeaderFor && session.calendar_pze === 2">
							<button
								(click)="updateEvent(editEventType)"
								[disabled]="myEditForm.disabled || myEditForm.invalid"
								class="p-button-success alignright"
								icon="fa fa-pencil"
								[label]="'general.requestchange' | translate"
								pButton
								type="button"></button>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="editEventType === 'BDE'">
						<button
							(click)="updateEvent(editEventType)"
							[disabled]="myEditForm.disabled || myEditForm.invalid"
							class="p-button-success alignright"
							icon="fa fa-pencil"
							[label]="'general.change' | translate"
							pButton
							type="button"></button>
						<button
							(click)="deleteEvent()"
							[disabled]="myEditForm.disabled || myEditForm.invalid"
							class="p-button-danger alignright b-m-r--10"
							icon="fa fa-trash"
							pButton
							type="button"></button>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</p-dialog>
<p-dialog
	[header]="'txScheduler.warnings.no-multiple-fzs-title' | translate"
	[(visible)]="warningDialog"
	[baseZIndex]="10000"
	[breakpoint]="640"
	[closable]="false"
	[closeOnEscape]="false"
	[contentStyle]="{ 'overflow-y': 'auto' }"
	[contentStyle]="{ 'min-height': '200px' }"
	[draggable]="false"
	[modal]="true"
	[resizable]="false"
	[responsive]="true"
	[style]="{ overflow: 'visible', width: '640px', 'min-width': '320px' }"
	appendTo="body">
	<div class="p-fluid">
		<div class="grid">
			<div class="col-12">
				{{ 'txScheduler.warnings.no-multiple-fzs-text' | translate }}
			</div>
		</div>
	</div>
	<div class="grid grid-responsive">
		<div class="p-g">
			<div class="p-sm-6 p-md-6 p-lg-6 p-g-6">
				<button
					(click)="warningDialog = false"
					class="p-button-danger"
					icon="fa fa-close"
					[label]="'general.abort' | translate"
					pButton
					type="button"></button>
			</div>
		</div>
	</div>
</p-dialog>
