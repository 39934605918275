<!--<div class="row">
    <div class="col-xs-12">
        <section class="widget">
            <h3>{{ 'sidebar.personalFile' | translate }}</h3>
        </section>
    </div>
</div>-->
<!--<tx-list-content
        [items$]="Employees$"
        [getLabel]="getLabel"
        listHeader="{{ 'sidebar.personalFile' | translate }}"
>
</tx-list-content>-->
<div class="grid" [class.disabled-overlay]="isDisabled" *ngIf="Teamleader$ | async as teamleader">
	<div class="col-12 xl:col-3">
		<mat-card class="mat-elevation-z0">
			<mat-card-title>
                <div class="display-flex flex-justify-content-space-between">
                    <span>{{ 'sidebar.personalFile' | translate }}</span>
                        <div>
                            <button mat-mini-fab class="mr-2" (click)="onNewClick($event)">
                                <fa-icon icon="plus"></fa-icon>
                            </button>
                        <button mat-mini-fab color="primary" [matMenuTriggerFor]="settings">
                            <fa-icon icon="cog"></fa-icon>
                        </button>
                        <mat-menu #settings="matMenu">
                            <div mat-menu-item (click)="openDialog('departments')">
                                {{ 'infocenter.departments' | translate }}
                            </div>
                            <div mat-menu-item (click)="openDialog('groups')">
                                {{ 'infocenter.groups' | translate }}
                            </div>
                            <div mat-menu-item (click)="openDialog('teams')">
                                Teams
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </mat-card-title>
			<tx-list
				*ngIf="Employees$ | async as Employees"
				maxHeight="65vh"
				[items]="Employees"
				[labelField]="getLabel"
				[sortField]="'employee_id'"
				[idField]="'employee_id'"
                [searchField]="'firstname,lastname'"
                [showNew]="false"
				routerLinkBase="personaloffice/personalakten"
				(selectedItem)="onItemSelected($event)"
            ></tx-list>
		</mat-card>
	</div>
	<div class="col-12 xl:col-9">
		<section class="widget">
			<div *ngIf="SelectedEmployee$ | async as Employee; else noSelection" class="flex-content">
				<!--<pre>{{ Employee | json }}</pre>-->
				<form [formGroup]="PersonalForm" #personalForm="ngForm" (ngSubmit)="save(Employee)">
					<mat-tab-group [(selectedIndex)]="SelectedTab" [dynamicHeight]="true">
						<mat-tab label="{{ 'txPersonalFiles.mastersheet' | translate }}">
							<ng-template matTabContent>
								<div class="padding-all-1">
									<div class="display-flex flex-wrap form-wrapper-70-30">
										<div class="flex-grow-1" style="width: 520px">
											<div class="flex-ml-1">
												<mat-form-field appearance="outline">
													<mat-label>{{ 'txPersonalFiles.personal_no' | translate }}</mat-label>
													<input matInput formControlName="persnr" required />
												</mat-form-field>
                                                <mat-form-field appearance="outline">
													<mat-label>{{ 'txPersonalFiles.salary_personal_no' | translate }}</mat-label>
													<input matInput formControlName="lohn_persnr" />
												</mat-form-field>
												<!--<mat-form-field appearance="outline" *ngIf="EmployeeTypes$ | async as types">
													<mat-label>Typ</mat-label>
													<mat-select formControlName="employee_type">
														<mat-option *ngFor="let type of types" [value]="type.value">{{
															type.description
														}}</mat-option>
													</mat-select>
												</mat-form-field>-->
											</div>
											<div>
												<mat-form-field appearance="outline" class="full-width">
													<mat-label>{{ 'txPersonalFiles.firstname' | translate }}</mat-label>
													<input matInput formControlName="firstname" />
												</mat-form-field>
											</div>
											<div>
												<mat-form-field appearance="outline" class="full-width">
													<mat-label>{{ 'txPersonalFiles.lastname' | translate }}</mat-label>
													<input matInput formControlName="lastname" />
												</mat-form-field>
											</div>
											<div *ngIf="Timemodels$ | async as Timemodels">
												<mat-form-field appearance="outline" class="full-width">
													<mat-label>{{ 'txPersonalFiles.timemodel_assignment' | translate }}</mat-label>
													<mat-chip-list #chipList>
														<mat-chip
															*ngFor="let timeModel of SelectedTimeModels"
															[removable]="true"
															(removed)="remove(timeModel)">
															{{ timeModel.label }}
															<fa-icon icon="trash" matChipRemove></fa-icon>
														</mat-chip>
														<input
															placeholder="{{ 'txPersonalFiles.timemodel_assignment' | translate }}"
															#timeModelInput
															[readonly]="SelectedTimeModels.length > 4"
															[formControl]="timeModelCtrl"
															[matAutocomplete]="auto"
															[matChipInputFor]="chipList"
															[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
															(matChipInputTokenEnd)="add($event)" />
                                                        <div matSuffix>
                                                            <button
                                                                class="mat-elevation-z0"
                                                                mat-mini-fab
                                                                type="button"
                                                                (mouseenter)="onTimeModelsInfoMouseenter($event, timeModelsPopover)"
                                                                (mouseleave)="onTimeModelsInfoMouseleave($event, timeModelsPopover)"
                                                            >
                                                                <fa-icon icon="info"></fa-icon>
                                                            </button>
                                                        </div>
													</mat-chip-list>
                                                    <div #timeModelsPopover class="top-[60px] z-50 hidden absolute bg-gray-50 border rounded p-4 mat-elevation-z4">
                                                        <div
                                                            class="absolute top-2 right-2 mat-elevation-z0 text-black/50 cursor-pointer"
                                                            (click)="timeModelsPopover.classList.add('hidden')"
                                                        >
                                                            <fa-icon icon="times" size="2x"></fa-icon>
                                                        </div>
                                                        <p>Bitte beachten Sie bei Mitarbeitern mit Schichtzuordnung:</p>
                                                        <p>Die hier als erste eingetragene Schicht wird dem Mitarbeiter als Basis-Zeitmodell zugeordnet.</p>
                                                        <p>Diese Information ist wichtig für die korrekte Ausweisung, zB von Krank- und Urlaubsbuchungen!</p>
                                                    </div>
													<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
														<mat-option *ngFor="let model of filterTimemodels(Timemodels)" [value]="model.model_id">{{
															model.model_name
														}}</mat-option>
													</mat-autocomplete>
												</mat-form-field>
											</div>
                                            <div *ngIf="Teamleader$ | async as Teamleader">
                                                <mat-form-field appearance="outline" class="full-width">
                                                    <mat-label>{{ 'txPersonalFiles.teamleader_assignment' | translate }}</mat-label>
                                                    <mat-chip-list #tlChipList>
                                                        <mat-chip
                                                                *ngFor="let teamleader of SelectedTeamLeader"
                                                                [removable]="true"
                                                                (removed)="removeTeamleader(teamleader)">
                                                            {{ teamleader.label }}
                                                            <div class="px-2 flex flex-align-items-center">
                                                                <mat-checkbox class="mr-2" (change)="changePermission('absent', teamleader, Employee)" [checked]="getPermission(Employee, teamleader).absent">
                                                                    {{ 'txPersonalFiles.missing_time' | translate }}
                                                                </mat-checkbox>
                                                                <mat-checkbox (change)="changePermission('booking', teamleader, Employee)" [checked]="getPermission(Employee, teamleader).booking">
                                                                    {{ 'txPersonalFiles.booking' | translate }}
                                                                </mat-checkbox>
                                                            </div>
                                                            <fa-icon icon="trash" matChipRemove></fa-icon>
                                                        </mat-chip>
                                                        <input
                                                                placeholder="{{ 'txPersonalFiles.teamleader_assignment' | translate }}"
                                                                #teamleaderInput
                                                                [formControl]="teamleaderModelCtrl"
                                                                [matAutocomplete]="tlAuto"
                                                                [matChipInputFor]="tlChipList"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                (matChipInputTokenEnd)="addTeamleader($event)" />
                                                    </mat-chip-list>
                                                    <mat-autocomplete #tlAuto="matAutocomplete" (optionSelected)="selectedTeamleader($event)">
                                                        <mat-option *ngFor="let teamleader of filterTeamleaderList(Teamleader)" [value]="teamleader.id">
                                                            {{ teamleader.name }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>

                                                <!--<div class="grid" *ngIf="getDeputyState(Employee, teamleader)">
                                                    <div class="col-12 md:col-8">
                                                        <p *ngIf="getDeputyState(Employee, teamleader)?.deputy_active!=1">{{ 'txPersonalFiles.deputyOn' | translate }}</p>
                                                        <p *ngIf="getDeputyState(Employee, teamleader)?.deputy_active==1">
                                                            {{ 'txPersonalFiles.deputyOff' | translate }}
                                                            <span *ngIf=" getDeputyState(Employee, teamleader)?.deputy_name != ''">
                                                                Derzeit ist {{ getDeputyState(Employee, teamleader).deputy_name }} als Stellvertreter eingetragen.
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 md:col-4">
                                                        <button *ngIf="getDeputyState(Employee, teamleader)?.deputy_active!=1" (click)="setDeputyState(getDeputyState(Employee, teamleader),1)"
                                                                class="btn btn-primary pull-md-right">
                                                            <i class="fa fa-toggle-off"></i> {{ 'txTeamleiter.substituteOn' | translate }}
                                                        </button>
                                                        <button *ngIf="getDeputyState(Employee, teamleader)?.deputy_active==1" (click)="setDeputyState(getDeputyState(Employee, teamleader),0)"
                                                                class="btn btn-success pull-md-right">
                                                            <i class="fa fa-toggle-on"></i> {{ 'txTeamleiter.substituteOff' | translate }}
                                                        </button>
                                                    </div>
                                                </div>-->
                                            </div>
										</div>
										<mat-card class="no-border mat-elevation-z0 flex-grow-0">
											<mat-card-header>
												<mat-card-subtitle>{{ 'txPersonalFiles.holiday_account' | translate }}</mat-card-subtitle>
											</mat-card-header>
											<mat-card-content>
												<div>
													<mat-form-field>
														<mat-label>{{ 'txPersonalFiles.holiday_year' | translate }}</mat-label>
														<input disabled matInput [value]="getEmployeeHolidayAccountData(Employee, 'annual').account_value" />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field>
														<mat-label>{{ 'txPersonalFiles.holiday_takeover_preyear' | translate }}</mat-label>
														<input disabled matInput [value]="getEmployeeHolidayAccountData(Employee, 'prev_year').account_value" />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field>
														<mat-label>{{ 'txPersonalFiles.holiday_rest_preyear' | translate }}</mat-label>
														<input disabled matInput [value]="getEmployeeHolidayAccountData(Employee, 'prev_year_remaining').account_value" />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field>
														<mat-label>{{ 'txPersonalFiles.holiday_rest_year' | translate }}</mat-label>
														<input disabled matInput [value]="getEmployeeHolidayAccountData(Employee, 'remaining').account_value" />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field>
														<mat-label>{{ 'txPersonalFiles.holiday_rest' | translate }}</mat-label>
														<input disabled matInput [value]="getEmployeeHolidayAccountData(Employee, 'predictable').account_value" />
													</mat-form-field>
												</div>
											</mat-card-content>
										</mat-card>
										<mat-card class="mat-elevation-z3 margin-all-1 locked-card flex-grow-0" [class.is-locked]="HolidayLocked">
											<button
												txConfirmClick
												confirmText="{{ 'txPersonalFiles.confirm_edit_holiday' | translate }}"
												title="Basisdaten editieren?"
												[buttonTexts]="{ cancel: translate.instant('general.no') + '', approve: translate.instant('general.yes') + '' }"
												[bypass]="!HolidayLocked"
												(confirmClick)="HolidayLocked = !HolidayLocked"
												mat-icon-button
												class="lock-btn"
												type="button">
												<fa-icon [icon]="HolidayLocked ? 'lock' : 'lock-open'"></fa-icon>
											</button>
											<mat-card class="no-border mat-elevation-z0">
												<mat-card-header>
													<mat-card-subtitle>{{ 'txPersonalFiles.base_values' | translate }}</mat-card-subtitle>
												</mat-card-header>
												<mat-card-content>
													<div>
														<mat-form-field>
															<mat-label>{{ 'txPersonalFiles.holiday_due_annual' | translate }}</mat-label>
															<input type="number" step="0.5" matInput formControlName="annual_holiday" />
														</mat-form-field>
													</div>
													<div>
														<mat-form-field>
															<mat-label>{{ 'txPersonalFiles.holiday_annual_special' | translate }}</mat-label>
															<input type="number" step="0.5" matInput formControlName="special_holiday" />
														</mat-form-field>
													</div>
												</mat-card-content>
											</mat-card>
											<mat-card class="no-border mat-elevation-z0">
												<mat-card-header>
													<mat-card-subtitle>{{ 'txPersonalFiles.start_values' | translate }}</mat-card-subtitle>
												</mat-card-header>
												<mat-card-content>
													<div>
														<mat-form-field>
															<mat-label>{{ 'txPersonalFiles.start_date' | translate }}</mat-label>
															<input
																formControlName="start_date"
																matInput
                                                                [disabled]="true"
																[matDatepicker]="dp_start_date" />
															<mat-datepicker-toggle matSuffix [for]="dp_start_date"></mat-datepicker-toggle>
															<mat-datepicker
																#dp_start_date
																startView="year"
                                                                disabled="false"
                                                            ></mat-datepicker>
														</mat-form-field>
													</div>
													<div>
														<mat-form-field>
															<mat-label>{{ 'txPersonalFiles.holiday' | translate }}</mat-label>
															<input type="number" step="0.5" matInput formControlName="start_holiday" />
														</mat-form-field>
													</div>
													<div>
														<mat-form-field>
															<mat-label>{{ 'txPersonalFiles.hourly_account' | translate }}</mat-label>
															<input
																mask="000:00"
																[showMaskTyped]="true"
																[dropSpecialCharacters]="false"
																matInput
																formControlName="start_value" />
														</mat-form-field>
													</div>
												</mat-card-content>
											</mat-card>
                                            <mat-card class="no-border mat-elevation-z0">
                                                <mat-card-header>
                                                    <mat-card-subtitle>{{ 'txPersonalFiles.terminal_login' | translate }}</mat-card-subtitle>
                                                </mat-card-header>
                                                <mat-card-content>
                                                    <div>
                                                        <div>
                                                            <mat-form-field>
                                                                <mat-label>{{ 'txPersonalFiles.transponder_no' | translate }}</mat-label>
                                                                <input matInput formControlName="card_number" />
                                                            </mat-form-field>
                                                        </div>
                                                        <div>
                                                            <mat-form-field>
                                                                <mat-label>{{ 'txPersonalFiles.biometric_recognition' | translate }}</mat-label>
                                                                <input matInput formControlName="fp_number" />
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="flex justify-center items-baseline">
                                                            <div *ngIf="Employee.qr_code" class="flex flex-column">
                                                                <label class="text-light">{{ 'txPersonalFiles.qr_code' | translate }}</label>
                                                                <canvas
                                                                        class="mb-2 w-[100px] h-[100px]"
                                                                        #canvas
                                                                        [qrCode]="Employee.qr_code"
                                                                        [height]="100"
                                                                        [width]="100"
                                                                        [qrCodeMargin]="0"
                                                                        [darkColor]="'#000000FF'"
                                                                        [lightColor]="'#FFFFFFFF'"
                                                                        qrCodeErrorCorrectionLevel="M"></canvas>
                                                                <span class="text-center">
															<a
                                                                    [href]="getQRCodeHref(canvas)"
                                                                    [download]="
																	Employee.lastname.toLowerCase() +
																	'_' +
																	Employee.firstname.toLowerCase() +
																	'_qr-code.png'
																"
                                                                    target="_blank"
                                                            >Download</a
                                                            >
														</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </mat-card-content>
                                            </mat-card>
										</mat-card>
									</div>
									<div class="display-flex flex-even-width">
										<mat-card class="mat-elevation-z0">
											<mat-card-content>
												<div class="flex-ml-1">
													<mat-form-field *ngIf="EmployeeRelations$ | async as types">
														<mat-label>{{ 'txPersonalFiles.employee_relationship' | translate }}</mat-label>
														<mat-select formControlName="employment_type">
															<mat-option *ngFor="let type of types" [value]="type.id">{{
																type.name
															}}</mat-option>
														</mat-select>
														<!--<input matInput />-->
													</mat-form-field>
                                                    <!--not used for now-->
													<!--<mat-form-field *ngIf="SalaryTypes$ | async as types">
														<mat-label>{{ 'txPersonalFiles.pay_method' | translate }}</mat-label>
														<mat-select formControlName="salary_type">
															<mat-option *ngFor="let type of types" [value]="type.value">{{
																type.description
															}}</mat-option>
														</mat-select>
													</mat-form-field>-->
													<mat-form-field *ngIf="KST$ | async as KST">
														<mat-label>{{ 'txPersonalFiles.base_kst' | translate }}</mat-label>
                                                        <mat-select formControlName="costcenter_id">
                                                            <mat-option *ngFor="let k of KST" [value]="k.id">
                                                                {{ k.bezeichnung }}
                                                            </mat-option>
                                                        </mat-select>
													</mat-form-field>
												</div>
												<div class="flex-ml-1">
													<mat-form-field *ngIf="Departments$ | async as Departments">
														<mat-label>{{ 'infocenter.department' | translate }}</mat-label>
                                                        <mat-select formControlName="department_id">
                                                            <mat-option *ngFor="let d of Departments" [disabled]="d.sync_state === 0" [value]="d.id">
                                                                {{ d.name | translate }}
                                                            </mat-option>
                                                        </mat-select>
													</mat-form-field>
													<mat-form-field *ngIf="EmployeeGroups$ | async as EmployeeGroups">
														<mat-label>{{ 'infocenter.group' | translate }}</mat-label>
                                                        <mat-select formControlName="group_id">
                                                            <mat-option *ngFor="let e of EmployeeGroups" [disabled]="e.sync_state === 0" [value]="e.id">
                                                                {{ e.name | translate }}
                                                            </mat-option>
                                                        </mat-select>
													</mat-form-field>
													<mat-form-field *ngIf="Teamlist$ | async as Teamlist">
														<mat-label>{{ 'infocenter.team' | translate }}</mat-label>
                                                        <mat-select formControlName="team_id">
                                                            <mat-option *ngFor="let team of Teamlist" [value]="team.id">
                                                                {{ team.name | translate }}
                                                            </mat-option>
                                                        </mat-select>
													</mat-form-field>
												</div>
											</mat-card-content>
										</mat-card>
									</div>
								</div>
							</ng-template>
						</mat-tab>
						<mat-tab label="Akte">
							<ng-template matTabContent>
								<div class="padding-all-1">
									<div class="form-wrapper">
										<div class="display-flex form-wrapper-50-50">
											<div class="col">
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.street' | translate }}</mat-label>
														<input formControlName="street" matInput />
													</mat-form-field>
												</div>
												<div class="multi-field-wrapper">
													<mat-form-field class="fs-1 width-15">
														<mat-label>{{ 'txPersonalFiles.postalcode' | translate }}</mat-label>
														<input formControlName="zipcode" matInput />
													</mat-form-field>
													<span class="divider"></span>
													<mat-form-field class="fg-2 fs-1">
														<mat-label>{{ 'txPersonalFiles.city' | translate }}</mat-label>
														<input formControlName="city" matInput />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.phone1' | translate }}</mat-label>
														<input formControlName="phone1" matInput />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.phone2' | translate }}</mat-label>
														<input formControlName="phone2" matInput />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.email1' | translate }}</mat-label>
														<input formControlName="email1" matInput />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.email2' | translate }}</mat-label>
														<input formControlName="email2" matInput />
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.birthday' | translate }}</mat-label>
														<input formControlName="birthday" matInput [matDatepicker]="dp_birthday" />
														<mat-datepicker-toggle matSuffix [for]="dp_birthday"></mat-datepicker-toggle>
														<mat-datepicker #dp_birthday [disabled]="false" startView="year"></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="multi-field-wrapper">
													<mat-form-field class="fg-1">
														<mat-label>{{ 'txPersonalFiles.first_entry' | translate }}</mat-label>
														<input formControlName="entry_date1" matInput [matDatepicker]="dp_entry_date1" />
														<mat-datepicker-toggle matSuffix [for]="dp_entry_date1"></mat-datepicker-toggle>
														<mat-datepicker #dp_entry_date1 [disabled]="false"></mat-datepicker>
													</mat-form-field>
													<!--<span class="divider"></span>-->
													<mat-form-field class="fg-1">
														<mat-label>{{ 'txPersonalFiles.second_entry' | translate }}</mat-label>
														<input formControlName="entry_date2" matInput [matDatepicker]="dp_entry_date2" />
														<mat-datepicker-toggle matSuffix [for]="dp_entry_date2"></mat-datepicker-toggle>
														<mat-datepicker #dp_entry_date2 [disabled]="false"></mat-datepicker>
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.leave' | translate }}</mat-label>
														<input formControlName="exit_date" matInput [matDatepicker]="dp_exit_date" />
														<mat-datepicker-toggle matSuffix [for]="dp_exit_date"></mat-datepicker-toggle>
														<mat-datepicker #dp_exit_date [disabled]="false"></mat-datepicker>
													</mat-form-field>
												</div>
											</div>
											<div class="col">
												<div class="multi-field-wrapper">
													<mat-form-field class="fg-2 multi-fielda">
														<mat-label>{{ 'txPersonalFiles.company_id' | translate }}</mat-label>
														<input formControlName="comp_idcard" matInput />
													</mat-form-field>
													<span class="divider"></span>
													<mat-form-field class="fg-1 fs-1 multi-field flex-basis-10 text-xs-center">
														<mat-label>{{ 'txPersonalFiles.validate_date' | translate }}</mat-label>
														<input formControlName="comp_idcard_date" matInput [matDatepicker]="dp_comp_idcard" />
														<mat-datepicker-toggle matSuffix [for]="dp_comp_idcard"></mat-datepicker-toggle>
														<mat-datepicker #dp_comp_idcard [disabled]="false"></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="multi-field-wrapper">
													<mat-form-field class="fg-2 multi-field">
														<mat-label>{{ 'txPersonalFiles.id' | translate }}</mat-label>
														<input formControlName="idcard" matInput />
													</mat-form-field>
													<span class="divider"></span>
													<mat-form-field class="fg-1 fs-1 multi-field flex-basis-0 text-xs-center">
														<mat-label>{{ 'txPersonalFiles.validate_date' | translate }}</mat-label>
														<input formControlName="idcard_date" matInput [matDatepicker]="dp_personal_ausweis" />
														<mat-datepicker-toggle matSuffix [for]="dp_personal_ausweis"></mat-datepicker-toggle>
														<mat-datepicker #dp_personal_ausweis [disabled]="false"></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="multi-field-wrapper">
													<mat-form-field class="fg-2 multi-field">
														<mat-label>{{ 'txPersonalFiles.travel_id' | translate }}</mat-label>
														<input formControlName="passport" matInput />
													</mat-form-field>
													<span class="divider"></span>
													<mat-form-field class="fg-1 fs-1 multi-field flex-basis-0 text-xs-center">
														<mat-label>{{ 'txPersonalFiles.validate_date' | translate }}</mat-label>
														<input formControlName="passport_date" matInput [matDatepicker]="dp_travel_id_date" />
														<mat-datepicker-toggle matSuffix [for]="dp_travel_id_date"></mat-datepicker-toggle>
														<mat-datepicker #dp_travel_id_date [disabled]="false"></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="multi-field-wrapper">
													<mat-form-field class="fg-2 multi-field">
														<mat-label>{{ 'txPersonalFiles.driver_license' | translate }}</mat-label>
														<input formControlName="driving_lic" matInput />
													</mat-form-field>
													<span class="divider"></span>
													<mat-form-field class="fg-0 fs-2 flex-basis-0 width-10 text-xs-center">
														<input matInput type="number" steps="any" formControlName="driving_lic_iv" />
													</mat-form-field>
													<!--<span class="divider"></span>-->
													<span>
														<fa-icon icon="info-circle" matTooltip="Prüfung in X Tagen"></fa-icon>
													</span>
													<mat-form-field class="fg-1 fs-1 multi-field flex-basis-0 text-xs-center">
														<mat-label>{{ 'txPersonalFiles.validate_date' | translate }}</mat-label>
														<input
															matInput
															formControlName="driving_lic_date"
															[matDatepicker]="dp_drive_license_date" />
														<mat-datepicker-toggle matSuffix [for]="dp_drive_license_date"></mat-datepicker-toggle>
														<mat-datepicker #dp_drive_license_date [disabled]="false"></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="multi-field-wrapper">
													<mat-form-field class="fg-2">
														<mat-label>{{ 'txPersonalFiles.work_contract' | translate }}</mat-label>
														<input formControlName="empl_contract" matInput />
													</mat-form-field>
													<span class="divider"></span>
													<mat-form-field class="fg-1 fs-1 multi-field flex-basis-0 text-xs-center">
														<mat-label>{{ 'txPersonalFiles.validate_date' | translate }}</mat-label>
														<input
															formControlName="empl_contract_date"
															matInput
															[matDatepicker]="dp_work_contract_date" />
														<mat-datepicker-toggle matSuffix [for]="dp_work_contract_date"></mat-datepicker-toggle>
														<mat-datepicker #dp_work_contract_date [disabled]="false"></mat-datepicker>
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.payrate1' | translate }}</mat-label>
														<input
															(change)="numberInputToFixed($event)"
															formControlName="payrate1"
															type="number"
															step="any"
															matInput />
														<span matSuffix>€</span>
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.payrate2' | translate }}</mat-label>
														<input
															(change)="numberInputToFixed($event)"
															formControlName="payrate2"
															type="number"
															step="any"
															matInput />
														<span matSuffix>€</span>
													</mat-form-field>
												</div>
												<div>
													<mat-form-field class="full-width">
														<mat-label>{{ 'txPersonalFiles.payrate3' | translate }}</mat-label>
														<input
															(change)="numberInputToFixed($event)"
															formControlName="payrate3"
															type="number"
															step="any"
															matInput />
														<span matSuffix>€</span>
													</mat-form-field>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="padding-all-1">
									<div class="form-wrapper">
										<div>
											<mat-form-field appearance="outline" class="full-width">
												<mat-label>{{ 'txPersonalFiles.documentation' | translate }}</mat-label>
												<textarea
													formControlName="documentation"
													matInput
													cdkTextareaAutosize
													cdkAutosizeMinRows="5"
													cdkAutosizeMaxRows="20"></textarea>
											</mat-form-field>
										</div>
									</div>
								</div>
							</ng-template>
						</mat-tab>
                        <mat-tab label="Berechtigungen" *ngIf="authService.can('staff_office_file_permissions')">
                            <ng-template matTabContent>
                                <div>
                                    <mat-card class="no-border mat-elevation-z0">
                                        <mat-card-content class="display-flex flex-wrap flex-direction-column flex-grow-1">
                                            <h5>{{ 'txPersonalFiles.portal_login' | translate }}</h5>
                                            <div class="display-flex flex-wrap">
                                                <mat-form-field class="flex-grow-1 mr-4">
                                                    <mat-label>{{ 'txPersonalFiles.user' | translate }}</mat-label>
                                                    <input matInput [(ngModel)]="FormattedLoginName" [ngModelOptions]="{standalone: true}" (ngModelChange)="LoginPermissionChange$.next({ employee: Employee, perm: 'Loginname', value: $event })" />
                                                    <div matSuffix class="select-none text-gray-400">
                                                        @{{ getSessionDomain() }}
                                                    </div>
                                                </mat-form-field>
                                                <mat-form-field class="flex-grow-1 mr-4">
                                                    <mat-label>{{ 'txPersonalFiles.pin' | translate }}</mat-label>
                                                    <input matInput [(ngModel)]="CloudPin" [ngModelOptions]="{standalone: true}" (ngModelChange)="LoginPermissionChange$.next({ employee: Employee, perm: 'Cloud PIN', value: $event })"/>
                                                </mat-form-field>
                                                <mat-form-field class="flex-grow-1">
                                                    <mat-label>{{ 'txPersonalFiles.qr_code' | translate }}</mat-label>
                                                    <input matInput [(ngModel)]="QrCode" [ngModelOptions]="{standalone: true}" (ngModelChange)="LoginPermissionChange$.next({ employee: Employee, perm: 'QR Code', value: $event })"/>
                                                </mat-form-field>
                                            </div>
                                            <button type="button" color="accent" mat-raised-button txConfirmClick [confirmText]="'Wollen Sie das Passwort wirklich zurücksetzten?'" (confirmClick)="resetPassword(Employee)">{{ 'txPersonalFiles.reset_password' | translate }}</button>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div class="p-4 flex flex-wrap gap-2">
                                    <div *ngFor="let perm of Employee.getStaffPermissions()" class="flex flex-column " [ngClass]="{'flex-basis-50': perm.columns==1,'flex-basis-100': perm.columns!=1}">
                                        <ng-container *ngIf="perm.value_type === 'label' || (perm.children && perm.children.length>0)">
                                            <div class="font-weight-bold" style="border-bottom: 1px solid black; margin-bottom: 1rem">{{ perm.name | translate }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="perm.value_type !== 'label'">
                                            <mat-form-field *ngIf="perm.value_type === 'string'" appearance="outline" color="primary" class="w-100 access-form-field">
                                                <mat-label>{{ perm.name | translate }}</mat-label>
                                                <input matInput [value]="perm.value_text" (change)="updateUserPermission(perm, { value: 0, value_text: $event.currentTarget }, Employee.employee_id)">
                                            </mat-form-field>
                                            <mat-checkbox *ngIf="perm.value_type === 'value'" color="primary" [checked]="perm.value === 1" (change)="updateUserPermission(perm, { value: $event.checked ? 1 : 0, value_text: null }, Employee.employee_id)">{{ perm.name | translate }}</mat-checkbox>
                                            <!-- select field -->
                                            <mat-form-field *ngIf="perm.value_type === 'selection'" appearance="outline" color="primary" class="w-100 access-form-field">
                                                <mat-label>{{ perm.name | translate }}</mat-label>
                                                <mat-select (selectionChange)="updateUserSelection(perm, { value: $event.value }, Employee.employee_id)">
                                                    <mat-option *ngFor="let option of selectionSources[perm.selection_source]" [value]="option.value">{{ option.description | translate }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                        <div *ngIf="perm.children && perm.children.length > 0" class="px-2 flex flex-wrap gap-4">
                                            <div *ngFor="let child of perm.children" [ngClass]="{'flex-basis-50': perm.columns==2,'flex-basis-100': perm.columns==1, 'flex-basis-25': perm.columns==4}">
                                                <ng-container *ngIf="child.value_type === 'label' || (child.children && child.children.length>0)">
                                                    <div class="font-weight-bold" style="margin-bottom: 0.5rem">{{ child.name | translate }}</div>
                                                </ng-container>
                                                <ng-container *ngIf="child.value_type !== 'label'">
                                                    <mat-form-field *ngIf="child.value_type === 'string'" appearance="outline" color="primary" class="w-100 access-form-field">
                                                        <mat-label>{{ child.name | translate }}</mat-label>
                                                        <input matInput [value]="child.value_text" (change)="updateUserPermission(child, { value: 0, value_text: $event.currentTarget }, Employee.employee_id)">
                                                    </mat-form-field>
                                                    <mat-checkbox *ngIf="child.value_type === 'value'" color="primary" [checked]="child.value === 1" (change)="updateUserPermission(child, { value: $event.checked ? 1 : 0, value_text: null }, Employee.employee_id)">{{ child.name | translate }}</mat-checkbox>
                                                    <!-- select field -->
                                                    <mat-form-field *ngIf="child.value_type === 'selection'" appearance="outline" color="primary" class="w-100 access-form-field">
                                                        <mat-label>{{ child.name | translate }}</mat-label>
                                                        <mat-select [value]="child.selection_value" (selectionChange)="updateUserSelection(child, { value: $event.value }, Employee.employee_id)">
                                                            <mat-option *ngFor="let option of selectionSources[child.selection_source]" [value]="option.value">{{ option.description | translate }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </ng-container>
                                                <div *ngIf="child.children && child.children.length > 0" class="px-2 flex flex-wrap">
                                                    <div *ngFor="let subchild of child.children" [ngClass]="{'flex-basis-50': child.columns==2,'flex-basis-100': child.columns==1, 'flex-basis-25': child.columns==4}">
                                                        <ng-container *ngIf="subchild.value_type === 'label' || (subchild.children && subchild.children.length>0)">
                                                            <div class="font-weight-bold">{{ subchild.name | translate }}</div>
                                                        </ng-container>
                                                        <ng-container *ngIf="subchild.value_type !== 'label'">
                                                            <mat-form-field *ngIf="subchild.value_type === 'string'" appearance="outline" color="primary" class="w-100 access-form-field">
                                                                <mat-label>{{ subchild.name | translate }}</mat-label>
                                                                <input matInput [value]="subchild.value_text" (change)="updateUserPermission(subchild, { value: 0, value_text: $event.currentTarget }, Employee.employee_id)">
                                                            </mat-form-field>
                                                            <mat-checkbox *ngIf="subchild.value_type === 'value'" color="primary" [checked]="subchild.value === 1" (change)="updateUserPermission(subchild, { value: $event.checked ? 1 : 0, value_text: null }, Employee.employee_id)">{{ subchild.name | translate }}</mat-checkbox>
                                                            <!-- select field -->
                                                            <mat-form-field *ngIf="subchild.value_type === 'selection'" appearance="outline" color="primary" class="w-100 access-form-field">
                                                                <mat-label>{{ subchild.name | translate }}</mat-label>
                                                                <mat-select [value]="subchild.selection_value" (selectionChange)="updateUserSelection(subchild, { value: $event.value }, Employee.employee_id)">
                                                                    <mat-option *ngFor="let option of selectionSources[subchild.selection_source]" [value]="option.value">{{ option.description | translate }}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
						<!--<mat-tab label="Konto">
                            <ng-template matTabContent>

                            </ng-template>
                        </mat-tab>-->
					</mat-tab-group>
					<div class="tab-buttons">
						<button mat-raised-button *ngIf="!Employee.employee_id" type="button" (click)="unselectEmployee()">
                            {{ 'general.abort' | translate }}
						</button>
						<button mat-raised-button *ngIf="Employee.employee_id" [disabled]="!PersonalForm.dirty" type="button" (click)="patchForm(Employee)">
                            {{ 'general.abort' | translate }}
						</button>
                        <button mat-raised-button color="warn" [disabled]="!PersonalForm.dirty" type="submit">
                            {{ 'general.save' | translate }}
                        </button>
					</div>
					<div class="user-calender" *ngIf="Employee.employee_id > 0">
						<a [routerLink]="['/kalender', { id: Employee.persnr, date: null }]">
                            {{ 'txPersonalFiles.employee_calendar' | translate }}
                        </a>
                        <div>
                            <button
                                mat-mini-fab
                                type="button"
                                [matMenuTriggerFor]="moreSettings"
                            >
                                <fa-icon icon="ellipsis-v"></fa-icon>
                            </button>

                            <mat-menu #moreSettings="matMenu">
                                <div
                                    txConfirmClick
                                    title="Archivieren"
                                    [confirmText]="'Wirklich \'' + Employee.firstname + ' ' + Employee.lastname + '\' archivieren?'"
                                    [buttonTexts]="{ cancel: translate.instant('general.no') + '', approve: translate.instant('general.yes') + '' }"
                                    mat-menu-item
                                    (confirmClick)="archiveEmployee(Employee)"
                                >
                                    {{ 'general.archive' | translate }}
                                </div>
                            </mat-menu>
                        </div>
                    </div>
				</form>
			</div>
			<ng-template #noSelection>
				<div class="no-selection">
                    {{ 'txPersonalFiles.select_person' | translate }}
                </div>
			</ng-template>
		</section>
	</div>
</div>
