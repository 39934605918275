import { Injectable } from '@angular/core';
import { HeartbeatService } from './heartbeat.service';
import { get, set, Store } from 'idb-keyval';
import { TxApiService } from './txapi.service';
import { ConnectionStateService } from './connection-state.service';
import CalendarPzeService from './calendar-pze.service';

@Injectable({
	providedIn: 'root',
})
export class StalledRequestsService {
	db: Store;
	locked = false;

	constructor(
		private heartbeatService: HeartbeatService,
		private api: TxApiService,
		private state: ConnectionStateService,
		private events: CalendarPzeService
	) {
		this.db = new Store('offline', 'requests');

        heartbeatService.addToHeartbeat(
            'StalledRequestsService',
            () => {
			if (!this.locked && this.state.isOnlineSubject.getValue()) {
				this.locked = true;
				this.processStalled(this.db, api, events).then(() => (this.locked = false));
			}
		}, 10);
	}

	async processStalled(db: Store, api: TxApiService, events: CalendarPzeService) {
		let requests: any[] = await get('stalled', db);
		if (requests) {
			const done = [];
			for (let i = 0; i < requests.length; ++i) {
				if (!requests[i].url) {
					done.push(i);
					continue;
				}
				const res = await api
					.callAPI(requests[i].url.substr(requests[i].url.lastIndexOf('/') + 1), requests[i].body)
					.toPromise();
				if (res?.type !== 'offline') {
					//requests.splice(i, 1);
					done.push(i);
				}
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
			await set(
				'stalled',
				requests.filter((_, i) => !done.includes(i)),
				db
			);
			const [onChange] = events.onChangedDayCalendarPZE();
			onChange.subscribe();
		}
		return true;
	}
}
