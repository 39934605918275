import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { TxApiService } from '../../shared/services/txapi.service';

import { AuthService } from '../../shared/services/auth.service';
import { InfocenterService } from '../../shared/services/infocenter.service';
import { FormatDate } from '../../shared/services/formatdate.service';
import { TabStates, TabStateService } from '../../shared/services/tabstate.service';
import { GeoDataService } from '../../shared/services/geodata.service';
import { UserInfoService } from '../../shared/services/user-info.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { ListOfUserInfo } from '../../shared/interfaces/user-info';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BookingService } from '../../shared/services/booking.service';
import { MessengerService } from '../../shared/services/messenger.service';
import { TerminalService } from '../../shared/services/terminal.service';
import { Event, ListOfCalendarPZE } from '../../shared/interfaces/scheduler';
import { filter, map } from 'rxjs/operators';
import CalendarPzeService from '../../shared/services/calendar-pze.service';
import { UserStatusService } from '../../shared/services/user-status.service';
import { StateEntity } from '../../store/entities/StateEntity';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { getStates } from '../../store/selectors/user-state.selectors';
import { GetStates, SetUserState } from '../../store/actions/user-state.actions';

declare var jQuery: any;

@Component({
	selector: 'tx-buchungen',
	templateUrl: './txbuchungen.component.html',
	providers: [TabStateService],
})
export class TxBuchungenComponent implements OnInit, OnDestroy {
	tabState: TabStates;
	kostenstellen = [];
	mycurrentKSTID: string = null;
	geoDataEnabled: boolean;
	geoDataSubscription: Subscription;
	blockImageUploadUi = false;
	userInfoSubscription: Subscription;
	listOfUserInfo: ListOfUserInfo;
	networkIsOnline: boolean;
	isMobile: boolean;
	isTablet: boolean;
	pinLoggedIn = false;
	goodConnection = true;
	message;

	UserStates$: Observable<{ value: string; label: string }[]>;
	SelectedState: string;

	QueuedBookings: Event[] = [];

	_currentDayEvents$: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>(null);
	currentDayEvents$: Observable<Event[]> = this._currentDayEvents$.asObservable();

	everySecond$: Observable<number> = interval(1000);

	_calendarPzeChange$: Observable<Event[]>;
	_calendarPzeCancel: () => void | null = null;

	private _queueSize$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
	queueSize$ = this._queueSize$.asObservable();

	private subs: Subscription[] = [];

	constructor(
		private myTxApiservice: TxApiService,
		public myAuthService: AuthService,
		private myInfocenterService: InfocenterService,
		private formatDate: FormatDate,
		private myTabStateService: TabStateService,
		private myGeoDataService: GeoDataService,
		private myUserInfoService: UserInfoService,
		private myConnectionStateService: ConnectionStateService,
		private myDeviceDetectorService: DeviceDetectorService,
		private myBookingService: BookingService,
		private myMessengerServer: MessengerService,
		private calendarPZE: CalendarPzeService,
		private myTerminalService: TerminalService,
		private store: Store<State>
	) {
		this.UserStates$ = this.store.select(getStates).pipe(
			filter((states) => !!states),
			map((states) => states.map((s) => ({ value: s.id, label: s.name })))
		);
		this.subs.push(
			(this.geoDataSubscription = this.myGeoDataService.isEnabled().subscribe((state) => {
				this.geoDataEnabled = state;
			})),
			(this.userInfoSubscription = this.myUserInfoService.getUserInfo().subscribe((myUserInfo) => {
				if (myUserInfo.statustext === 'OK' && Array.isArray(myUserInfo.ListOfUserInfo)) {
					this.listOfUserInfo = myUserInfo.ListOfUserInfo[0];
					if (!this.SelectedState) {
						this.SelectedState = `${this.listOfUserInfo?.state_id}`;
					}
				}
			})),
			this.myConnectionStateService.onlineState.subscribe((onlineState) => {
				this.networkIsOnline = onlineState;
			}),
			this.myTerminalService.pinLoggedIn.subscribe((data) => (this.pinLoggedIn = data)),
			interval(1000).subscribe((val) => {
				this.myTxApiservice.getCurrentQueueSize().then((data) => {
					const cur = this._queueSize$.getValue();
					if (cur === null) {
						this._queueSize$.next([]);
					} else if (cur?.map((c) => c.body.hash).join() !== data?.map((d) => d.body.hash).join()) {
						this._queueSize$.next(data ?? []);
					}
				});
			})
		);

		this.isMobile = this.myDeviceDetectorService.isMobile() || true;
		this.isTablet = this.myDeviceDetectorService.isTablet();

		this.myConnectionStateService.connectionSpeed.subscribe((data) => (this.goodConnection = data > 500));
	}

	ngOnDestroy(): void {
		if (this._calendarPzeCancel !== null) {
			this._calendarPzeCancel();
		}
	}

	changeUserState(id: string | null) {
		if (id === null) {
			this.store.dispatch(SetUserState(0));
		}
	}

	setUserState() {
		this.store.dispatch(SetUserState(parseInt(this.SelectedState)));
	}

	onTabOpen(name) {
		this.myTabStateService.onTabOpen(name);
	}

	onTabClose(name) {
		this.myTabStateService.onTabClose(name);
	}

	onKSTSelected(aEvent: any): void {
		this.mycurrentKSTID = aEvent;
		this.clickBookingButton('K');
	}

	clickBookingButton(aBuchungsart: string) {
		const currentEvents = this._currentDayEvents$.getValue();
		let event = {
			title: 'PZE',
			className: ['pze-event'],
			queued: true,
		} as Event;

		switch (aBuchungsart) {
			case 'PS':
			case 'DE':
			case 'K':
				event.start = moment().toDate();
				this.QueuedBookings.push(event);
				break;
			case 'PB':
			case 'DB':
			case 'G':
				if (this.QueuedBookings.length > 0) {
					event = this.QueuedBookings[this.QueuedBookings.length - 1];
				} else if (currentEvents?.length > 0) {
					event = currentEvents[currentEvents.length - 1];
				}
				event.queued = true;
				event.end = moment().seconds(0).toDate();
				break;
		}

		this.myBookingService
			.createBooking(aBuchungsart, '', '', null, false, this.mycurrentKSTID)
			.toPromise()
			.then(
				(mySessionData) => {
					if (mySessionData.type !== 'offline') {
						let responseStatus = 'success';
						if (mySessionData.statuscode !== 0) {
							responseStatus = 'error';
						}
						this.message = this.myMessengerServer.message(mySessionData.statustext, responseStatus as MessengerType);
						if (this.pinLoggedIn) {
							setTimeout(() => {
								this.message.cancel();
								debugger;
								this.myTerminalService.pinLoggedInSubject.next(false);
								this.myAuthService.logout();
							}, 1500);
						}
					}
				},
				(error) => {
					console.log('ERROR' + error);
					this.myMessengerServer.message('ERROR ' + error, 'error');
					if (this.pinLoggedIn) {
						setTimeout(() => {
							this.myAuthService.logout();
						}, 1500);
					}
				}
			)
			.finally(() => {
				if (!this.networkIsOnline) {
					let buchungsArt = '';
					switch (aBuchungsart) {
						case 'K':
							buchungsArt = 'Kommen';
							break;
						case 'G':
							buchungsArt = 'Gehen';
							break;
						case 'PB':
							buchungsArt = 'Pausen Beginn';
							break;
						case 'PS':
							buchungsArt = 'Pausen Ende';
							break;
						case 'DB':
							buchungsArt = 'Dienstgang Beginn';
							break;
						case 'DE':
							buchungsArt = 'Dienstgang Ende';
							break;
					}
					const dateTime = moment().format('DD.MM.YYYY HH:mm');
					this.listOfUserInfo.user_info = `letzte Buchung: <b> ${buchungsArt} ${dateTime}</b>`;
				}
				this.myUserInfoService.catchUserInfoFromServer(true);

				const [onChange, cancel] = this.calendarPZE.onChangedDayCalendarPZE();

				this._calendarPzeChange$ = onChange;
				this._calendarPzeCancel = cancel;

				onChange.subscribe((events) => {
					this._currentDayEvents$.next(events);
					this.QueuedBookings = [];
				});

				/*const checkInterval = this.everySecond$
					.pipe(
						switchMap(() => this.processCurrentDayEvents()),
						withLatestFrom(this._currentDayEvents$),
						map(([newEvents, events]) => {
							if (newEvents?.length > 0) {
								if (newEvents.length > (events?.length ?? 0)) {
									this._currentDayEvents$.next(newEvents);
									return true;
								} else {
									const newEvent = newEvents[newEvents.length - 1];
									if (
										newEvent &&
										moment(newEvent.end).format('DDMMYYYYHHmm') === moment(event.end).format('DDMMYYYYHHmm') &&
										moment(newEvent.start).format('DDMMYYYYHHmm') === moment(event.start).format('DDMMYYYYHHmm')
									) {
										this._currentDayEvents$.next(newEvents);
										return true;
									}
								}
							}
							return false;
						}),
						map((res) => {
							if (res) {
								checkInterval.unsubscribe();
								this.QueuedBookings = [];
							}
						})
					)
					.subscribe();*/

				/*delay(10000)
					.then(() => this.processCurrentDayEvents().toPromise())
					.then((events) => {
						console.log(events);
						this._currentDayEvents$.next(events);
					});*/
			});
	}

	mergeQueuedAndEvents(events: Event[]) {
		return [...events, ...this.QueuedBookings.filter((q: any) => true).map((q: any) => {})];
	}

	processCurrentDayEvents() {
		const schedulerDateRange = {
			von: moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
			bis: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
		};
		return this.myTxApiservice.callAPI('getCalendarPZE', schedulerDateRange).pipe(
			map((res) => {
				const events: Event[] = res.ListOfCalendarPZE.map((schedulerData: ListOfCalendarPZE) => {
					const currentEvent: Event = {
						// Global event settings
						id: schedulerData.id,
						title: 'PZE',
						start: schedulerData.beginn !== '' ? moment(schedulerData.beginn).toDate() : null,
						end: schedulerData.ende !== '' ? moment(schedulerData.ende).toDate() : null,
						color: schedulerData.color,
						className: ['pze-event'],
						allDay: false,
						overlap: false,
						extendedProps: {
							datum: schedulerData.datum,
							type: 'PZE',
							info: schedulerData.info,
							notiz: schedulerData.notiz,
							beginn_id: schedulerData.beginn_id,
							beginn: schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende,
							ende_id: schedulerData.ende_id,
							ende: schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn,
							kst_nummer: schedulerData.kst_nummer,
						} as ListOfCalendarPZE,
					};
					return currentEvent;
				});
				return events;
			})
		);
	}

	onFileUpload(event) {
		this.blockImageUploadUi = true;
	}

	ngOnInit(): void {
		this.store.dispatch(GetStates());
		this.myUserInfoService.catchUserInfoFromServer();
		this.tabState = JSON.parse(localStorage.getItem('tabActives'));

		jQuery('.nav-tabs').on('shown.bs.tab', 'a', (e) => {
			if (e.relatedTarget) {
				jQuery(e.relatedTarget).removeClass('active');
			}
		});

		this.processCurrentDayEvents()
			.toPromise()
			.then((res) => {
				this._currentDayEvents$.next(res);
			});

		/*this.calendarPZE.getDayEvents()
            .then(res => {
                this._currentDayEvents$.next(res);
            });*/

		if (!this.myConnectionStateService.isOnlineSubject.getValue()) {
			const myTmpKSTArray = JSON.parse(localStorage.getItem('txKostenstellen'));
			if (myTmpKSTArray) {
				for (let i = 0, l = myTmpKSTArray.length; i < l; i++) {
					this.kostenstellen.push({
						label: myTmpKSTArray[i].bezeichnung,
						command: () => {
							this.onKSTSelected(myTmpKSTArray[i].nummer);
						},
					});
				}
			}
		} else {
			const myKSTRequest = this.myTxApiservice.callAPI('getKST', {});
			myKSTRequest.subscribe(
				(myRequestData) => {
					if (myRequestData == null) {
						return;
					}

					for (let i = 0, l = myRequestData.ListOfKST.length; i < l; i++) {
						this.kostenstellen.push({
							label: myRequestData.ListOfKST[i].bezeichnung,
							command: () => {
								this.onKSTSelected(myRequestData.ListOfKST[i].nummer);
							},
						});
					}
					localStorage.setItem('txKostenstellen', JSON.stringify(myRequestData.ListOfKST));
				},
				(error) => {
					console.log('ERROR' + error);
				}
			);
		}
	}
}
