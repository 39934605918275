/* tslint:disable:variable-name */

export interface ListOfCalendarHead {
	id?: number;
	datum?: string;
	fehlzeit_id?: string;
	fehlzeit_dauer?: string;
	fehlzeit_name?: string;
	fehlzeit_color?: string;
	info?: string;
	type?: string;
	clickable?: boolean;
	kto_show?: number;
}

export interface ListOfCalendarPZE {
	id?: number;
	beginn: string;
	beginn_id: string;
	color?: string;
	ende: string;
	ende_id: string;
	info: string;
	kst_nummer: string;
	datum: string;
	notiz: string;
	type?: string;
}

export class ListOfCalendarBDE {
	id: number;
	auftrag_nummer: string;
	auftrag_name: string;
	beginn: string;
	ende: string;
	schritt_nummer: string;
	schritt_name: string;
	dauer_min: string;
	color: string;
	info: string;
	datum: string;
	type?: string;
	quantity?: number;
}

export interface SchedulerEvent {
	type?: string;
	book_type: string;
	datum: string;
	book_beginn: string;
	book_beginn_id: string;
	book_end: string;
	book_end_id: string;
	auftrag_nummer?: string;
	bde_info?: string;
	fehlzeit_id?: number;
	kst_nummer?: number;
	schritt_nummer?: string;
	team_leader?: number;
}

export interface Event {
	id: string | number;
	title: string;
	start: Date | string;
	end: Date | string;
	color: string;
	className?: string[];
	allDay: boolean;
	overlap?: boolean;
	queued?: boolean;
	extendedProps: SchedulerEvent | ListOfCalendarHead | ListOfCalendarPZE | ListOfCalendarBDE;
}
