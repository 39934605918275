import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
	getCalendarGroupList,
	getTimemodelDetails,
	getTimemodels,
} from '../../store/selectors/personal-office.selectors';
import { merge, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { TimemodelEntity } from '../../store/entities/TimemodelEntity';
import { debounceTime, delay, map, withLatestFrom } from 'rxjs/operators';
import { ModelBreakEntity, ModelFrameEntity, TimeModelDetailEntity } from '../../store/entities/TimeModelDetailEntity';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeModelBreak,
	ChangeModelFrame,
	DeleteTimemodelRequest,
	SaveTimemodel,
	SaveTimemodelRequest,
} from '../../store/actions/timemodel.actions';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { CalendarGroupEntity } from '../../store/entities/CalendarGroupEntity';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'tx-timemodels',
	templateUrl: './tx-timemodels.component.html',
	styleUrls: ['./tx-timemodels.component.scss'],
	animations: [
		trigger('fadeIn', [
			state('in', style({ opacity: 1 })),
			transition(':enter', [style({ opacity: 0 }), animate('200ms ease-in')]),
			transition(':leave', [style({ opacity: 0 }), animate('200ms ease-out')]),
		]),
	],
})
export class TxTimemodelsComponent implements OnDestroy, OnInit, AfterViewChecked {
	constructor(
		public translate: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private store: Store<State>,
		private fb: FormBuilder,
		private element: ElementRef
	) {
		this.Timemodels$ = this.store.select(getTimemodels);

		this.SelectedTimemodel$ = merge(
			this.store.select(getTimemodelDetails).pipe(
				withLatestFrom(route.paramMap),
				map(([models, params]) => [params, models])
			),
			route.paramMap.pipe(withLatestFrom(this.store.select(getTimemodelDetails)))
		).pipe(
			map(([params, timemodels]: [ParamMap, TimeModelDetailEntity[]]) => {
				let tm = timemodels?.find((tm) => tm.model_id === +params.get('id'));
				if (!tm && params.get('id') === '0') {
					tm = TxTimemodelsComponent.generateNewTimemodelDetailEntity();
				}

				if (tm) {
					this.patchForm(tm);
				}

				return tm;
			})
		);

		this.CalendarGroups$ = this.store.select(getCalendarGroupList);

		this.subs.push(
			this.TimemodelForm.valueChanges
				.pipe(
					delay(200),
					debounceTime(200),
					withLatestFrom(this.SelectedTimemodel$),
					map(([values, model]) => {
						//this.store.dispatch(SaveTimemodel(Object.assign(new TimeModelDetailEntity(), model, values)));
					})
				)
				.subscribe()
		);
	}

	public Timemodels$: Observable<TimemodelEntity[]>;
	public CalendarGroups$: Observable<CalendarGroupEntity[]>;

	public SelectedTimemodel$: Observable<TimeModelDetailEntity>;

	public ListOfModelFrame: ModelFrameEntity[] = [];
	public ListOfModelBreak: ModelBreakEntity[] = [];

	public TimemodelForm = this.fb.group({
		model_id: [null, [Validators.required]],
		name: [null, [Validators.required, Validators.min(3)]],
		calendar_group: [null, [Validators.required]],
		break_treshold_under: [null],
		break_min_val: [null],
		type: [null],
		round_day: [null],
		frame_check_g: [null],
		frame_check_k: [null],
		inclusiv_hours_val: [null],
		inclusiv_type: [null],
		max_hours_zk_val: [null],
		max_hours_day_val: [null],
		fix_hours_month_val: [null],
		short_break: [null],
	});

	public breakCheckValue = null;

	private subs: Subscription[] = [];

	public isLoading = false;

	private isInit = false;
	private static generateNewTimemodelDetailEntity() {
		const model: TimeModelDetailEntity = new TimeModelDetailEntity();
		model.ListOfModelFrame = [];
		for (let i = 1; i < 8; ++i) {
			const tmp = new ModelFrameEntity();
			tmp.id = 0;
			tmp.weekday = i;
			model.ListOfModelFrame.push(tmp);
		}
		model.ListOfModelBreak = [];
		model.model_id = 0;
		model.name = null;
		model.frame_check_g = 0;
		model.frame_check_k = 0;
		model.fix_hours_month_val = 0;
		model.inclusiv_hours_val = 0;
		model.inclusiv_type = 0;
		model.max_hours_day_val = 0;
		model.max_hours_zk_val = 0;
		model.round_day = 0;
		model.short_break = 0;
		model.calendar_group = 0;
		model.break_treshold_under = 0;
		model.break_min_val = 0;
		model.type = 0;
		return model;
	}

	ngOnInit() {}

	ngAfterViewChecked() {}

	ngOnDestroy() {
		this.subs.map((s) => s.unsubscribe());
	}

	public clearRow(row: ModelFrameEntity) {
		console.log(row);
		row.coretime1_end = null;
		row.coretime1_begin = null;
		row.coretime2_begin = null;
		row.coretime2_end = null;
		row.frame_begin = null;
		row.frame_end = null;
		row.plan_time = null;

		this.TimemodelForm.markAsDirty();
	}

	public patchForm(timemodel: TimeModelDetailEntity, force = false) {
		if (force || timemodel.model_id !== this.TimemodelForm.value.model_id) {
			this.TimemodelForm.reset();
			this.TimemodelForm.setValue({
				frame_check_g: timemodel.frame_check_g,
				frame_check_k: timemodel.frame_check_k,
				inclusiv_type: timemodel.inclusiv_type,
				model_id: timemodel.model_id,
				name: timemodel.name,
				calendar_group: timemodel.calendar_group,
				break_treshold_under: timemodel.break_treshold_under,
				break_min_val: timemodel.break_min_val,
				round_day: timemodel.round_day,
				type: timemodel.type,
				short_break: timemodel.short_break,
				inclusiv_hours_val: timemodel.inclusiv_hours_val
					? moment.duration(timemodel.inclusiv_hours_val, 'hours').format('HHH:mm')
					: null,
				max_hours_zk_val: timemodel.max_hours_zk_val
					? moment.duration(timemodel.max_hours_zk_val, 'hours').format('HHH:mm')
					: null,
				max_hours_day_val: timemodel.max_hours_day_val
					? moment.duration(timemodel.max_hours_day_val, 'hours').format('HHH:mm')
					: null,
				fix_hours_month_val: timemodel.fix_hours_month_val
					? moment.duration(timemodel.fix_hours_month_val, 'hours').format('HHH:mm')
					: null,
			});

			this.ListOfModelFrame = [
				...(timemodel.ListOfModelFrame?.map((mf) => Object.assign(new ModelFrameEntity(), mf)) ?? []),
			];

			this.ListOfModelBreak = [
				...(timemodel.ListOfModelBreak?.map((mb) => Object.assign(new ModelBreakEntity(), mb)) ?? []),
			];

			this.breakCheckValue = `${timemodel.frame_check_k}${timemodel.frame_check_g}`;
		}
	}

	unselectTimemodel() {
		this.router.navigate(['/', 'personaloffice', 'zeitmodelle', 'all']);
	}

	deleteTimemodel(selected: TimeModelDetailEntity) {
		this.store.dispatch(DeleteTimemodelRequest(selected));
	}

	onNewClick(clicked) {
		if (clicked) {
			this.router.navigate(['/', 'personaloffice', 'zeitmodelle', '0']);
		}
	}

	onChangeFrameTime(
		entity: TimeModelDetailEntity,
		row: ModelFrameEntity,
		field: keyof ModelFrameEntity,
		value: any,
		asString = false
	) {
		/*const tmp = {
			...entity.ListOfModelFrame.find((m) => m.id === row.id),
			[field]: asString
				? moment().seconds(0).minutes(0).hours(0).add(moment.duration(value.target.value)).format('YYYY-MM-DDTHH:mm:00')
				: moment.duration(value.target.value).asHours(),
		};*/

		//this.store.dispatch(ChangeModelFrame(entity.model_id, Object.assign(new ModelFrameEntity(), tmp)));
		if (!value.target.value) {
			// @ts-ignore
			row[field] = null;
		} else {
			// @ts-ignore
			row[field] = asString
				? moment().seconds(0).minutes(0).hours(0).add(moment.duration(value.target.value)).format('YYYY-MM-DDTHH:mm:00')
				: moment.duration(value.target.value).asHours();
		}
		this.TimemodelForm.markAsDirty();
	}

	changeCheckedSelection(selected: TimeModelDetailEntity, event: MatSelectChange) {
		let k = 0;
		let g = 0;
		switch (event.value) {
			case '00':
				break;
			case '10':
				k = 1;
				break;
			case '01':
				g = 1;
				break;
			case '11':
				k = 1;
				g = 1;
				break;
			default:
				break;
		}

		this.onChangeCheckbox(selected, 'frame_check_k', k);
		this.onChangeCheckbox(selected, 'frame_check_g', g);
	}

	onChangeCheckbox(
		entity: TimeModelDetailEntity,
		field: keyof TimeModelDetailEntity,
		value: MatCheckboxChange | number
	) {
		const val = value instanceof MatCheckboxChange ? value.checked : value;
		const e = {
			...entity,
			[field]: val ? 1 : 0,
		};

		this.TimemodelForm.patchValue({
			[field]: e[field],
		});

		// this.store.dispatch(SaveTimemodel(Object.assign(new TimeModelDetailEntity(), e)));

		this.TimemodelForm.markAsDirty();
	}

	onChangeModelBreakCheckbox(
		entity: TimeModelDetailEntity,
		row: ModelBreakEntity,
		field: keyof ModelBreakEntity,
		value: MatCheckboxChange
	) {
		/*const m = {
			...row,
			[field]: value.checked ? 1 : 0,
		};*/

		// @ts-ignore
		row[field] = value.checked ? 1 : 0;

		//this.store.dispatch(ChangeModelBreak(entity.model_id, Object.assign(new ModelBreakEntity(), m)));

		this.TimemodelForm.markAsDirty();
	}

	onChangeBreakTime(
		entity: TimeModelDetailEntity,
		row: ModelBreakEntity,
		field: keyof ModelBreakEntity,
		value: any,
		asString = false
	) {
		const e = this.ListOfModelBreak.find((mb) => mb.id === row.id);
		if (!value.target.value) {
			// @ts-ignore
			e[field] = null;
		} else {
			// @ts-ignore
			e[field] = asString
				? moment().seconds(0).minutes(0).hours(0).add(moment.duration(value.target.value)).format('YYYY-MM-DDTHH:mm:00')
				: moment.duration(value.target.value).asHours();
		}

		//this.store.dispatch(ChangeModelBreak(entity.model_id, Object.assign(new ModelBreakEntity(), tmp)));

		this.TimemodelForm.markAsDirty();
	}

	onChangedMaskedInput(entity: TimeModelDetailEntity, field: keyof TimeModelDetailEntity, event: Event) {
		if (!(event.target instanceof HTMLInputElement)) {
			return;
		}
		const e = {
			...entity,
			[field]: moment.duration(event.target.value).asHours(),
		};

		this.TimemodelForm.patchValue({
			[field]: e[field],
		});

		// this.store.dispatch(SaveTimemodel(Object.assign(new TimeModelDetailEntity(), e)));

		this.TimemodelForm.markAsDirty();
	}

	setValueToNull(field: string) {
		this.TimemodelForm.patchValue({
			[field]: null,
		});

		this.TimemodelForm.markAsDirty();
	}

	filterModelBreaks(a: ModelBreakEntity) {
		return !a.deleted;
	}

	addModelBreak(entity: TimeModelDetailEntity) {
		/*const e = {
			...entity,
			ListOfModelBreak: entity.ListOfModelBreak ? [...entity.ListOfModelBreak] : [],
		};*/
		const model = new ModelBreakEntity();
		model.id = this.ListOfModelBreak.reduce((acc, curr) => (acc > curr.id ? acc : curr.id), 0) + 1;
		model.deleted = false;
		model.isNew = true;
		model.break_val = null;
		model.break_begin_plan = null;
		model.break_end_plan = null;
		model.break_treshold_val = null;
		//e.ListOfModelBreak.push(model);

		this.ListOfModelBreak.push(model);
		//this.store.dispatch(SaveTimemodelRequest(e));

		return false;
	}

	deleteBreakModel(entity: TimeModelDetailEntity, row: ModelBreakEntity) {
		/*const e = {
			...entity,
			ListOfModelBreak: [
				...entity.ListOfModelBreak.filter((mb) => mb.id !== row.id),
				Object.assign(new ModelBreakEntity(), {
					...row,
					deleted: true,
				}),
			],
		};*/

		this.ListOfModelBreak = this.ListOfModelBreak.map((mb) =>
			mb.id !== row.id
				? mb
				: {
						...mb,
						deleted: true,
				  }
		);

		this.TimemodelForm.markAsDirty();

		//this.store.dispatch(SaveTimemodelRequest(Object.assign(new TimeModelDetailEntity(), e)));

		return false;
	}

	onSubmit(model: TimeModelDetailEntity) {
		this.TimemodelForm.markAllAsTouched();

		const newModel = Object.assign(new TimeModelDetailEntity(), model, this.TimemodelForm.value, {
			ListOfModelBreak: this.ListOfModelBreak.map((mb) => {
				if (mb.isNew) {
					return {
						...mb,
						id: 0,
						isNew: null,
					};
				}
				return mb;
			}),
			ListOfModelFrame: this.ListOfModelFrame,
		});

		this.store.dispatch(SaveTimemodelRequest(newModel));
		this.patchForm(newModel, true);
	}
}
