import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AppConfigService } from './appconfig.service';
import { Router } from '@angular/router';
import { ErrorloggerService } from './errorlogger.service';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ReportEntity } from '../../store/entities/ReportEntity';
import { Md5 } from 'ts-md5';
import { ApiResponse } from '../../store/entities/Api/ApiResponse';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { getReportList } from '../../store/selectors/reports.selectors';
import { FetchReports, SetReports } from '../../store/actions/reports.actions';
import { GlobalConstants } from '../../globalConstants';

@Injectable()
export class TxreportapiService {
	constructor(
		private readonly http: HttpClient,
		private readonly auth: AuthService,
		private readonly config: AppConfigService,
		private readonly actions: Actions,
		private readonly router: Router,
		private readonly store: Store,
		private readonly errorLoggerService: ErrorloggerService
	) {}

	getReports(): Observable<ReportEntity[]> {
		return this.store.select(getReportList).pipe(
			switchMap((reports) => {
				if (!reports) {
					this.store.dispatch(FetchReports());
					return this.actions.pipe(
						ofType(SetReports),
						map(({ list }) => list)
					);
				}
				return of(reports);
			}),
			catchError((err) => {
				console.log(err);
				return of(null);
			})
		);
	}

	public getReport(
		report: ReportEntity,
		filter?: { idFilter?: number[]; dateBeginFilter?: Date; dateEndFilter?: Date }
	) {
		return this.http.post(
			`${this.config.config.TimeIXReportServerUrl}`,
			{
				txportalSessionId: this.auth.getSession().sessionid,
				reportTemplate: report.report_template,
				reportName: report.report_name,
				reportSection: report.report_section,
				reportType: report.report_type,
				filter,
				debugData: false,
			},
			{
				responseType: 'blob',
			}
		);
	}

	public fetchAll() {
		return this.callApi();
	}

	private callApi(id: string | null = null): Observable<ReportEntity[]> {
		const body = {
			hash: '',
			sessionid: '',
			iun: '',
			connectionspeed: '0',
			app_version: '',
			report_section: '',
		};
		// hash the complete request as an identifier

		if (this.auth.getSession() === null) {
			return of(null);
		}

		if (!body?.hash) {
			body.hash = Md5.hashStr(this.config.config.TimeIXServerUrl).toString();
		}
		body.sessionid = this.auth.getSession().sessionid; /* assign sessionID */
		body.iun = this.auth.getSession().iun; /* assign userName */
		body.app_version = GlobalConstants.appVersion ?? 'dev';

		return this.http
			.post<ApiResponse<ReportEntity[]>>(`${this.config.config.TimeIXServerUrl}getReports`, body, {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
				}),
			})
			.pipe(
				map((res) => res.ReportDetail),
				catchError((err) => {
					console.log(err);
					return of(null);
				})
			);
	}
}
