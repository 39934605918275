import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FSReference, TlProtocolService } from '../../shared/services/tl-protocol.service';
import { ListOfTLProtocol } from '../../shared/interfaces/tlprotocol';
import { forkJoin, interval, Observable, of } from 'rxjs';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import {
	catchError,
	delayWhen,
	map,
	sample,
	switchMap,
	take,
	takeUntil,
	tap,
	timeout,
	withLatestFrom,
} from 'rxjs/operators';

declare var Messenger: any;

@Component({
	selector: 'tx-teamleaderprotocol',
	templateUrl: './tx-teamleaderprotocol.component.html',
	styleUrls: ['./tx-teamleaderprotocol.component.scss'],
	animations: [
		trigger('rowVisibleState', [
			state(
				'false',
				style({
					height: '40px',
					opacity: 1,
					fontSize: '14px',
					display: 'table-row',
				})
			),

			transition('1 => 0', [
				animate(
					'500ms ease-in',
					keyframes([
						style({ display: 'table-row', offset: 0 }),
						style({ fontSize: 0, offset: 0 }),
						style({ height: 0, offset: 0 }),
						style({ opacity: 0, offset: 0.0 }),
						style({ height: '40px', fontSize: '14px', display: 'table-row', opacity: 1, offset: 1.0 }),
					])
				),
			]),
			state(
				'true',
				style({
					height: '0px',
					opacity: 0,
					display: 'none',
					fontSize: 0,
				})
			),
			transition('0 => 1', [
				animate(
					'500ms ease-out',
					keyframes([
						style({ opacity: 1, offset: 0 }),
						style({ fontSize: '14px', offset: 0 }),
						style({ height: '40px', offset: 0 }),
						style({ fontSize: 0, height: 1, opacity: 0.5, offset: 0.9 }),
						style({ height: '0px', opacity: 0.5, display: 'none', offset: 1.0 }),
					])
				),
			]),
		]),
	],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class TxTeamleaderprotocolComponent implements OnInit {
	cols: any[];
	tableEntries: ListOfTLProtocol[];
	selectedEntries = [];
	withArchiveState = false;
	driverLicenseCheck = false;
	refImage: string;
	refBase64: SafeResourceUrl;
	userImage: string;
	driverLicenseNotice: string;
	currentDataId: number;

	OnlineState$: Observable<{ isOnline: boolean }>;

	isBusy: {
		[key: string]: boolean;
	} = {};

	constructor(
		private myTlprotocolService: TlProtocolService,
		public sanitizer: DomSanitizer,
		private connectionStateService: ConnectionStateService
	) {
		this.OnlineState$ = connectionStateService.onlineState.pipe(
			map((online) => ({
				isOnline: online,
			}))
		);
	}

	ngOnInit() {
		this.updateRowData();
		this.cols = [
			{ field: 'name', header: 'Name', width: '25%' },
			{ field: 'datum', header: 'Datum', width: '25%' },
			{ field: 'protocoltext', header: 'Info', width: '40%' },
		];
		Messenger.options = {
			extraClasses: 'messenger-fixed messenger-on-top',
			theme: 'flat',
		};
	}

	updateRowData(): void {
		this.myTlprotocolService.getTlProtocoll(this.withArchiveState).subscribe((data) => {
			this.tableEntries = data.map((myEntryData) => myEntryData);
			this.selectedEntries = data.filter((entries) => entries.confirmed !== '');
		});
	}

	onRowSelect(event) {
		if (event.data) {
			const eventIndex = this.tableEntries.findIndex((data) => data.id === event.data.id);

			this.myTlprotocolService.updateTlProtocol(event.data.id, 1, 1000).subscribe((data) => {
				if (!this.withArchiveState) {
					this.tableEntries[eventIndex].deleteState = true;
				}
			});
		} else {
			if (event.checked) {
				this.processBatch(this.tableEntries, 1).subscribe((data) => {
					this.updateRowData();
				});
			} else {
				this.processBatch(this.tableEntries, 0).subscribe((data) => {
					this.updateRowData();
				});
			}
		}
	}

	onRowUnselect(event) {
		const eventIndex = this.tableEntries.findIndex((data) => data.id === event.data.id);
		this.tableEntries[eventIndex].deleteState = false;
		this.myTlprotocolService.updateTlProtocol(event.data.id, 0).subscribe((data) => {});
	}

	handleArchiveState(event) {
		this.updateRowData();
	}

	processBatch(inputObject, stateCode: number = 1) {
		const observableBatch = [];

		inputObject.forEach((myEntry) => {
			observableBatch.push(this.myTlprotocolService.updateTlProtocol(myEntry.id, stateCode, 2500));
		});
		return forkJoin(observableBatch);
	}

	showDriverLicenseCheckDialog(dataId: number, personalNr: number) {
		this.isBusy[dataId] = true;
		this.currentDataId = dataId;

		this.myTlprotocolService
			.getReferenceData(dataId, personalNr)
			.pipe(
				switchMap((data) => {
					const intermediate = this.connectionStateService.isIntermediateSubject.getValue();
					if (intermediate) {
						return this.connectionStateService.intermediateState.pipe(
							map(() => {
								return data;
							})
						);
					}
					return of(data);
				}),
				withLatestFrom(this.OnlineState$),
				take(1)
			)
			.subscribe(([result, state]) => {
				this.isBusy[dataId] = false;
				if (!state?.isOnline) {
					Messenger().message(
						'Aktuell zu schlechte Netzwerkverbindung, diese Funktion ist daher vorübergehend nicht verfügbar.',
						'info'
					);
					return;
				}
				if (result.statuscode === 0) {
					this.driverLicenseCheck = true;
					this.refImage = 'data:image/jpeg;base64,' + result.ref_data;
					/*this.refBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
						'data:application/pdf;base64,' + result.ref_data
					);*/
					this.refBase64 = Buffer.from(result.ref_data, 'base64');
					this.userImage = 'data:image/jpeg;base64,' + result.user_data;
				} else {
					Messenger().post({
						message: result.statustext,
						type: 'error',
						showCloseButton: true,
					});
				}
			});
	}

	confirmDriverLicenseCheck(status: number) {
		this.myTlprotocolService
			.updateTlProtocol(this.currentDataId, status, 0, 'FSCHECK', status, this.driverLicenseNotice)
			.subscribe(
				(result) => {
					this.driverLicenseCheck = false;
					this.updateRowData();

					Messenger().post({
						message: result.statustext,
						type: 'success',
						showCloseButton: true,
						hideAfter: 5,
					});
				},
				(error) => {
					console.log('ERROR' + error);
					Messenger().post({
						message: 'ERROR' + error,
						type: 'error',
						showCloseButton: true,
					});
				},
				() => {
					this.driverLicenseNotice = '';
				}
			);
	}

    getInputValue(event: EventTarget) {
        return '';
    }
}
