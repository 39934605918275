import { createAction } from '@ngrx/store';
import { ModelBreakEntity, ModelFrameEntity, TimeModelDetailEntity } from '../entities/TimeModelDetailEntity';

export const ChangeModelFrame = createAction(
	'[Time Models Detail] change model frame',
	(id: number, change: ModelFrameEntity) => ({ id, change })
);

export const ChangeModelBreak = createAction(
	'[Time Model Detail] change model break',
	(id: number, change: ModelBreakEntity) => ({ id, change })
);

export const SaveTimemodelRequest = createAction(
	'[Time Model Detail] save timemodel detail request',
	(entity: TimeModelDetailEntity) => ({ entity })
);

export const SaveTimemodel = createAction(
	'[Time Model Detail] save timemodel detail',
	(entity: TimeModelDetailEntity) => ({ entity })
);

export const DeleteTimemodelRequest = createAction(
	'[Time Model Detail] deletet timemodel request',
	(entity: TimeModelDetailEntity) => ({ entity })
);

export const DeleteTimemodel = createAction('[Time Model Detail] deletet timemodel', (id: number) => ({ id }));
