export type TX_BOOL = 0 | 1;

export function empty(e) {
	switch (e) {
		case '':
		case 0:
		case '0':
		case null:
		case 'null':
		case false:
		// tslint:disable-next-line:triple-equals
		case typeof e == 'undefined':
			return true;
		default:
			return false;
	}
}

export function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

export async function delay<T>(millis: number = 1000, value?: T) {
	return new Promise<T>((_) => setTimeout(() => _(value), millis));
}

export function ZeroFill(value: number, characters = 2) {
	let ret = `${value}`;
	if (ret.length < characters) {
		ret = `${'0'.repeat(characters - ret.length)}${ret}`;
	}
	return ret;
}

export function Download(blob: any, name: string) {
	const a = document.createElement('a');
	const objectUrl = URL.createObjectURL(blob);
	a.href = objectUrl;
	a.download = name;
	a.click();
	URL.revokeObjectURL(objectUrl);
	a.remove();
}
