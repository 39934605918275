import { AfterViewInit, Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	concatMap,
	forkJoin,
	lastValueFrom,
	mergeMap,
	Observable,
	of,
	ReplaySubject,
	Subject,
	switchMap,
} from 'rxjs';
import { ListOfWorkflowGroup, TxListOfWorkflowTyp } from '../../../shared/interfaces/workflow';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PlanCalendarEntity } from '../../../store/entities/PlanCalendarEntity';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { catchError, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { CalendarOptions, createElement, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import moment, { Moment } from 'moment';
import { MDCTooltip } from '@material/tooltip';
import { Store } from '@ngrx/store';
import { WorkflowService } from '../../../shared/services/workflow.service';
import { PlansService } from '../../../shared/services/plans.service';
import { AppConfigService } from '../../../shared/services/appconfig.service';
import { TxApiService } from '../../../shared/services/txapi.service';
import { getPlanCalendar } from '../../../store/selectors/plans.selectors';
import { ResourceInput } from '@fullcalendar/resource-common';
import { ListOfCalendarBDE, ListOfCalendarHead, ListOfCalendarPZE } from '../../../shared/interfaces/scheduler';
import chroma from 'chroma-js';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TxTimeTrackingDialogComponent } from '../../dialogs/tx-time-tracking/tx-time-tracking.dialog';
import { MatDialog } from '@angular/material/dialog';
import { DateSelectArg } from '@fullcalendar/common';
import { MessengerService } from '../../../shared/services/messenger.service';
import { TxCreateBooking } from '../../../shared/interfaces/bdebuchung';
import { ApiResponseType } from '../../../store/entities/Api/ApiResponse';
import { GlobalConstants } from '../../../globalConstants';

@Component({
	selector: 'tx-po-accounts',
	templateUrl: './tx-po-accounts.component.html',
	styleUrls: ['./tx-po-accounts.component.scss'],
})
export class TxPoAccountsComponent implements OnDestroy, AfterViewInit {
	@ViewChild('calendar') calendarComponent: FullCalendarComponent;

	public workflowGroups$: Observable<Array<ListOfWorkflowGroup>>;
	public workflows$: Observable<PlanCalendarEntity[]>;

	public SelectedGroups: number[] = [];

	public selectedGroups$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null);

	public accountInfos$ = new BehaviorSubject<{
		teamleader_for: number;
		begin_date: Date;
		end_date: Date;
	} | null>(null);

	public CalendarOptions: CalendarOptions = {
		schedulerLicenseKey: GlobalConstants.fullCalendarLicenseKey,
		plugins: [resourceTimelinePlugin],
		locale: 'de',
		headerToolbar: {
			left: 'refresh today customPrev,customNext',
			center: 'title',
			right: 'viewDay,viewWeek',
		},
		initialView: 'resourceTimelineDay',
		themeSystem: 'bootstrap',
		buttonText: {
			today: this.translate.instant('txWorkflowCalender.today') as string,
		},
		bootstrapFontAwesome: {
			today: 'fa-clock',
		},
		customButtons: {
			refresh: {
				text: 'Reload',
				bootstrapFontAwesome: 'fa-sync',
				click: (ev: MouseEvent, element: HTMLElement) => {
					this.calendarComponent.getApi().refetchEvents();
				},
			},
			customPrev: {
				text: '<',
				click: () => {
					this.calendarComponent.getApi().prev();
					this.router.navigateByUrl(
						`/personaloffice/personalkonten/${moment(
							this.calendarComponent.getApi().getCurrentData().currentDate
						).format('yyyy-MM-DD')}`
					);
				},
			},
			customNext: {
				text: '>',
				click: () => {
					this.calendarComponent.getApi().next();
					this.router.navigateByUrl(
						`/personaloffice/personalkonten/${moment(
							this.calendarComponent.getApi().getCurrentData().currentDate
						).format('yyyy-MM-DD')}`
					);
				},
			},
			viewDay: {
				text: this.translate.instant('txScheduler.day'),
				click: (mouseEvent, htmlElement) => {
					this.calendarComponent.getApi().changeView('resourceTimelineDay');
					this.calendarComponent.getApi().refetchEvents();
					this.appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
						config.SelectedView = 'resourceTimelineDay';
						this.appConfig.setPersonalAccountsConfig(config);
					});

					this.router.navigateByUrl(
						`/personaloffice/personalkonten/${moment(
							this.calendarComponent.getApi().getCurrentData().currentDate
						).format('yyyy-MM-DD')}`
					);
					htmlElement.parentNode.querySelector('.fc-state-active')?.classList.remove('fc-state-active');
					htmlElement.classList.add('fc-state-active');
				},
			},
			viewWeek: {
				text: this.translate.instant('txScheduler.week'),
				click: (mouseEvent, htmlElement) => {
					this.calendarComponent.getApi().changeView('resourceTimelineWeek');
					this.calendarComponent.getApi().refetchEvents();
					this.appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
						config.SelectedView = 'resourceTimelineWeek';
						this.appConfig.setPersonalAccountsConfig(config);
					});

					this.router.navigateByUrl(
						`/personaloffice/personalkonten/${moment(
							this.calendarComponent.getApi().getCurrentData().currentDate
						).format('yyyy-MM-DD')}`
					);
					htmlElement.parentNode.querySelector('.fc-state-active')?.classList.remove('fc-state-active');
					htmlElement.classList.add('fc-state-active');
				},
			},
		},
		resourceAreaColumns: [
			{
				field: 'select',
				headerContent: (args) => {
					return createElement('input', {
						type: 'checkbox',
						className: 'multi-user-select-all-cb',
						onClick: (args) => {
							const el: HTMLInputElement = args.target;
							let selector = '.multi-user-select-cb';
							if (el.checked) {
								selector += ':not(:checked)';
							} else {
								selector += ':checked';
							}

							this.MultiSelectUserIds = [];

							document.querySelectorAll(selector).forEach((e: HTMLInputElement) => {
								e.click();
							});
						},
					});
				},
				width: 30,
				cellContent: (args) => {
					return createElement('input', {
						type: 'checkbox',
						value: args.resource.id,
						className: 'multi-user-select-cb',
						onClick: () => {
							this.toggleMultiSelect(args.resource.id);
						},
					});
				},
			},
			{
				field: 'title',
				headerContent: 'Name',
				cellContent: (args) => {
					return createElement('a', {
						text: args.resource.title,
						onClick: () => {
							window.location.href = `/#/kalender;id=${args.resource.id};date=${moment(args.view.currentStart).format(
								'yyyy-MM-DD'
							)}`;
						},
						href: '#',
					});
				},
			},
			{
				field: 'info',
				headerContent: (args) => {
					return args.view.type === 'resourceTimelineDay' ? 'Tagesinfo' : 'Wocheninfo';
				},
				cellContent: (args) => {
					return createElement('span', {
						style: {
							fontSize: '10px',
						},
						dangerouslySetInnerHTML: {
							__html: this.accountInfo[+args.resource.id] ?? '',
						},
					});
				},
			},
		],
		eventDidMount: (info) => {
			if (!info.timeText) {
				info.timeText = `${moment(info.event.start).format('HH:mm')} - ${moment(info.event.end).format('HH:mm')}`;
			}
			const event_id = `event-tooltip-${info.event.id}`;
			// check if timeText contains Invalid and if the current view is resourceTimelineDay
			if (info.timeText.toLowerCase().includes('invalid') && info.view.type === 'resourceTimelineDay') {
				return;
			}

			info.el.setAttribute('aria-describedby', event_id);
			if (!document.querySelector('#' + event_id)) {
				const tt = document.createElement('div');
				tt.id = event_id;
				tt.className = 'mdc-tooltip event-tooltip';
				tt.setAttribute('role', 'tooltip');
				tt.setAttribute('aria-hidden', 'true');

				const inner = tt.appendChild(document.createElement('div'));
				inner.className = 'mdc-tooltip__surface mdc-tooltip__surface-animation';
				if (info.timeText.toLowerCase().includes('invalid')) {
					inner.innerText = `${info.timeText}: ${info.event.title}`;
				} else {
					inner.innerText = `${info.timeText}: ${info.event.title}`;
				}

				document.body.appendChild(tt);

				new MDCTooltip(tt);
			} else if (document.querySelector('#' + event_id)) {
				if (info.timeText.toLowerCase().includes('invalid')) {
					// find and update the tooltip
					document
						.querySelector('#' + event_id)
						.querySelector<HTMLElement>('.mdc-tooltip__surface').innerText = `${info.event.title}`;
				}
				new MDCTooltip(document.querySelector('#' + event_id));
			}

			if (info.el.getBoundingClientRect().width < 85) {
				info.el.innerHTML = '';
				info.el.classList.add('event-tooltip-short');
				const icon = document.createElement('i');
				icon.className = 'fas fa-mouse-pointer';
				icon.style.color = info.event.textColor;
				info.el.appendChild(icon);
			}

			console.log(info.event);

			if (info.event.extendedProps.info) {
				const descriptionContainer = document.createElement('div');
				descriptionContainer.className = 'fc-description';
				descriptionContainer.innerHTML = info.event.extendedProps.info;
				info.el.querySelector('.fc-event-title-container')?.appendChild(descriptionContainer);
			}
		},
		weekNumbers: true,
		weekNumberCalculation: 'ISO',
		weekText: 'KW',
		weekTextLong: 'Woche',
		views: {
			resourceTimelineDay: {
				displayEventTime: true,
				eventTimeFormat: {
					hour: '2-digit',
					minute: '2-digit',
				},
				titleFormat: {
					day: '2-digit',
					month: 'long',
					weekday: 'long',
					year: 'numeric',
				},
				buttonText: 'Tag',
				slotDuration: { hours: 1 },
				slotLabelFormat: [
					{
						hour: '2-digit',
						minute: '2-digit',
					},
				],
				slotLabelContent: (hookProps) => hookProps.text.replace(' Uhr', ''),
				select: (info) => this.selectDateRange(info),
			},
			resourceTimelineWeek: {
				type: 'resourceTimeline',
				eventTimeFormat: {
					hour: '2-digit',
					minute: '2-digit',
				},
				titleFormat: {
					month: 'long',
					day: '2-digit',
					week: 'narrow',
					year: 'numeric',
				},
				buttonText: 'Woche',
				slotDuration: { days: 1 },
				slotLabelFormat: {
					weekday: 'short',
					day: '2-digit',
					omitCommas: true,
				},
				select: (info) => this.selectDateRange(info),
			},
		},
		firstDay: 1,
		contentHeight: 'auto',
		scrollTime: '12:00',
		allDaySlot: true,
		defaultAllDay: true,
		editable: false,
		selectable: true,
		nowIndicator: true,
		events: [],
		resources: [],
	};

	private accountInfo: {
		[key: number]: string;
	} = {};

	public ShowDayBalance = true;
	public MultiSelectUserIds: string[] = [];

	constructor(
		private readonly store: Store,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly workflowService: WorkflowService,
		private readonly planService: PlansService,
		private readonly appConfig: AppConfigService,
		private readonly api: TxApiService,
		private readonly _ngZone: NgZone,
		private readonly dialog: MatDialog,
		private readonly translate: TranslateService,
		private readonly messenger: MessengerService
	) {
		this.accountInfos$
			.asObservable()
			.pipe(
				filter(Boolean),
				concatMap((d) => this.api.callAPI('getTimeAccountinfo', d).pipe(map((res) => ({ res, d }))))
			)
			.subscribe((data) => {
				this.accountInfo[data.d.teamleader_for] = data.res.infotext;
				this.calendarComponent.getApi().render();
			});

		appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
			this.SelectedGroups = config.SelectedGroups;
			this.selectedGroups$.next(config.SelectedGroups);

			this.ShowDayBalance = config.ShowDayBalance;

			if (this.calendarComponent) {
				this.calendarComponent.getApi().changeView(config.SelectedView ?? 'resourceTimelineDay');
				this.setCurrentViewState();
			} else {
				setTimeout(() => {
					this.calendarComponent.getApi().changeView(config.SelectedView ?? 'resourceTimelineDay');
					this.setCurrentViewState();
				}, 100);
			}
		});

		route.firstChild?.paramMap.pipe(take(1)).subscribe((params) => {
			const date = moment(params.get('date'));
			if (date.isValid()) {
				if (this.calendarComponent) {
					this.calendarComponent.getApi().gotoDate(date.toDate());
				} else {
					setTimeout(() => {
						this.calendarComponent.getApi().gotoDate(date.toDate());
					}, 100);
				}
			}
		});

		this.workflowGroups$ = workflowService.getWorkflowGroups();
		this.workflows$ = combineLatest([this.store.select(getPlanCalendar), this.selectedGroups$.asObservable()]).pipe(
			map(([res, selectedGroups]) => {
				return res.filter((d) => (selectedGroups ?? []).includes(d.workflow_group));
			}),
			tap((res) => {
				this.CalendarOptions.resources = res.map(
					(g) => ({ id: `${g.employee_id}`, title: g.employee_name, info: g.info } as ResourceInput)
				);
				this.CalendarOptions.events = this.fetchEvents.bind(this);
			})
		);

		this.selectedGroups$.pipe(withLatestFrom(appConfig.personalAccountsConfig$)).subscribe(([selected, config]) => {
			if (JSON.stringify(selected.sort()) !== JSON.stringify(config.SelectedGroups.sort())) {
				config.SelectedGroups = selected;
				this.SelectedGroups = selected;
				this.appConfig.setPersonalAccountsConfig(config);
			}
		});
	}

	async selectDateRange(info: DateSelectArg) {
		const data: {
			selected: string;
			data: TxListOfWorkflowTyp;
		} = await lastValueFrom(
			this.dialog
				.open(TxTimeTrackingDialogComponent, {
					data: {
						title: 'Zeiterfassung',
					},
				})
				.afterClosed()
		);
		if (!data) {
			return;
		}
		const ids = [...this.MultiSelectUserIds];
		if (!ids.includes(info.resource.id)) {
			ids.push(info.resource.id);
		}
		for (const id of ids) {
			await this.createEvent(info.start, info.end, +data.selected, +id);
		}

		this.MultiSelectUserIds = [];
		document.querySelectorAll('.multi-user-select-cb').forEach((el: HTMLInputElement) => {
			el.removeAttribute('checked');
			el.checked = false;
		});
		this.calendarComponent.getApi().refetchEvents();
	}

	createEvent(start: Date, end: Date, id: number, employee_id: number) {
		let endpoint = '';
		const data = new TxCreateBooking();

		const mStart = moment(start);
		const mEnd = moment(end);

		data.fehlzeit_id = id;
		data.team_leader_for = employee_id;
		data.book_beginn = mStart.format('YYYY-MM-DD') + 'T00:00:00';
		data.book_end =
			(mEnd.format('YYYY-MM-DD') === mStart.format('YYYY-MM-DD') ? mEnd : mEnd.subtract(1, 'day')).format(
				'YYYY-MM-DD'
			) + 'T23:59:59';
		data.book_type = 'FZ';

		return lastValueFrom(
			this.api.callAPI('setBookPeriod', data).pipe(
				tap((res: ApiResponseType) => {
					if (res.statuscode === -1) {
						this.messenger.message(res.statustext, 'error');
					}
				}),
				catchError((error, ex) => {
					console.log('ERROR' + error);

					this.messenger.message('ERROR ' + error, 'error');
					return of(error);
				}),
				take(1)
			)
		);
	}

	toggleMultiSelect(id: string) {
		if (this.MultiSelectUserIds.includes(id)) {
			this.MultiSelectUserIds = this.MultiSelectUserIds.filter((s) => s !== id);
		} else {
			this.MultiSelectUserIds.push(id);
		}
	}

	toggleDayBalance(event: MouseEvent) {
		event.stopPropagation();
		event.stopImmediatePropagation();

		this.ShowDayBalance = !this.ShowDayBalance;

		this.appConfig.personalAccountsConfig$.pipe(take(1)).subscribe((config) => {
			config.ShowDayBalance = this.ShowDayBalance;

			this.appConfig.setPersonalAccountsConfig(config);
		});

		this.calendarComponent.getApi().refetchEvents();

		return false;
	}

	ngAfterViewInit(): void {}
	setCurrentViewState(): void {
		// get current view type
		const viewType = this.calendarComponent.getApi().view.type;
		// change state of view type button, depending on which view is currently active
		if (viewType === 'resourceTimelineDay') {
			document.querySelector('.fc-viewDay-button').classList.add('fc-state-active');
		} else if (viewType === 'resourceTimelineWeek') {
			document.querySelector('.fc-viewWeek-button').classList.add('fc-state-active');
		}
	}

	ngOnDestroy(): void {}

	toggleGroup(event: MatButtonToggleChange) {
		this.selectedGroups$.next(event.value);
	}

	fetchEvents(info, success, failure) {
		setTimeout(() => {
			const calendarApi = this.calendarComponent.getApi();
			const schedulerStartDate = moment(calendarApi.view.currentStart).format('YYYY-MM-DD') + 'T00:00:00';
			const schedulerEndDate = moment(calendarApi.view.currentEnd).format('YYYY-MM-DD') + 'T00:00:00';

			const data: Observable<any>[] = [];

			const schedulerDateRange = {
				von: schedulerStartDate,
				bis: schedulerEndDate,
				team_leader_for: 0,
			};

			data.push(
				this.store.select(getPlanCalendar).pipe(
					map((res) => {
						res.forEach((r) => {
							this.accountInfos$.next({
								teamleader_for: r.employee_id,
								begin_date: this.calendarComponent.getApi().view.currentStart,
								end_date: moment(this.calendarComponent.getApi().view.currentEnd).subtract(1, 'day').toDate(),
							});
						});
						return res.filter((d) => this.SelectedGroups.includes(d.workflow_group));
					}),
					switchMap((res) => {
						return forkJoin(
							res.map((e) => {
								const copy = JSON.parse(
									JSON.stringify({
										...schedulerDateRange,
										team_leader_for: e.employee_id,
										staff_office: 1,
									})
								);
								return forkJoin([
									this.api.callAPI('getCalendarHead', copy).pipe(
										map((res) => {
											return res.ListOfCalendarHead.map((schedulerData: ListOfCalendarHead) => {
												if (!schedulerData.fehlzeit_name && !schedulerData.fehlzeit_id && !schedulerData.info) {
													return null;
												}
												const datum = moment(schedulerData.datum).format('YYYY-MM-DD');
												const title = (schedulerData.fehlzeit_name ?? '') + (schedulerData.info ?? '');
												if (!this.ShowDayBalance && schedulerData.kto_show === 0) {
													return null;
												}
												let color = schedulerData.fehlzeit_color ? schedulerData.fehlzeit_color : 'rgba(210,210,210,1)';
												color = `rgba(${chroma(color).rgb().join(',')}, 0.4)`;
												const currentEvent: EventInput = {
													// Global event settings
													id: schedulerData.id + 'FZ',
													employee_id: e.employee_id,
													title: title?.replace('null', '') ?? '',
													start: datum,
													end: datum,
													color,
													classNames: !!schedulerData.fehlzeit_id ? 'event-absence' : 'event-summary',
													allDay: true,
													// editable: !!schedulerData.fehlzeit_name,
													extendedProps: {
														type: 'FZ',
														fehlzeit_id: schedulerData.fehlzeit_id,
														fehlzeit_dauer: schedulerData.fehlzeit_dauer,
														fehlzeit_name: schedulerData.fehlzeit_name,
														clickable: !!schedulerData.fehlzeit_name,
													} as ListOfCalendarHead,
												};
												return currentEvent;
											}).filter(Boolean);
										})
									),
									this.api.callAPI('getCalendarPZE', copy).pipe(
										map((res) => {
											const events = [];
											events.push(
												...res.ListOfCalendarPZE.map((schedulerData: ListOfCalendarPZE) => {
													const currentEvent: EventInput = {
														// Global event settings
														id: schedulerData.id?.toString() ?? '',
														employee_id: e.employee_id,
														title: 'PZE',
														start: moment(
															schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende
														).toDate(),
														end: moment(schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn).toDate(),
														color: schedulerData.color,
														className: ['pze-event'],
														allDay: false,
														overlap: false,
														extendedProps: {
															datum: schedulerData.datum,
															type: 'PZE',
															info: schedulerData.info,
															notiz: schedulerData.notiz,
															beginn_id: schedulerData.beginn_id,
															beginn: schedulerData.beginn !== '' ? schedulerData.beginn : schedulerData.ende,
															ende_id: schedulerData.ende_id,
															ende: schedulerData.ende !== '' ? schedulerData.ende : schedulerData.beginn,
															kst_nummer: schedulerData.kst_nummer,
														} as ListOfCalendarPZE,
													};
													return currentEvent;
												})
											);
											return events;
										})
									),
									this.api.callAPI('getCalendarBDE', copy).pipe(
										map((res) => {
											return res.ListOfCalendarBDE.map((schedulerData: ListOfCalendarBDE) => {
												const currentEvent: EventInput = {
													// Global event settings
													id: schedulerData.id + '',
													employee_id: e.employee_id,
													title: schedulerData.schritt_name
														? schedulerData.auftrag_name + ' – ' + schedulerData.schritt_name
														: schedulerData.auftrag_name,
													start: schedulerData.beginn,
													end: schedulerData.ende,
													overlap: false,
													color: schedulerData.color,
													allDay: false,
													extendedProps: {
														datum: schedulerData.datum,
														type: 'BDE',
														beginn: schedulerData.beginn,
														ende: schedulerData.ende,
														auftrag_nummer: schedulerData.auftrag_nummer,
														schritt_nummer: schedulerData.schritt_nummer,
														bde_id: schedulerData.id,
														info: schedulerData.info,
														quantity: schedulerData.quantity,
													},
												};
												return currentEvent;
											});
										})
									),
								]);
							})
						);
					})
				)
			);

			combineLatest(data)
				.pipe(
					map((a) => {
						return a.map((ar) => ar.flat(3));
					}),
					take(1)
				)
				.subscribe((e) => {
					console.log(e);
					const elements = e.flat().map((el) => {
						const color = chroma(el.color);
						const currentEvent: EventInput = {
							id: el.id,
							resourceId: el.employee_id,
							allDay: el.allDay,
							title: el.title.includes('<') ? '' : el.title,
							start: el.start,
							end: el.end,
							timeZone: 'local',
							classNames: 'event-round-border ' + (el.classNames ?? ''),
							backgroundColor: el.color,
							borderColor: el.borderColor ?? color.brighten(0.5).hex(),
							textColor: color.luminance() > 0.5 ? 'black' : 'white',
							extendedProps: {
								...el.extendedProps,
								info: el.title.includes('<') ? el.title : null,
							},
						};
						return currentEvent;
					});
					success(elements);
				});
		});
	}
}
