import { Action, createReducer, on } from '@ngrx/store';
import { ListOfKSTEntity } from '../entities/ListOfKSTEntity';
import { SetDepartments, SetEmployeeGroups, SetCostcenters, SetDataLoading } from '../actions/data.actions';
import { ListOfDepartmentEntity } from '../entities/ListOfDepartmentEntity';
import { ListOfEmployeeGroupEntity } from '../entities/ListOfEmployeeGroupEntity';

export interface State {
	costcenters: Array<ListOfKSTEntity>;
	departments: Array<ListOfDepartmentEntity>;
	employeeGroups: Array<ListOfEmployeeGroupEntity>;
	loading: boolean;
}

export const initialState: State = {
	costcenters: null,
	departments: null,
	employeeGroups: null,
	loading: false,
};

const dataReducer = createReducer(
	initialState,
	on(SetCostcenters, (state, { list }) => ({
		...state,
		costcenters: [...list],
	})),
	on(SetDepartments, (state, { list }) => ({
		...state,
		departments: [...list],
	})),
	on(SetEmployeeGroups, (state, { list }) => ({
		...state,
		employeeGroups: [...list],
	})),
	on(SetDataLoading, (state, { loading }) => ({
		...state,
		loading,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return dataReducer(state, action);
}
