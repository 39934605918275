import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Md5 } from 'ts-md5/dist/md5';

import * as EXIF from 'exif-js';
//import * as moment from 'moment';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import { ImageUploadService } from '../../shared/services/image-upload.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

declare var Messenger: any;

@Component({
	selector: 'tx-imageupload',
	templateUrl: './tx-imageupload.component.html',
})
export class TxImageuploadComponent implements OnInit {
	@Input() title = 'Bildupload';
	@Input() type = 'FS';
	@Input() description = 'Bitte laden Sie ein Bild hoch.';
	@Input() chooseLabel = 'Aufnehmen';
	@Input() uploadLabel = 'Hochladen';
	@Input() resetLabel = 'Abbrechen';
	@Input() blockui = false;
	@Output() blockuiChange = new EventEmitter<boolean>();
	@Input() forceDesktop = false;
	@Output() upload: EventEmitter<any> = new EventEmitter();

	@ViewChild('fileInput') fileInput: HTMLInputElement;

	readyToUpload = false;
	fileData = {};
	exifData: any;
	selectedFile: File;
	uploadedImage: File;
	imagePreview: any;
	imagePreviewLoading = false;
	mobileOrTabletDevice = false;

	pending = false;

	constructor(
		/*private ng2ImgMaxService: Ng2ImgMaxService,*/
		public sanitizer: DomSanitizer,
		public connectionState: ConnectionStateService,
		private myImageUploadService: ImageUploadService,
		private deviceDetectorService: DeviceDetectorService
	) {
		if (this.forceDesktop === false) {
			if (this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
				this.mobileOrTabletDevice = true;
			}
		} else {
			this.mobileOrTabletDevice = false;
		}
	}

	async onFileChanged(event) {
		this.imagePreviewLoading = true;
		this.selectedFile = event.target.files[0];
		this.exifData = await this.getExifData(event.target.files[0]);
		this.resizeImageAndPreview(this.selectedFile);
	}

	resizeImageAndPreview(file) {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const can = document.createElement('canvas');
			const ctx = can.getContext('2d');
			const thisImage = new Image();
			if (typeof reader.result === 'string') {
				thisImage.src = reader.result;
			}

			thisImage.onload = () => {
				const maxWidth = 640;
				const maxHeight = 480;
				let tempW = thisImage.width;
				let tempH = thisImage.height;
				if (tempW > tempH) {
					if (tempW > maxWidth) {
						tempH *= maxWidth / tempW;
						tempW = maxWidth;
					}
				} else {
					if (tempH > maxHeight) {
						tempW *= maxHeight / tempH;
						tempH = maxHeight;
					}
				}
				can.width = tempW;
				can.height = tempH;
				ctx.save();

				ctx.drawImage(thisImage, 0, 0, tempW, tempH);
				ctx.restore();
				this.imagePreview = can.toDataURL('image/jpeg');
				this.readyToUpload = true;
				this.imagePreviewLoading = false;
			};
		};
	}

	getExifData(image): Promise<any> {
		return new Promise((resolve, reject) => {
			EXIF.getData(image, function () {
				const allMetaData = EXIF.getAllTags(this);
				resolve(allMetaData);
			});
		});
	}

	ConvertDMSToDD(degrees, minutes, seconds, direction) {
		let dd = degrees + minutes / 60 + seconds / (60 * 60);

		if (direction === 'S' || direction === 'W') {
			dd = dd * -1;
		} // Don't do anything for N or E
		return dd;
	}

	getGPSValue(): string {
		try {
			const latDegree = this.exifData.GPSLatitude[0].numerator;
			const latMinute = this.exifData.GPSLatitude[1].numerator;
			const latSecond = this.exifData.GPSLatitude[2].numerator / 1000;
			const latDirection = this.exifData.GPSLatitudeRef;
			const gLat = this.ConvertDMSToDD(latDegree, latMinute, latSecond, latDirection);

			const lonDegree = this.exifData.GPSLongitude[0].numerator;
			const lonMinute = this.exifData.GPSLongitude[1].numerator;
			const lonSecond = this.exifData.GPSLongitude[2].numerator / 1000;
			const lonDirection = this.exifData.GPSLongitudeRef;
			const gLon = this.ConvertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);
			return gLat + ',' + gLon;
		} catch (err) {
			return '';
		}
	}

	onUpload() {
		let pdf = '';
		let pdfImageType = null;
		const md5 = new Md5();
		const formatX = this.exifData.PixelXDimension ? this.exifData.PixelXDimension : 640;
		const formatY = this.exifData.PixelYDimension ? this.exifData.PixelYDimension : 480;
		if (this.selectedFile.type === 'image/jpeg') {
			pdfImageType = 'JPEG';
		}

		if (pdfImageType) {
			const doc = new jsPDF({
				orientation: 'landscape',
				unit: 'px',
				format: [formatX, formatY],
			});

			const ratio = formatX / formatY;
			const width = doc.internal.pageSize.getWidth();
			const height = ratio * width;

			doc.addImage(this.imagePreview, pdfImageType, 0, 0, width, height);
			pdf = doc.output('datauristring', { filename: this.selectedFile.name });
		}

		this.fileData = {
			filename: this.selectedFile.name,
			metaGPS: this.getGPSValue(),
			metaHash: md5.appendStr(this.imagePreview).end(),
			metaType: this.selectedFile.type,
			picData: this.imagePreview.replace('data:image/jpeg;base64,', ''),
			pdfData: pdf.replace('data:application/pdf;filename=' + this.selectedFile.name + ';base64,', ''),
			picType: this.type,
			metaDate: moment(this.selectedFile.lastModified).format('YYYY-MM-DDTHH:mm:ss'),
		};

		this.pending = true;

		const obs = of(this.fileData);

		obs
			.pipe(
				switchMap((data) => {
					const intermediate = this.connectionState.isIntermediateSubject.getValue();
					if (intermediate) {
						return this.connectionState.intermediateState.pipe(
							map(() => {
								return data;
							})
						);
					}
					return of(data);
				}),
				withLatestFrom(this.connectionState.onlineState),
				switchMap(([data, online]) => {
					if (!online) {
						return of(null);
					}
					return this.myImageUploadService.uploadImage(data);
				}),
				take(1)
			)
			.subscribe(
				(myUpload) => {
					if (!myUpload) {
						Messenger().post({
							message:
								'Aktuell zu schlechte Netzwerkverbindung, diese Funktion ist daher vorübergehend nicht verfügbar.',
							type: 'info',
							showCloseButton: true,
							hideAfter: 5,
						});
						this.readyToUpload = false;
						this.blockuiChange.emit(false);
						this.fileInput.value = '';
						return;
					}
					this.pending = false;
					let messengerState = 'success';
					if (myUpload.statuscode === -1) {
						console.log('error');
						messengerState = 'error';
						this.readyToUpload = false;
						this.blockuiChange.emit(false);
						this.fileInput.value = '';
					}

					Messenger().post({
						message: myUpload.statustext,
						type: messengerState,
						showCloseButton: true,
						hideAfter: 5,
					});

					this.imagePreview = null;

					setTimeout(() => {
						this.readyToUpload = false;
						this.blockuiChange.next(false);
						this.fileInput.value = '';
					}, 2500);
				},
				(error) => {
					console.log('ERROR' + error);
					this.readyToUpload = false;
					this.blockuiChange.next(false);
					this.fileInput.value = '';
					Messenger().post({
						message: error.statustext,
						type: 'error',
						showCloseButton: true,
						hideAfter: 5,
					});

					setTimeout(() => {
						this.readyToUpload = false;
						this.blockuiChange.next(false);
						this.fileInput.value = '';
					}, 2500);
				}
			);

		this.blockuiChange.next(true);
	}

	resetUpload() {
		this.imagePreview = '';
		this.readyToUpload = false;
		this.fileInput.value = '';
	}

	ngOnInit() {
		Messenger.options = {
			extraClasses: 'messenger-fixed messenger-on-top',
			theme: 'flat',
		};
	}
}
