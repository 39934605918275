import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TxApiService } from '../../shared/services/txapi.service';
import {
	GetDepartments,
	GetEmployeeGroups,
	GetCostcenters,
	SetDepartments,
	SetEmployeeGroups,
	SetCostcenters,
	UpdateDepartment,
	DeleteDepartment,
	UpdateEmployeeGroup,
	DeleteEmployeeGroup,
	SetDataLoading,
} from '../actions/data.actions';
import { interval, switchMap, takeUntil, takeWhile } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ApiResponse } from '../entities/Api/ApiResponse';
import { ListOfKSTEntity } from '../entities/ListOfKSTEntity';
import { ListOfDepartmentEntity } from '../entities/ListOfDepartmentEntity';
import { ListOfEmployeeGroupEntity } from '../entities/ListOfEmployeeGroupEntity';
import { TxResponse } from '../../shared/interfaces/tx-response';
import { MessengerService } from '../../shared/services/messenger.service';
import { DataService } from '../../shared/services/data.service';
import { getDepartments, getEmployeeGroups } from '../selectors/data.selectors';

@Injectable({
	providedIn: 'root',
})
export class DataEffects {
	getKst$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GetCostcenters),
			switchMap(() => this.http.callAPI('getKST')),
			map((res: ApiResponse<ListOfKSTEntity[]>) => {
				return SetCostcenters(res.ListOfKST.map((l) => ListOfKSTEntity.tryCast(l)));
			})
		)
	);

	getDepartments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GetDepartments),
			switchMap(() => this.http.callAPI('getDepartments')),
			map((res: ApiResponse<ListOfDepartmentEntity[]>) => {
				return SetDepartments(res.ListOfDepartment.map((d) => ListOfDepartmentEntity.tryCast(d)));
			})
		)
	);

	getEmployeeGroups$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GetEmployeeGroups),
			switchMap(() => this.http.callAPI('getEmployeeGroups')),
			map((res: ApiResponse<ListOfEmployeeGroupEntity[]>) => {
				return SetEmployeeGroups(res.ListOfEmployeeGroup.map((d) => ListOfEmployeeGroupEntity.tryCast(d)));
			})
		)
	);

	updateDepartment$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UpdateDepartment),
				switchMap(({ id, name }) =>
					this.http.callAPI('updateDepartment', { department: { id, name } }).pipe(
						tap(() => {
							this.store.dispatch(SetDataLoading(true));
						}),
						switchMap(() =>
							interval(1000).pipe(
								tap(() => {
									this.store.dispatch(GetDepartments());
								}),
								withLatestFrom(this.store.select(getDepartments)),
								takeWhile(([, departments]) => {
									const department = departments.find((d) => (id > 0 ? d.id === id : d.name === name));
									console.log(department);
									if (department?.sync_state === 1) {
										this.store.dispatch(SetDataLoading(false));
									}
									return department?.sync_state !== 1;
								})
							)
						)
					)
				)
			),
		{ dispatch: false }
	);

	deleteDepartment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeleteDepartment),
			switchMap(({ id }) => this.http.callAPI('deleteDepartment', { id })),
			map((res: TxResponse) => {
				if (res.statuscode === -1) {
					this.toast.message(res.statustext, 'error');
				}
				return GetDepartments();
			})
		)
	);

	updateEmployeeGroup$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UpdateEmployeeGroup),
				switchMap(({ id, name }) =>
					this.http.callAPI('updateEmployeeGroup', { employeeGroup: { id, name } }).pipe(
						tap(() => {
							this.store.dispatch(SetDataLoading(true));
						}),
						switchMap(() =>
							interval(1000).pipe(
								tap(() => {
									this.store.dispatch(GetEmployeeGroups());
								}),
								withLatestFrom(this.store.select(getEmployeeGroups)),
								takeWhile(([, employeeGroups]) => {
									const employeeGroup = employeeGroups.find((d) => (id > 0 ? d.id === id : d.name === name));
									if (employeeGroup?.sync_state === 1) {
										this.store.dispatch(SetDataLoading(false));
									}
									return employeeGroup?.sync_state !== 1;
								})
							)
						)
					)
				)
			),
		{ dispatch: false }
	);

	deleteEmployeeGroup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeleteEmployeeGroup),
			switchMap(({ id }) => this.http.callAPI('deleteEmployeeGroup', { id })),
			map(() => GetEmployeeGroups())
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store,
		private http: TxApiService,
		private readonly dataService: DataService,
		private readonly toast: MessengerService
	) {}
}
