<ng-container>
    <div mat-dialog-title class="flex justify-between items-center min-w-[500px]">
        <div class="m-0 mr-1 text-xl">
            {{ title }}
        </div>
        <button mat-mini-fab mat-dialog-close color="primary" class="mat-elevation-z0">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div class="flex flex-column">
            <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input matInput #name>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Teamleiter</mat-label>
                <mat-select #select>
                    <mat-option *ngFor="let user of teamleaderUsers" [value]="user.employee_id">
                        {{ user.firstname }} {{ user.lastname }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="flex flex-justify-content-flex-end">
                <button
                        mat-raised-button
                        color="accent"
                        [mat-dialog-close]="{ teamleaderUser: select.value, name: name.value }"
                        [disabled]="!select.value || !name.value"
                >Erstellen</button>
            </div>
        </div>
    </div>
</ng-container>
