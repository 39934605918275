<!--<div class="grid">-->
<!--    <div class="col-12">-->
<!--        <section class="widget clearfix">-->
<!--            <div class="grid">-->
<!--                <div class="col-12 md:col-8">-->
<!--                    <p *ngIf="!myTeamleaderState">{{ 'txTeamleiter.deputyOn' | translate }}</p>-->
<!--                    <p *ngIf="myTeamleaderState">-->
<!--                        {{ 'txTeamleiter.deputyOff' | translate }}-->
<!--                        <span *ngIf="session?.stellvertreter_name != ''">-->
<!--							Derzeit ist {{ session?.stellvertreter_name }} Ihr Stellvertreter-->
<!--						</span>-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="col-12 md:col-4">-->
<!--                    <button *ngIf="!myTeamleaderState" (click)="toggleTeamleaderState(1)"-->
<!--                            class="btn btn-primary pull-md-right">-->
<!--                        <i class="fa fa-toggle-off"></i> {{ 'txTeamleiter.substituteOn' | translate }}-->
<!--                    </button>-->
<!--                    <button *ngIf="myTeamleaderState" (click)="toggleTeamleaderState(0)"-->
<!--                            class="btn btn-success pull-md-right">-->
<!--                        <i class="fa fa-toggle-on"></i> {{ 'txTeamleiter.substituteOff' | translate }}-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
<!--    </div>-->
<!--</div>-->

<div class="grid" *ngIf="myWorkflowCalendar$ | async">
    <div class="relative col-12">
        <div class="absolute top-0 right-0 z-index-1000 pr-2 pt-2">
            <button *ngIf="!myTeamleaderState" (click)="toggleTeamleaderState(1)"
                    class="btn btn-primary pull-md-right">
                <i class="fa fa-toggle-off"></i> {{ 'txTeamleiter.substituteOn' | translate }}
            </button>
            <button *ngIf="myTeamleaderState" (click)="toggleTeamleaderState(0)"
                    class="btn btn-success pull-md-right">
                <i class="fa fa-toggle-on"></i> {{ 'txTeamleiter.substituteOff' | translate }}
            </button>
        </div>
        <p-accordion (onOpen)="onTabOpen('t1')" (onClose)="onTabClose('t1')">
            <div class="scroll-indicator">
                <i class="fa fa-angle-right blink"></i>
                <i class="fa fa-angle-right blink"></i>
                <i class="fa fa-angle-right blink"></i>
            </div>
            <p-accordionTab
                    [header]="'txTeamleiter.teamCalender' | translate"
                    [selected]="tabState?.t1 == null || tabState?.t1 == true ? true : false">
                <section class="widget disabled-exact disabled-loading"
                         [ngClass]="{ 'disabled-overlay': calendarIsBusy }">
                    <tx-workflow-calendar
                            #txWorkflowCalendarComponent
                            *ngIf="myWorkflowCalendar"
                            [CalendarData]="myWorkflowCalendar"
                            [TeamLeader]="true"
                            [WfGroups]="wfGroups"
                            [showAllDataLoad]="true"
                            (OnRefresh)="getWorkflowCalendar(0)"
                            (OnClickLoadFullYear)="getWorkflowCalendar(1)"
                            (OnBusy)="calendarIsBusy = $event"></tx-workflow-calendar>
                </section>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>

<!--<div class="row">
    <div class="col-xs-12">
        <p-accordion (onOpen)="onTabOpen('dayView')" (onClose)="onTabClose('dayView')">
            <p-accordionTab [header]="'Tagesansicht'"
                            [selected]="tabState?.dayView == null || tabState?.dayView == true ? true : false">
                <tx-dayview></tx-dayview>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>-->

<div class="grid">
    <div class="col-12">
        <p-accordion (onOpen)="onTabOpen('t2')" (onClose)="onTabClose('t2')">
            <p-accordionTab
                    [header]="'txTeamleiter.requestsFromTeam' | translate"
                    [selected]="tabState?.t2 == null || tabState?.t2 == true ? true : false"
                    (mouseenter)="unwatchWorkflow()" (mouseleave)="watchWorkflow()"
            >
                <div class="scroll-indicator">
                    <i class="fa fa-angle-right blink"></i>
                    <i class="fa fa-angle-right blink"></i>
                    <i class="fa fa-angle-right blink"></i>
                </div>
                <section class="widget">
                    <div *ngIf="!myWorkflowHasData">Keine Anträge vorhanden</div>

                    <div *ngIf="myWorkflowHasData">
                        <div class="widget-body">
                            <p-table
                                    [value]="Workflow"
                                    styleClass="table table-hover"
                                    [paginator]="true"
                                    [rows]="20"
                                    [responsive]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>{{ 'txTeamleiter.date' | translate }}</th>
                                        <th>{{ 'txTeamleiter.type' | translate }}</th>
                                        <th>{{ 'txTeamleiter.name' | translate }}</th>
                                        <th>{{ 'txTeamleiter.editor' | translate }}</th>
                                        <th>{{ 'txTeamleiter.note' | translate }}</th>
                                        <th>{{ 'txTeamleiter.answer' | translate }}</th>
                                        <th>{{ 'txTeamleiter.state' | translate }}</th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item>
                                    <tr class="bb-0">
                                        <td>
                                            <span class="p-column-title">Datum</span>

                                            <div class="md:inline-block sm:flex sm:flex-column">

                                                <div>
                                                    <ng-template [ngIf]="checkBisData(item.bis)">
                                                        <strong>{{ 'txTeamleiter.from' | translate }}:</strong> {{ item.von | date: 'EE dd.MM.yyyy'
                                                        }}<br/>
                                                    </ng-template>
                                                    <ng-template [ngIf]="!checkBisData(item.bis)">
                                                        <strong>{{ 'txTeamleiter.on' | translate }}:</strong> {{ item.von | date: 'EE dd.MM.yyyy'
                                                        }}<br/>
                                                    </ng-template>
                                                </div>

                                                <div>
                                                    <ng-template [ngIf]="checkBisData(item.bis)">
                                                        <strong>{{ 'txTeamleiter.until' | translate }} :&nbsp;</strong>
                                                        {{ item.bis ? (item?.bis | date: 'EE dd.MM.yyyy') : '' }}
                                                    </ng-template>
                                                    <ng-template [ngIf]="!checkBisData(item.bis)">
                                                        <strong>{{ 'txTeamleiter.at' | translate }} :&nbsp;</strong>
                                                        {{ item?.von | formatTime: 'HH:mm' }}
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span class="p-column-title">Typ</span>{{ item.Typname }}{{ item.TypNeu }}
                                        </td>
                                        <td class="wrap"><span class="p-column-title">Name</span>{{ item.Name }}</td>
                                        <td class="wrap"><span class="p-column-title">Bearbeiter</span><span
                                                [innerHTML]="item.Bearbeiter"></span></td>
                                        <td class="wrap">
                                            <span class="p-column-title">Notiz</span><span
                                                [innerHTML]="item.NotizAntragssteller"></span>
                                        </td>
                                        <td class="wrap">
											<span class="p-column-title">Antwort</span>
                                            <input #notiz (focus)="unwatchWorkflow('input')" (blur)="!notiz.value && watchWorkflow('input')" type="text" autocomplete="new-password" class="form-control" />
                                        </td>
                                        <td>
                                            <span class="p-column-title">Status</span>
                                            {{ item.Status == 0 ? 'abgelehnt' : '' }}
                                            {{ item.Status == 1 ? 'genehmigt' : '' }}
                                            {{ item.Status == 99 ? 'offen' : '' }}
                                        </td>
                                        <td rowspan="2" class="workflow-buttons">
                                            <!--                                        <td [attr.rowspan]="item.Info ? '2' : null">-->
                                            <button
                                                    type="submit"
                                                    class="btn btn-primary btn-block mr-2"
                                                    (click)="onApproveClick(item, notiz.value)">
                                                {{ 'txTeamleiter.agree' | translate }}
                                            </button>
                                            <button
                                                    type="submit"
                                                    class="btn btn-primary btn-block"
                                                    (click)="onDeclineClick(item, notiz.value)">
                                                {{ 'txTeamleiter.disagree' | translate }}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr (mouseenter)="hoverBoth($event)" (mouseleave)="hoverBoth($event)">
                                        <td colspan="7" class="wrap">
                                            <strong *ngIf="item.info">{{ 'txTeamleiter.info' | translate }}: </strong
                                            ><span [innerHTML]="item.info"></span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </section>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>

<div class="grid">
    <div class="col-12">
        <p-accordion (onOpen)="onTabOpen('t2')" (onClose)="onTabClose('t2')">
            <p-accordionTab
                    [header]="'txTeamleiter.protocol' | translate"
                    [selected]="tabState?.t2 == null || tabState?.t2 == true ? true : false">
                <section class="widget">
                    <tx-teamleaderprotocol></tx-teamleaderprotocol>
                </section>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
