import { createAction } from '@ngrx/store';
import { ListOfKSTEntity } from '../entities/ListOfKSTEntity';
import { ListOfDepartmentEntity } from '../entities/ListOfDepartmentEntity';
import { ListOfEmployeeGroupEntity } from '../entities/ListOfEmployeeGroupEntity';

export const GetCostcenters = createAction('[Data] get kst');

export const SetCostcenters = createAction('[Data] set kst', (list: ListOfKSTEntity[]) => ({ list }));

export const GetDepartments = createAction('[Data] get departments');
export const SetDataLoading = createAction('[Data] set data loading', (loading: boolean) => ({
	loading,
}));

export const SetDepartments = createAction('[Data] set departments', (list: ListOfDepartmentEntity[]) => ({ list }));

export const GetEmployeeGroups = createAction('[Data] get employee groups');

export const SetEmployeeGroups = createAction('[Data] set employee groups', (list: ListOfEmployeeGroupEntity[]) => ({
	list,
}));

export const UpdateDepartment = createAction('[Data] update department', (id: number, name: string) => ({ id, name }));

export const DeleteDepartment = createAction('[Data] delete department', (id: number) => ({ id }));

export const UpdateEmployeeGroup = createAction('[Data] update employee group', (id: number, name: string) => ({
	id,
	name,
}));

export const DeleteEmployeeGroup = createAction('[Data] delete employee group', (id: number) => ({ id }));
