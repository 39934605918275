<div [ngClass]="qrScan ? 'tx-barcode-scanner--qr' : ''" class="tx-barcode-scanner">
	<div *ngIf="configMode" class="tx-barcode-scanner__select">
        <mat-form-field
                class="w-100"
                appearance="fill"
        >
            <mat-label>Kamera</mat-label>
            <mat-select
                    [(ngModel)]="selectedDevice"
                    (selectionChange)="onDeviceSelectChange($event)"
            >
                <mat-option *ngFor="let device of availablesDevices" [value]="device.value">
                    {{ device.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
		<!--<p-dropdown
			[options]="availablesDevices"
			appendTo="body"
			styleClass="fullwidthDiv">
        </p-dropdown>
        <div *ngIf="scanner.device">
            <div class="font-weight-bold my-2">{{ scanner.device.label }}:</div>
        </div>-->
	</div>
	<div [ngClass]="deviceSelected ? 'tx-barcode-scanner__container--visible' : ''" class="tx-barcode-scanner__container">
		<zxing-scanner
			#scanner
			(scanSuccess)="handleQrCodeResult($event)"
			[device]="currentDevice"
			[formats]="formats"
            [autostart]="true"
			[enable]="scannerEnabled"
			[tryHarder]="true">
		</zxing-scanner>
		<div class="tx-barcode-scanner__helper"></div>
	</div>
    <div *ngIf="!deviceSelected && !!currentDevice" class="w-100 flex flex-justify-content-center flex-align-items-center" style="min-width: 300px; min-height: 200px; padding: 10px;">
        <mat-spinner class="text-center"></mat-spinner>
    </div>
</div>
