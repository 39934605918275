<mat-form-field
	*ngIf="material === true; else noMaterial"
	[id]="id"
	[appearance]="appearance"
	class="w-100"
	(click)="onClick($event)">
	<mat-label>{{ placeholder }}</mat-label>
	<input
		#input
		(blur)="onBlur()"
		[(ngModel)]="value"
		[disabled]="disabled"
		[name]="name"
		matInput
		type="text"
		readonly
		[ngStyle]="{ textAlign: textAlign }"
		[required]="required" />
</mat-form-field>
<ng-template #noMaterial>
	<div class="clockpicker">
		<input
			(blur)="onBlur()"
			(click)="registerClockpicker()"
			[(ngModel)]="value"
			[disabled]="disabled"
			[id]="id"
			[name]="name"
			[placeholder]="placeholder"
			pInputText
			readonly
			[ngStyle]="{ textAlign: textAlign }"
			type="text" />
	</div>
</ng-template>
