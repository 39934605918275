<div *ngIf="{ users: Users$ | async, currentUser: CurrentUser$ | async, head: MessageHead$ | async } as data">
    <mat-card class="mat-elevation-z0">
        <mat-card-title>
            <div>Message Center</div>
        </mat-card-title>
        <mat-card-content>
            <div class="grid" *ngIf="ActiveUsers$ | async as Users">
                <div class="col-2" style="overflow-y: auto; max-height: 71vh;">
                    <div class="">
                        <tx-list [items]="Users"
                                 [labelField]="getLabel"
                                 [idField]="'persnr'"
                                 [searchField]="'firstname,lastname'"
                                 [showNew]="false"
                                 [badges]="getBadges(data.head)"
                                 routerLinkBase="personaloffice/messages"
                                 (selectedItem)="Employee = $event ? $event.firstname + ' ' + $event.lastname : ''">
                        ></tx-list>
                    </div>
                </div>
                <div *ngIf="UserMessages$ | async as UserMessages" class="col-10" style="max-height: 71vh;">
                    <tx-chat
                             #chat
                             [height]="getAvailableHeight() - (AnswerMessage ? 58 : 0)"
                             [is_office]="true"
                             [Messages]="UserMessages"
                             [(WithArchived)]="WithArchived"
                             [Title]="Employee"
                             [(AnswerMessage)]="AnswerMessage"
                             (refresh)="refresh()"
                             (send)="sendMessage($event.message, $event.answer)"
                             (archive)="archiveMessage($event.message, $event.remove)"
                    ></tx-chat>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
