import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { AppConfigService } from './appconfig.service';
import { TxSession } from '../interfaces/session';
import sha1 from 'crypto-js/sha1';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { getPermissions, getSession as getUserSession } from '../../store/selectors/user.selectors';
import { KillStore } from '../../store/actions';

const myHeaders = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	}),
};

@Injectable()
export class AuthService {
	// store the URL so we can redirect after logging in
	public redirectUrl: string;
	private session: TxSession;
	public session$: Observable<TxSession>;
	public CurrentUserPermissions$: string[];

	constructor(
		private http: HttpClient,
		private myAppConfig: AppConfigService,
		private router: Router,
		private store: Store<State>
	) {
		this.session$ = store.select(getUserSession);
		this.session$.subscribe((session) => {
			this.session = session;
		});
		// load session if saved in local storage

		// get permission of current user
		this.store.select(getPermissions).subscribe((permissions) => {
			this.CurrentUserPermissions$ = permissions;
		});
	}

	can(permission: string): boolean {
		return this.CurrentUserPermissions$.indexOf(permission) > -1;
	}
	preparepassword(password, loggedIn?) {
		if (!loggedIn) {
			if (password === '') {
				return null;
			} else {
				return sha1(password).toString();
			}
		}
		return password;
	}

	login(username, password, loggedIn?): Observable<TxSession> {
		const apiUrl = this.myAppConfig.config.TimeIXServerUrl + 'createSession';
		return this.http
			.post<any>(
				apiUrl,
				{
					username,
					password: this.preparepassword(password, loggedIn ? loggedIn : null),
				},
				myHeaders
			)
			.pipe(
				map((result) => {
					const myResult = result;
					myResult.iun = username;
					this.setSession(myResult);
					this.setSessionDomain(username);
					return myResult;
				}),
				catchError(this.handleError)
			);
	}

	qrLogin(qr, domain) {
		const apiUrl = this.myAppConfig.config.TimeIXServerUrl + 'createSession';
		return this.http
			.post<any>(
				apiUrl,
				{
					username: '',
					password: '',
					qrcode: qr,
					pindomain: domain,
				},
				myHeaders
			)
			.pipe(
				map((result) => {
					this.setSession(result);
					return result;
				}),
				catchError(this.handleError)
			);
	}

	pinLogin(pin, domain) {
		const apiUrl = this.myAppConfig.config.TimeIXServerUrl + 'createSession';
		return this.http
			.post<any>(
				apiUrl,
				{
					username: '',
					password: '',
					pin,
					pindomain: domain,
				},
				myHeaders
			)
			.pipe(
				map((result) => {
					this.setSession(result);
					return result;
				}),
				catchError(this.handleError)
			);
	}

	logout() {
		if (localStorage.getItem('txlgn') !== null) {
			localStorage.removeItem('txlgn');
		}
		if (localStorage.getItem('txSchedulerDefaultDate') !== null) {
			localStorage.removeItem('txSchedulerDefaultDate');
		}
		this.redirectUrl = '';
		const apiUrl = this.myAppConfig.config.TimeIXServerUrl + 'deleteSession';
		this.http
			.post<any>(apiUrl, { sessionid: this.session.sessionid }, myHeaders)
			.pipe(
				map(() => {
					this.deleteSession();
				}),
				map(() => {
					this.store.dispatch(KillStore());
					this.router.navigate(['/login']);
				}),
				catchError((error) => {
					this.router.navigate(['/login']);
					return this.handleError(error);
				}),
				take(1)
			)
			.subscribe(() => {
				// use reload to force kill all views
				window.location.reload();
			});
	}

	setSession(aSession: TxSession) {
		this.session = aSession;
		if (environment.production === false) {
			this.debugSessionSettings();
		}
		localStorage.removeItem('currentSession');
		localStorage.removeItem('teamleaderChangedTo');
		localStorage.setItem('currentSession', JSON.stringify(this.session));
	}

	getSession() {
		if (environment.production === false) {
			this.debugSessionSettings();
		}
		return this.session;
	}

	getSessionDomain() {
		return Promise.resolve(localStorage.getItem('txSessionDomain'));
	}

	setSessionDomain(username: string) {
		if (username.includes('@')) {
			localStorage.setItem('txSessionDomain', username.replace(/.*@/, '').trim());
		}
	}

	deleteSession() {
		this.session = new TxSession();
		localStorage.removeItem('currentSession');
		localStorage.removeItem('teamleaderChangedTo');
		localStorage.removeItem('txlgn');
	}

	private handleError(error: any) {
		// In a real world app, we might use a remote logging infrastructure
		// We'd also dig deeper into the error to get a better message
		const errMsg = error.message
			? error.message
			: error.status
			? `${error.status} - ${error.statusText}`
			: 'Server error';
		console.error(errMsg); // log to console instead
		return observableThrowError(errMsg);
	}

	debugSessionSettings() {
		// this.session.calendar_pze = 1;
		// this.session.calendar_bde = 1;
	}
}
