import {Workbox} from 'workbox-window';
function loadServiceWorker() {
    console.log('Loading service worker');
    if ('serviceWorker' in navigator) {
        const wb = new Workbox('/service-worker.js');

        wb.addEventListener('activated', (event) => {
            if (!event.isUpdate) {
                console.log('Service worker activated for the first time!');
            } else {
                console.log('Service worker activated!');
                alert('Update verfügbar, bitte neuladen');
                document.location.reload();
            }
        });

        wb.addEventListener('waiting', (event) => {
            console.log(
                `A new service worker has installed, but it can't activate` +
                `until all tabs running the current version have fully unloaded.`
            );
        });

        wb.addEventListener('installed', (event) => {
            if (!event.isUpdate) {
                console.log('Service worker installed for the first time');
            } else {
                console.log('Service worker installed');
            }
        });

        wb.register();
    }
}
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

import 'moment-duration-format';
import { dom, library, config } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(far, fas);
config.autoReplaceSvg = false;

// dom.watch();

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
    .then((module) => {
        loadServiceWorker();
        return module;
    });

if (environment.hmr) {
    if (module[ 'hot' ]) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap().catch(err => console.log(err));
}
