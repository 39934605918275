import * as fromData from '../reducers/data.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getData = createFeatureSelector<fromData.State>('data');

export const isDataLoading = createSelector(getData, (state) => state.loading);

export const getCostcenters = createSelector(getData, (state) => state.costcenters);

export const getDepartments = createSelector(getData, (state) => state.departments);

export const getEmployeeGroups = createSelector(getData, (state) => state.employeeGroups);
