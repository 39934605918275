import { Injectable } from '@angular/core';

export interface TabStates {
	w1: boolean;
	w2: boolean;
	w3: boolean;
	w4: boolean;
	z1: boolean;
	z2: boolean;
	z3: boolean;
	z4: boolean;
	b1: boolean;
	b2: boolean;
	b3: boolean;
	b4: boolean;
	bde1: boolean;
	bde2: boolean;
	bde3: boolean;
	bde4: boolean;
	t1: boolean;
	t2: boolean;
	t3: boolean;
	t4: boolean;
	dayView: boolean;
	us1: boolean;
}

@Injectable()
export class TabStateService {
	constructor() {}

	public onTabOpen(name) {
		let existingTabEntries = JSON.parse(localStorage.getItem('tabActives'));
		if (existingTabEntries == null) {
			existingTabEntries = {};
		}

		existingTabEntries[name] = 1;
		localStorage.setItem('tabActives', JSON.stringify(existingTabEntries));
	}

	public onTabClose(name) {
		let existingTabEntries = JSON.parse(localStorage.getItem('tabActives'));
		if (existingTabEntries == null) {
			existingTabEntries = {};
		}

		existingTabEntries[name] = 0;
		localStorage.setItem('tabActives', JSON.stringify(existingTabEntries));
	}

    public getTabState(name = null) {
        let existingTabEntries = JSON.parse(localStorage.getItem('tabActives'));
        if (existingTabEntries == null) {
            existingTabEntries = {};
        }
        if(name){
            return existingTabEntries[name] ?? true;
        }
        return existingTabEntries;
    }
}
