import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	DeleteTimemodel,
	DeleteTimemodelRequest,
	SaveTimemodel,
	SaveTimemodelRequest,
} from '../actions/timemodel.actions';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { TxApiService } from '../../shared/services/txapi.service';
import { LoadTimemodelDetailRequest, LoadTimemodelsRequest } from '../actions/personal-office.actions';
import { Router } from '@angular/router';
import { getTimemodelDetails } from '../selectors/personal-office.selectors';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { TimeModelDetailEntity } from '../entities/TimeModelDetailEntity';
import moment from 'moment';
import { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs';
import { MessengerService } from '../../shared/services/messenger.service';
import { ApiResponse } from '../entities/Api/ApiResponse';

@Injectable({
	providedIn: 'root',
})
export class TimemodelEffects {
	constructor(
		private actions$: Actions,
		private http: TxApiService,
		private router: Router,
		private msg: MessengerService,
		private store: Store<State>
	) {}

	SaveTimemodelRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SaveTimemodelRequest),
			withLatestFrom(this.store.select(getTimemodelDetails)),
			map(
				([{ entity }, models]) =>
					[{ entity: TimemodelEffects.preprocess(entity) }, models] as [
						{ entity: TimeModelDetailEntity },
						TimeModelDetailEntity[]
					]
			),
			switchMap(([{ entity }, models]) =>
				this.http
					.callAPI('updateTimeModel', {
						TimeModelDetails: [entity],
					})
					.pipe(
						concatMap((res) => {
							if (res.statuscode === 0) {
								return [
									entity.model_id === 0 ? LoadTimemodelsRequest(entity.name) : LoadTimemodelsRequest(),
									LoadTimemodelDetailRequest(entity.model_id),
								];
							}
							throw new Error(res);
						})
					)
			)
		)
	);

	DeleteTimemodelRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeleteTimemodelRequest),
			switchMap(({ entity }) =>
				this.http
					.callAPI('deleteTimeModel', {
						model_id: entity.model_id,
					})
					.pipe(
						map((res: ApiResponse<any>) => {
							if (res.statuscode === 0) {
								return DeleteTimemodel(entity.model_id);
							}
							this.msg.message(res.statustext, 'error');
							throw new Error(res.statustext);
						})
					)
			)
		)
	);

	DeleteTimemodel$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(DeleteTimemodel),
				map(({ id }) => {
					this.router.navigate(['/', 'personaloffice', 'zeitmodelle', 'all']);
				})
			),
		{ dispatch: false }
	);

	private static preprocess(model: TimeModelDetailEntity): TimeModelDetailEntity {
		const tmp = new TimeModelDetailEntity();
		Object.assign(tmp, model, {
			inclusiv_hours_val: model.inclusiv_hours_val
				? moment.duration(model.inclusiv_hours_val, 'hours').asHours()
				: null,
			max_hours_zk_val: model.max_hours_zk_val ? moment.duration(model.max_hours_zk_val, 'hours').asHours() : null,
			max_hours_day_val: model.max_hours_day_val ? moment.duration(model.max_hours_day_val, 'hours').asHours() : null,
			fix_hours_month_val: model.fix_hours_month_val
				? moment.duration(model.fix_hours_month_val, 'hours').asHours()
				: null,
		});
		return tmp;
	}
}
