<p-accordion (onOpen)="onTabOpen('bde1')" (onClose)="onTabClose('bde1')">
	<p-accordionTab
		[header]="'tx-bde.assignment' | translate"
		[selected]="tabState?.bde1 == null || tabState?.bde1 == true ? true : false">
		<section class="widget" widget>
			<div class="widget-body">
				<form role="form" #bdeFormCtrl="ngForm">
					<fieldset>
						<div class="grid">
							<div class="col-12">
								<div class="form-group">
									<p-dropdown
										ngDefaultControl
										name="myGuiAuftragDropdown"
										id="myGuiAuftragDropdown"
										styleClass="fullwidthDiv"
										[placeholder]="'tx-bde.selectAssignment' | translate"
										[showClear]="true"
										[options]="myAuftragsArray"
										[(ngModel)]="mycurrentAuftragSelectID"
										(onChange)="onAuftragSelected($event)"
										[filter]="filterStatus"
										(onFocus)="onFocusDropdown($event)"
										(onBlur)="onBlurDropdown($event)"
										[resetFilterOnHide]="true"
										emptyFilterMessage="Kein Auftrag gefunden"
										inputId="dropdown-1"
										[disabled]="showLoadingBDEData"></p-dropdown>
								</div>
								<div class="form-group" *ngIf="myGuiAuftragsschrittDropdownVisible">
									<p-dropdown
										ngDefaultControl
										*ngIf="myGuiAuftragsschrittDropdownVisible"
										name="myGuiAuftragsschrittDropdown"
										id="myGuiAuftragsschrittDropdown"
										styleClass="fullwidthDiv"
										placeholder="Bitte Tätigkeit wählen"
										[showClear]="true"
										[options]="myAuftragsschrittArray"
										[(ngModel)]="myCurrentAuftragsschrittSelectID"
										(onChange)="onAuftragSchrittSelected($event)"
										[filter]="filterStatus"
										(onFocus)="onFocusDropdown($event)"
										(onBlur)="onBlurDropdown($event)"
										[resetFilterOnHide]="true"
										emptyFilterMessage="Keine Tätigkeit gefunden"></p-dropdown>
								</div>
								<div class="grid">
									<div class="col-12">
										<button
											pButton
											[disabled]="!myGuiTaetigkeitDropdownVisible || !myGuiAuftragsschrittDropdownVisible"
											(click)="doStempelBDE('AB')"
											class="btn btn-lg btn-primary btn-block"
											[label]="'tx-bde.sendAssignment' | translate"></button>
									</div>
									<div
										*ngIf="(isMobile || isTablet) && barcodeState !== 0"
										[ngClass]="currentSession.barcode == 1 && (isMobile || isTablet) ? 'col-6' : 'col-12'">
										<button
											pButton
											(click)="showCodeScanner()"
											icon="fa fa-barcode"
											class="btn btn-primary btn-block"
											label="Scan"></button>
										<p-dialog
											[(visible)]="displayCodeScannerPopup"
											[responsive]="true"
											header="Barcode scannen"
											[blockScroll]="true"
											[modal]="true"
											appendTo="body">
											<tx-code-scanner
												[qrScan]="true"
												[scannerEnabled]="displayCodeScannerPopup"
												(scanResult)="receiveScanResult($event)"></tx-code-scanner>
										</p-dialog>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		</section>
	</p-accordionTab>
</p-accordion>

<!--<section class="widget waiting active-bde" *ngIf="showLoadingBDEData">
	<div class="row title">
		<div class="col-xs-12">
			<h4 class="title">{{ 'tx-bde.pleaseWait' | translate }}</h4>
		</div>
	</div>
	<div class="row title" *ngIf="!isOnline">
		<div class="col-xs-12">
			<h5 class="subtitle">
				Die App ist gerade offline, die Daten werden übermittelt, wenn die App wieder online ist.
			</h5>
		</div>
	</div>
	<div class="widget-body">
		<div class="row">
			<div class="col-xs-12">
				<p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
				<h6>{{ 'tx-bde.data' | translate }}</h6>
			</div>
		</div>
	</div>
</section>-->

<div *ngIf="myActiveBDE$ | async as myActiveBDE">
	<section class="widget active-bde" widget *ngFor="let item of myActiveBDE; let i = index">
		<tx-bde-assignment
			[assignment]="item"
			[i]="i"
			(end)="
				this.doStempelBDE(
					$event.aBuchungsart,
					$event.aActiveAuftrag,
					$event.aActiveSchritt,
					$event.aIndex,
					$event.aKill,
					$event.note,
					$event.quantity
				)
			"></tx-bde-assignment>
	</section>
	<section class="widget active-bde" widget *ngFor="let item of myQueuedBDE; let i = index">
		<tx-bde-assignment
			[assignment]="item"
			[i]="1000 + i"
			(end)="
				this.doStempelBDE(
					$event.aBuchungsart,
					$event.aActiveAuftrag,
					$event.aActiveSchritt,
					$event.aIndex,
					$event.aKill,
					$event.note
				)
			"></tx-bde-assignment>
	</section>
</div>
