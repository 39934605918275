/* tslint:disable:variable-name */
export class TxBdeBuchungen {
	constructor(
		public statuscode: number = 0,
		public statustext: string = '',
		public ListOfBDEBuchung: Array<TxBuchung> /* can be any kind of Buchung */
	) {}
}

export class TxBuchung {
	constructor(
		public datum?: string,
		public start?: string,
		public end?: string,
		public fehlzeit_color?: string,
		public fehlzeit_dauer?: string,
		public fehlzeit_id?: string,
		public fehlzeit_name?: string,
		public pze_beginn?: string,
		public pze_beginn_id?: string,
		public pze_color?: string,
		public pze_ende?: string,
		public pze_ende_id?: string,
		public pze_info?: string,
		public kst_nummer?: number,
		public kst_name?: string,
		public ListOfBDEDetail?: Array<TxBuchungBdeDetail>
	) {}
}

export class TxBuchungBdeDetail {
	constructor(
		public datum?: string,
		public start?: string,
		public end?: string,
		public bde_id?: string,
		public auftrag_nummer?: string,
		public auftrag_name?: string,
		public bde_beginn?: string,
		public bde_ende?: string,
		public schritt_nummer?: string,
		public schritt_name?: string,
		public dauer_min?: string,
		public bde_color?: string,
		public bde_info?: string
	) {}
}

export class TxCreateBooking {
	constructor(
		public auftrag_nummer?: string,
		public book_beginn?: string,
		public book_end?: string,
		public book_type?: string,
		public schritt_nummer?: string,
		public info?: string,
		public fehlzeit_id?: number,
		public kst_nummer?: number,
		public team_leader_for?: number
	) {}
}

export class TxDeleteBooking {
	constructor(public book_beginn_id?: string, public book_end_id?: string, public book_type?: string) {}
}

export class TxUpdateBooking {
	constructor(
		public auftrag_nummer?: string,
		public info?: string,
		public book_beginn?: string,
		public book_beginn_id?: number,
		public book_end?: string,
		public book_end_id?: number,
		public book_type?: string,
		public fehlzeit_id?: number,
		public kst_nummer?: number,
		public schritt_nummer?: string,
		public team_leader_for?: number
	) {}
}
