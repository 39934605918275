import { Pipe, PipeTransform } from '@angular/core';
import moment, { Duration } from 'moment';

type MomentFunctions = 'weekdays' | 'formatTime' | 'duration';

@Pipe({
	name: 'Moment',
})
export class MomentPipe implements PipeTransform {
	constructor() {
		moment.locale('de');
	}

	public static zeroFill(value: number) {
		if (value < 10) {
			return `0${value}`;
		}
		return `${value}`;
	}

	transform(value: any, func: MomentFunctions, format?: string): string {
		if (!value) {
			return null;
		}
		let duration: Duration = null;
		switch (func) {
			case 'weekdays':
				return value && moment.weekdays(value);
			case 'formatTime':
				if (typeof value === 'string') {
					duration = moment.duration(value.split('T')[1]);
				} else {
					duration = moment.duration(value, 'hours');
				}
				return value && `${MomentPipe.zeroFill(duration.get('hours'))}:${MomentPipe.zeroFill(duration.get('minutes'))}`;
			case 'duration':
				duration = moment.duration(value, 'h');
				return `${duration.get('h')}${duration.get('m')}`;
		}
		return value;
	}
}
