import { ListOfWorkstepProjectResponse } from './Api/ListOfWorkstepResponse';
import moment from 'moment';

export class ProjectWorkstep extends ListOfWorkstepProjectResponse {
	sum_quantity: number;
	calculated_time: string;
	deleted: boolean;

	public static tryCast(o: any): ProjectWorkstep {
		const ret = new ProjectWorkstep();
		return Object.assign(ret, o);
	}
}

export class ProjectDetailEntity {
	project_id: number;
	project_number: string;
	project_name: string;
	creation_date: string;
	target_date: string;
	target_time: string;
	calculated_time: string;
	project_state: number;
	description: string;
	target_quantity: number;
	unproductive: number;
	ListOfProjectWorkstep: ProjectWorkstep[] = [];

	public static tryCast(o: any): ProjectDetailEntity {
		let ret = new ProjectDetailEntity();
		ret = Object.assign(ret, o);
		ret.ListOfProjectWorkstep = ret.ListOfProjectWorkstep?.map((l) => ProjectWorkstep.tryCast(l)) ?? [];
		if (ret.target_time) {
			const parsed = moment.duration(ret.target_time, 'hours');
			ret.target_time = parsed.format('HHH:mm');
			if (parsed.asHours() < 1) {
				ret.target_time = '000:' + ret.target_time;
			}
		} else {
			ret.target_time = '000:00';
		}
		if (ret.calculated_time && /^[0-9]{1,4}:[0-9]{2}$/.test(ret.calculated_time)) {
			const parsed = moment.duration(ret.calculated_time, 'hours');
			ret.calculated_time = parsed.format('HHH:mm');
			if (parsed.asHours() < 1) {
				ret.calculated_time = '000:' + ret.calculated_time;
			}
		} else {
			ret.calculated_time = '000:00';
		}
		return ret;
	}

	toFormValue() {
		let formatedTargetTime: string = (this.target_time ?? '').includes(':') ? this.target_time : '0:0';

		formatedTargetTime = (
			this.target_time?.length > 5
				? this.target_time
				: '0'.repeat(5 - this.target_time?.length ?? 0) + this.target_time ?? 0
		) as string;

		const formatedCalculatedTime =
			this.calculated_time?.length > 5
				? this.calculated_time
				: '0'.repeat(5 - this.calculated_time?.length ?? 0) + this.calculated_time ?? 0;

		return {
			project_id: this.project_id,
			project_number: this.project_number,
			project_name: this.project_name,
			creation_date: this.creation_date,
			target_date: this.target_date,
			target_time: this.target_time,
			calculated_time: formatedCalculatedTime,
			project_state: this.project_state,
			description: this.description,
			target_quantity: this.target_quantity,
			unproductive: this.unproductive,
		};
	}
}
