import { NgModule } from '@angular/core';
import { FormatTimePipe } from './pipes/timeFormat.pipe';
import { ObjToArrPipe } from './pipes/objtoArr.pipe';
import { CoTimePipe } from './pipes/coTime.pipe';
import { StringFilterPipe } from './pipes/stringFilter.pipe';
/*
Prime NG
 */
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgxPaginationModule } from 'ngx-pagination';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { EqualValidatorDirective } from './directives/equal-validator.directive';
import { GroupFilterPipe } from './pipes/group-filter.pipe';
// ngx-translate Imports
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ConfirmDialogClickDirective, TxConfirmDialogComponent } from './directives/confirm-dialog-click.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MomentPipe } from './pipes/moment.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { MaskedInputDirective } from './directives/masked-input.directive';
import { ArrayPipe } from './pipes/array.pipe';
import { MessageService, SharedModule } from 'primeng/api';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import {
	MAT_COLOR_FORMATS,
	NGX_MAT_COLOR_FORMATS,
	NgxMatColorPickerModule,
} from '@angular-material-components/color-picker';
import { StopClickPropagationDirective } from './directives/stop-click-propagation.directive';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

FullCalendarModule.registerPlugins([
	// register FullCalendar plugins
	dayGridPlugin,
	timeGridPlugin,
	interactionPlugin,
	momentPlugin,
	bootstrapPlugin,
]);

@NgModule({
	imports: [
		CalendarModule,
		DropdownModule,
		InputTextModule,
		DialogModule,
		TabViewModule,
		BlockUIModule,
		PanelModule,
		SliderModule,
		NgxPaginationModule,
		InputTextareaModule,
		TableModule,
		InputSwitchModule,
		ToggleButtonModule,
		FileUploadModule,
		AccordionModule,
		SharedModule,
		CheckboxModule,
		ButtonModule,
		ProgressSpinnerModule,
		ZXingScannerModule,
		SplitButtonModule,
		OverlayPanelModule,
		TooltipModule,
		FullCalendarModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		ToastModule,
		MatDialogModule,
		MatButtonModule,
	],
	declarations: [
		FormatTimePipe,
		FilterPipe,
		MomentPipe,
		ArrayPipe,
		SortPipe,
		ObjToArrPipe,
		CoTimePipe,
		StringFilterPipe,
		EqualValidatorDirective,
		GroupFilterPipe,
		ConfirmDialogClickDirective,
		TxConfirmDialogComponent,
		MaskedInputDirective,
		StopClickPropagationDirective,
	],
	providers: [TranslatePipe, MessageService],
	exports: [
		FormatTimePipe,
		FilterPipe,
		MomentPipe,
		SortPipe,
		ObjToArrPipe,
		CoTimePipe,
		StringFilterPipe,
		GroupFilterPipe,
		CalendarModule,
		DropdownModule,
		InputTextModule,
		DialogModule,
		TabViewModule,
		BlockUIModule,
		PanelModule,
		SliderModule,
		NgxPaginationModule,
		InputTextareaModule,
		TableModule,
		InputSwitchModule,
		ToggleButtonModule,
		FileUploadModule,
		AccordionModule,
		SharedModule,
		CheckboxModule,
		ButtonModule,
		ProgressSpinnerModule,
		ZXingScannerModule,
		SplitButtonModule,
		OverlayPanelModule,
		TooltipModule,
		TranslateModule,
		FullCalendarModule,
		ToastModule,
		ConfirmDialogClickDirective,
		TxConfirmDialogComponent,
		MomentPipe,
		ArrayPipe,
		MaskedInputDirective,
		StopClickPropagationDirective,
		EqualValidatorDirective,
	],
})
export class TxSharedModule {}
